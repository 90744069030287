import React from 'react';
import { Input, Select } from '@8powers/ams/components/UI/Form';
import Display from '@8powers/ams/components/Display';
import { Icon } from '@8powers/ams/components/UI';
import {
  Wrapper,
  Row,
  Num,
  InputWrap,
  Multiply,
  Sum,
  Actions,
  Button
} from './styles';

class ListItem extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    const { item } = this.props;
    if (item.name.length === 0) {
      this.inputRef && this.inputRef.current.focus();
    }
  }

  createUnitSelect() {
    const { unitComboData, item, budgetLine, onInputChange } = this.props;

    const options = unitComboData.map(option => (
      <option
        key={option.id}
        value={option.id}
        selected={option.id === item.unit}
      >
        {option.label}
      </option>
    ));

    return (
      <Select
        name="unit"
        tabIndex={1}
        onChange={e => onInputChange(e, budgetLine, item.id)}
      >
        {options}
      </Select>
    );
  }

  render() {
    const {
      index,
      item,
      budgetLine,
      onDeleteClick,
      onInputChange
    } = this.props;

    return (
      <Wrapper>
        <Row>
          <Num>{index}</Num>
          <InputWrap long>
            <Input
              value={item.name}
              name="name"
              onChange={e => onInputChange(e, budgetLine, item.id)}
              ref={this.inputRef}
            />
          </InputWrap>
        </Row>
        <Row>
          <InputWrap>
            <Input
              value={item.count}
              name="count"
              onChange={e => onInputChange(e, budgetLine, item.id)}
            />
          </InputWrap>
          <InputWrap>{this.createUnitSelect()}</InputWrap>
          <Multiply>&times;</Multiply>
          <InputWrap withCurrency>
            <Input
              value={item.unitPrice}
              name="unitPrice"
              onChange={e => onInputChange(e, budgetLine, item.id)}
            />
          </InputWrap>
          <Multiply>=</Multiply>
          <Sum>
            <Display type="price" value={item.total} /> zł
          </Sum>
          <Actions>
            <Button
              type="button"
              onClick={() => onDeleteClick(budgetLine, item.id)}
            >
              <Icon name="trash" />
            </Button>
          </Actions>
        </Row>
      </Wrapper>
    );
  }
}

export default ListItem;
