import React, { Component } from 'react';
import Display from '@8powers/ams/components/Display';
import { Wrapper, Label, List, Summary, SummaryValue } from './styles';
import Item from './Item';

class CollectionField extends Component {
  render() {
    const {
      label,
      items,
      summary,
      onListItemChange,
      preview,
      readOnly
    } = this.props;

    return (
      <Wrapper>
        <Label>{label}</Label>
        <List>
          {items.map((item, index) => (
            <Item
              index={index + 1}
              key={item.id}
              item={item}
              preview={preview}
              readOnly={readOnly}
              onListItemChange={onListItemChange}
            />
          ))}
        </List>
        {items.length > 0 && (
          <Summary>
            <SummaryValue>
              <Display value={summary} type="price" /> zł
            </SummaryValue>
          </Summary>
        )}
      </Wrapper>
    );
  }
}

export default CollectionField;
