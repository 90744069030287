import { createDucks } from '@8powers/core/redux';

const { reducer, saga, actions } = createDucks('investment', {
  investment: {
    endpoint: 'investment',
    slices: ['list', 'detail', 'create', 'edit']
  },
  investmentState: {
    endpoint: 'investmentState',
    slices: ['combo']
  },
  team: {
    endpoint: 'investmentTeam',
    slices: ['list', 'create', 'edit']
  },
  location: {
    endpoint: 'location',
    slices: ['combo']
  },
  profile: {
    endpoint: 'profile',
    slices: ['combo']
  },
  company: {
    endpoint: 'company',
    slices: ['combo']
  },
  budget: {
    endpoint: 'budget',
    slices: ['combo']
  },
  logo: {
    endpoint: 'file',
    slices: ['create']
  }
});

export { saga, actions };
export default reducer;
