const widgets = {
  CostForm: {
    component: 'FormContainer',
    props: {},
    children: [
      {
        component: 'Form',
        props: {
          groups: [
            {
              label: '',
              columns: [
                [
                  {
                    name: 'protocol',
                    label: 'cost.entity.protocolConditional',
                    type: 'conditionalField',
                    displayField: 'date',
                    rawControl: true
                  }
                ]
              ]
            },
            {
              label: '',
              columns: [
                [
                  {
                    name: 'createDate',
                    label: 'cost.entity.createDate',
                    type: 'date'
                  }
                ],
                [
                  {
                    name: 'paymentDate',
                    label: 'cost.entity.paymentDate',
                    type: 'date'
                  }
                ]
              ]
            },
            {
              label: '',
              columns: [
                [
                  {
                    name: 'company',
                    label: 'cost.entity.company',
                    type: 'endpointCombo',
                    endpoint: 'companyCombo',
                    slice: 'cost/company/combo'
                  },
                  {
                    name: 'state',
                    label: 'cost.entity.state',
                    type: 'endpointCombo',
                    endpoint: 'costState',
                    slice: 'cost/state/combo',
                    valueField: 'id',
                    displayField: 'label'
                  },
                  {
                    label: null,
                    name: 'collection',
                    type: 'collection',
                    rawControl: true
                  }
                ]
              ]
            }
          ]
        }
      }
    ]
  },
  CostEditForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'cost',
      slice: 'cost/cost/edit',
      operation: 'edit',
      fancyMask: true
    },
    children: [
      {
        component: 'CostForm'
      }
    ]
  },
  CostCreateForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'cost',
      slice: 'cost/cost/create',
      operation: 'create',
      fancyMask: true,
      extractFromRecord: [
        'protocol',
        'createDate',
        'paymentDate',
        'company',
        'budget',
        'state',
        'collection'
      ]
    },
    children: [
      {
        component: 'CostForm'
      }
    ]
  },
  CostDetailContainer: {
    component: 'EndpointSelectedContainer',
    props: {
      endpoint: 'cost',
      slice: 'cost/cost/list'
    },
    children: [
      {
        component: 'EndpointDetail',
        props: {
          config: {
            groups: [
              {
                label: 'Dane podstawowe',
                columns: [
                  [
                    {
                      key: 'id',
                      label: 'cost.entity.id'
                    },
                    {
                      key: 'createDate',
                      label: 'cost.entity.createDate',
                      type: 'datetime'
                    }
                  ],
                  [
                    {
                      key: 'protocol',
                      label: 'cost.entity.protocol',
                      type: 'objectProps',
                      typeProps: {
                        props: ['date']
                      }
                    },
                    {
                      key: 'paymentDate',
                      label: 'cost.entity.paymentDate',
                      type: 'datetime'
                    }
                  ]
                ]
              },
              {
                label: '',
                columns: [
                  [
                    {
                      key: 'collection',
                      label: 'cost.entity.items',
                      type: 'itemsList',
                      collection: true
                    }
                  ]
                ]
              },
              {
                label: '',
                columns: [
                  [
                    {
                      key: 'createdAt',
                      label: 'cost.entity.createdAt',
                      type: 'relativeDate'
                    },
                    {
                      key: 'createdBy',
                      label: 'cost.entity.createdBy',
                      type: 'objectProps',
                      typeProps: {
                        props: ['firstName', 'lastName']
                      }
                    }
                  ],
                  [
                    {
                      key: 'updatedAt',
                      label: 'cost.entity.updatedAt',
                      type: 'relativeDate'
                    },
                    {
                      key: 'updatedBy',
                      label: 'cost.entity.updatedBy',
                      type: 'objectProps',
                      typeProps: {
                        props: ['firstName', 'lastName']
                      }
                    }
                  ]
                ]
              }
            ]
          }
        }
      }
    ]
  }
};

export default widgets;
