export const routes = [
  {
    name: 'profile_list',
    path: '/user',
    componentConfig: [
      {
        component: 'EndpointListContainer',
        props: {
          endpoint: 'profile',
          slice: 'profile/profile/list',
          selectAction: {
            preview: {
              meta: {
                modal: {
                  body: [
                    {
                      component: 'ProfileDetailContainer'
                    }
                  ]
                }
              }
            },
            edit: {
              type: 'profile/profile/edit/start',
              meta: {
                modal: {
                  title: 'profile.actions.update',
                  body: [
                    {
                      component: 'ProfileEditForm'
                    }
                  ]
                }
              }
            }
          }
        },
        children: [
          {
            component: 'Grid',
            props: {
              title: 'profile.module.name',
              config: {
                columns: {
                  id: {
                    label: 'profile.entity.id',
                    width: '90px'
                  },
                  firstName: {
                    label: 'profile.entity.firstName'
                  },
                  lastName: {
                    label: 'profile.entity.lastName'
                  },
                  user: {
                    width: '17%',
                    label: 'profile.entity.user.email',
                    display: {
                      type: 'objectProps',
                      props: ['email']
                    }
                  },
                  phone: {
                    label: 'profile.entity.phone'
                  },
                  positionLabel: {
                    label: 'profile.entity.position'
                  },
                  state: {
                    label: 'profile.entity.state',
                    display: {
                      type: 'objectProps',
                      props: ['label']
                    }
                  },
                  updatedAt: {
                    label: 'profile.entity.updatedAt',
                    display: {
                      type: 'relativeDate'
                    }
                  }
                },
                filtersConfig: [],
                actions: [
                  {
                    label: 'profile.actions.create',
                    type: 'create',
                    icon: 'plus',
                    action: {
                      type: 'profile/profile/edit/start',
                      meta: {
                        modal: {
                          title: 'profile.create',
                          body: [
                            {
                              component: 'ProfileCreateForm'
                            }
                          ]
                        }
                      }
                    }
                  }
                ],
                recordActions: [
                  {
                    label: '',
                    type: 'edit',
                    icon: 'pen',
                    action: {
                      type: 'profile/profile/edit/start',
                      meta: {
                        modal: {
                          title: 'profile.update',
                          body: [
                            {
                              component: 'ProfileEditForm'
                            }
                          ]
                        }
                      }
                    }
                  },
                  {
                    label: '',
                    type: 'delete',
                    icon: 'trash',
                    action: {
                      type: 'profile/profile/list/delete',
                      meta: {
                        API: {
                          endpoint: 'profile',
                          operation: 'delete'
                        },
                        confirm: {
                          props: {
                            title: 'ims.confirm.delete.title',
                            message: 'ims.confirm.delete.message',
                            confirmText: 'ims.confirm.delete.confirmText',
                            cancelText: 'ims.confirm.delete.cancelText'
                          },
                          confirmed: false
                        }
                      }
                    }
                  }
                ]
              }
            }
          }
        ]
      }
    ],
    exact: true,
    global: true,
    section: true,
    module: 'profile',
    label: 'profile.module.name',
    icon: 'users'
  }
];
