import { createDucks } from '@8powers/core/redux';

const { reducer, saga, actions } = createDucks('profile', {
  profile: {
    endpoint: 'profile',
    slices: ['list', 'detail', 'create', 'edit']
  },
  state: {
    endpoint: 'profileState',
    slices: ['combo']
  },
  position: {
    endpoint: 'profilePosition',
    slices: ['combo']
  },
  role: {
    endpoint: 'role',
    slices: ['combo']
  }
});

export { saga, actions };
export default reducer;
