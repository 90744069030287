export default {
  messages: {
    team: {
      create: 'New team',
      update: 'Update team',
      module: {
        name: 'Team'
      },
      entity: {
        id: 'Id',
        investment: 'Investment',
        profile: 'Profile',
        position: 'Position',
        createdAt: 'CreatedAt',
        updatedAt: 'UpdatedAt'
      },
      grid: [],
      actions: {
        create: 'Create',
        delete: 'Delete'
      }
    }
  }
};
