import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Footer from '@8powers/ams/components/Application/MainApp/Footer';
import Header from '@8powers/ams/components/Application/MainApp/Header';
import Money from '@8powers/ams/components/Display/Money';
import {
  CellHeader,
  Wrapper,
  Content,
  EmptyInfo,
  ModeSwitcher,
  ModeSwitcherItem
} from './styles';
import { Cell } from './Item/styles';
import ListItem from './Item';
import NodeWrap from './NodeWrap';

class Budget extends Component {
  static propTypes = {
    mode: PropTypes.string
  };

  static defaultProps = {
    mode: 'preview'
  };

  getSummaryProps() {
    const { summary, summaryData } = this.props;
    if (!summary) return null;
    return summary.map(item => {
      if (item.name && summaryData[item.name] !== undefined) {
        return {
          ...item,
          value: summaryData[item.name]
        };
      }
      return item;
    });
  }

  createList(data, depth = 0, prefixes = []) {
    const {
      config,
      mode,
      onInputChange,
      onDragEnd,
      onQuarterInputChange,
      onBudgetLineCreate,
      onBudgetLineDuplicate,
      onBudgetLineDelete,
      unitComboData,
      onRowClick,
      showLineItemDetail
    } = this.props;
    return data.map((item, index) => {
      const letters = 'ABCDEFGHIJKLMNOPRSTUWYZ';
      let hasChildren;

      if (!depth || depth === 0) {
        prefixes = [letters[index]];
      } else if (item.children && item.children.length > 0) {
        hasChildren = true;
        prefixes.push(index + 1);
      } else {
        hasChildren = false;
      }

      const prefix = `${prefixes.join('.')}${
        hasChildren === false ? `.${index + 1}` : ''
      }`;

      const Wrap =
        item.children && item.children.length > 0 ? NodeWrap : React.Fragment;

      if (!item.children || item.children.length === 0) {
        return (
          <ListItem
            nodeDepth={depth}
            index={index}
            prefix={prefix}
            key={item.id}
            record={item}
            recordActions={config.recordActions}
            onInputChange={onInputChange}
            onQuarterInputChange={onQuarterInputChange}
            onBudgetLineCreate={onBudgetLineCreate}
            onBudgetLineDuplicate={onBudgetLineDuplicate}
            onBudgetLineDelete={onBudgetLineDelete}
            mode={mode}
            unitComboData={unitComboData}
            onRowClick={onRowClick}
            showLineItemDetail={showLineItemDetail}
          />
        );
      }

      let children = null;

      if (item.children && item.children.length > 0) {
        children = this.createList(item.children, depth + 1, prefixes);
        prefixes.pop();
      }

      return (
        <Wrap
          key={item.id}
          MainComponent={ListItem}
          componentProps={{
            nodeDepth: depth,
            index,
            prefix,
            key: item.id,
            record: item,
            onInputChange,
            onDragEnd,
            onQuarterInputChange,
            recordActions: config.recordActions,
            mode,
            onBudgetLineCreate,
            onBudgetLineDuplicate,
            onBudgetLineDelete,
            unitComboData,
            onRowClick
          }}
        >
          {children}
        </Wrap>
      );
    });
  }

  render() {
    const {
      icon,
      total,
      recordsTxt,
      t,
      mode,
      onModeChange,
      disableEdit,
      budgetLinesTree,
      onSubmit,
      isEmpty,
      clone
    } = this.props;

    let actions = [];

    if (mode !== 'preview') {
      actions = [
        {
          label: 'Zapisz',
          type: 'create',
          icon: 'plus',
          action: onSubmit
        }
      ];
    } else {
      actions = [
        {
          label: 'Kopiuj',
          type: 'copy',
          icon: 'copy',
          action: clone
        }
      ];
    }

    return (
      <Wrapper>
        <Header
          icon={icon}
          title={t(`budget.line.list.title.${mode}`)}
          total={0}
          recordsTxt={t(recordsTxt)}
        >
          <Money value={total} currency="PLN" suffix />
        </Header>
        <ModeSwitcher>
          <ModeSwitcherItem
            active={mode === 'preview'}
            onClick={() => onModeChange('preview')}
          >
            {t('budget.line.list.modeSwitch.preview')}
          </ModeSwitcherItem>
          {!disableEdit && (
            <ModeSwitcherItem
              active={mode === 'edit'}
              onClick={() => onModeChange('edit')}
            >
              {t('budget.line.list.modeSwitch.edit')}
            </ModeSwitcherItem>
          )}
          <ModeSwitcherItem
            active={mode === 'schedule'}
            onClick={() => onModeChange('schedule')}
          >
            {t('budget.line.list.modeSwitch.schedule')}
          </ModeSwitcherItem>
        </ModeSwitcher>
        <Content style={{ paddingTop: mode === 'preview' ? 0 : '2rem' }}>
          {mode === 'preview' && (
            <CellHeader>
              <Cell>Kontrakt</Cell>
              <Cell>Koszty</Cell>
              <Cell>Budżet</Cell>
            </CellHeader>
          )}
          {isEmpty && mode === 'preview' ? (
            <EmptyInfo>{t('budget.line.list.empty')}</EmptyInfo>
          ) : (
            this.createList(budgetLinesTree)
          )}
        </Content>
        <Footer actions={actions} summary={this.getSummaryProps()} />
      </Wrapper>
    );
  }
}

export default withTranslation()(Budget);
