export default {
  messages: {
    offerItem: {
      create: 'Nowa pozycja',
      update: 'Edytuj pozycję',
      module: {
        name: 'Pozycja oferty'
      },
      entity: {
        id: 'Id',
        offer: 'Oferta',
        budgetLine: 'Linia budżetowa',
        description: 'Nazwa',
        cost: 'Koszt',
        count: 'Liczba',
        unit: 'Jednostka',
        unitPrice: 'Cena jednostkowa',
        createdBy: 'Dodane przez',
        updatedBy: 'Edytowane przez',
        createdAt: 'Data dodania',
        updatedAt: 'Ostatnia modyfikacja'
      },
      grid: [],
      actions: {
        create: 'Dodaj',
        update: 'Edytuj',
        delete: 'Usuń'
      }
    }
  }
};
