import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Label } from '@8powers/ams/components/EndpointDetail/styles';
import Display from '@8powers/ams/components/Display';
import { ListItem, BudgetLine, Left, Right, Positions } from './styles';

class ItemsList extends Component {
  render() {
    const { value, label, t } = this.props;
    const items = [];
    const numberLevels = {
      count: 1,
      unitPrice: 1,
      total: 1,
      label: 1
    };
    value.forEach(line => {
      line.items.forEach(item => {
        const count = JSON.stringify(item.count || 0).length;
        const unitPrice = JSON.stringify(item.unitPrice || 0).length;
        const total = JSON.stringify(item.total || 0).length;
        if (count > numberLevels.count) {
          numberLevels.count = count;
        }
        if (unitPrice > numberLevels.unitPrice) {
          numberLevels.unitPrice = unitPrice;
        }
        if (total > numberLevels.total) {
          numberLevels.total = total;
        }
        if (item.unitLabel.length > numberLevels.label) {
          numberLevels.label = item.unitLabel.length;
        }
      });
    });

    value.forEach((line, i) => {
      const lineItems = [];
      line.items.forEach((item, pi) => {
        lineItems.push(
          <BudgetLine>
            <Left>
              {pi + 1}. {item.name}
            </Left>
            <BudgetLine>
              <Right style={{ minWidth: `${numberLevels.count + 1}rem` }}>
                {item.count}
              </Right>
              <Right style={{ minWidth: `${numberLevels.label - 1}rem` }}>
                {item.unitLabel}
              </Right>
              &nbsp;x
              <Right style={{ minWidth: `${numberLevels.unitPrice + 1}rem` }}>
                {item.unitPrice}
              </Right>
              &nbsp;zł =
              <Right style={{ minWidth: `${numberLevels.total + 4}rem` }}>
                <Display type="price" value={item.total} /> zł
              </Right>
            </BudgetLine>
          </BudgetLine>
        );
      });
      items.push(
        <ListItem>
          <BudgetLine>
            <Left>
              {i + 1}. {line.budgetLine.listOrder} {line.budgetLine.name}
            </Left>
            <Right>
              {/* Left this in case there is a screen we want to display it */}
              {/* <Display type="price" value={line.budgetLine.cost} /> zł */}
            </Right>
          </BudgetLine>
          <Positions>{lineItems}</Positions>
        </ListItem>
      );
    });

    return (
      <div style={{ width: '100%' }}>
        <Label>{t(label)}</Label>
        {items}
      </div>
    );
  }
}

export default withTranslation()(ItemsList);
