export const routes = [
  {
    name: 'costItem_list',
    path: '/costItem',
    componentConfig: [
      {
        component: 'EndpointListContainer',
        props: {
          endpoint: 'costItem',
          slice: 'costItem/costItem/list',
          selectAction: {
            preview: {
              meta: {
                modal: {
                  body: [
                    {
                      component: 'CostItemDetailContainer'
                    }
                  ]
                }
              }
            },
            edit: {
              type: 'costItem/costItem/edit/start',
              meta: {
                modal: {
                  title: 'costItem.actions.update',
                  body: [
                    {
                      component: 'CostItemEditForm'
                    }
                  ]
                }
              }
            }
          }
        },
        children: [
          {
            component: 'Grid',
            props: {
              title: 'costItem.module.name',
              config: {
                columns: {
                  id: {
                    label: 'costItem.entity.id'
                  },
                  cost: {
                    label: 'costItem.entity.cost',
                    display: {
                      type: 'objectProps',
                      props: ['id']
                    }
                  },
                  budgetLine: {
                    label: 'costItem.entity.budgetLine',
                    display: {
                      type: 'objectProps',
                      props: ['name']
                    }
                  },
                  description: {
                    label: 'costItem.entity.description'
                  },
                  amount: {
                    label: 'costItem.entity.amount'
                  },
                  contractItem: {
                    label: 'costItem.entity.contractItem',
                    display: {
                      type: 'objectProps',
                      props: ['description']
                    }
                  },
                  updatedAt: {
                    label: 'costItem.entity.updatedAt',
                    display: {
                      type: 'datetime'
                    }
                  }
                },
                filtersConfig: [],
                actions: [
                  {
                    label: 'costItem.actions.create',
                    type: 'create',
                    icon: 'plus',
                    action: {
                      type: 'costItem/costItem/edit/start',
                      meta: {
                        modal: {
                          title: 'costItem.create',
                          body: [
                            {
                              component: 'CostItemCreateForm'
                            }
                          ]
                        }
                      }
                    }
                  }
                ],
                recordActions: [
                  {
                    label: '',
                    type: 'edit',
                    icon: 'pen',
                    action: {
                      type: 'costItem/costItem/edit/start',
                      meta: {
                        modal: {
                          title: 'costItem.update',
                          body: [
                            {
                              component: 'CostItemEditForm'
                            }
                          ]
                        }
                      }
                    }
                  },
                  {
                    label: '',
                    type: 'delete',
                    icon: 'trash',
                    action: {
                      type: 'costItem/costItem/list/delete',
                      meta: {
                        API: {
                          endpoint: 'costItem',
                          operation: 'delete'
                        },
                        confirm: {
                          props: {
                            title: 'ims.confirm.delete.title',
                            message: 'ims.confirm.delete.message',
                            confirmText: 'ims.confirm.delete.confirmText',
                            cancelText: 'ims.confirm.delete.cancelText'
                          },
                          confirmed: false
                        }
                      }
                    }
                  }
                ]
              }
            }
          }
        ]
      }
    ],
    exact: true,
    section: false,
    module: 'costItem',
    label: 'costItem.module.name',
    icon: null
  }
];
