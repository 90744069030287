const widgets = {
  CompanyForm: {
    component: 'FormContainer',
    children: [
      {
        component: 'Form',
        props: {
          groups: [
            {
              label: 'company.form.default',
              columns: [
                [
                  {
                    name: 'name',
                    label: 'company.entity.name',
                    type: 'text'
                  },
                  {
                    name: 'email',
                    label: 'company.entity.email',
                    type: 'text'
                  },
                  {
                    name: 'representedBy',
                    label: 'company.entity.representedBy',
                    type: 'text'
                  },
                  {
                    name: 'accountNumber',
                    label: 'company.entity.accountNumber',
                    type: 'text'
                  }
                ]
              ]
            },
            {
              label: '',
              columns: [
                [
                  {
                    name: 'type',
                    label: 'company.entity.type',
                    type: 'endpointCombo',
                    endpoint: 'companyType',
                    slice: 'company/companyType/combo',
                    placeholder: '--',
                    displayField: 'label'
                  }
                ],
                [
                  {
                    name: 'state',
                    label: 'company.entity.state',
                    type: 'endpointCombo',
                    endpoint: 'companyState',
                    slice: 'company/companyState/combo',
                    placeholder: '--',
                    displayField: 'label'
                  }
                ]
              ]
            },
            {
              label: '',
              columns: [
                [
                  {
                    name: 'taxID',
                    label: 'company.entity.taxID',
                    type: 'text'
                  }
                ],
                [
                  {
                    name: 'krs',
                    label: 'company.entity.krs',
                    type: 'text'
                  },
                  {
                    name: 'regon',
                    label: 'company.entity.regon',
                    type: 'text'
                  }
                ]
              ]
            },
            {
              label: 'company.form.contactPerson',
              columns: [
                [
                  {
                    name: 'persons.0.firstName',
                    label: 'person.entity.firstName',
                    type: 'text'
                  },
                  {
                    name: 'persons.0.email',
                    label: 'person.entity.email',
                    type: 'text'
                  },
                  {
                    name: 'persons.0.position',
                    label: 'person.entity.position',
                    type: 'text'
                  }
                ],
                [
                  {
                    name: 'persons.0.lastName',
                    label: 'person.entity.lastName',
                    type: 'text'
                  },
                  {
                    name: 'persons.0.phone',
                    label: 'person.entity.phone',
                    type: 'text'
                  }
                ]
              ]
            },
            {
              label: 'company.form.contactData',
              columns: [
                [
                  {
                    name: 'contactData.postCode',
                    label: 'company.entity.contactData.postCode',
                    type: 'text'
                  },
                  {
                    name: 'contactData.street',
                    label: 'company.entity.contactData.street',
                    type: 'text'
                  },
                  {
                    name: 'sameAddress',
                    label: 'company.entity.sameAddress',
                    type: 'checkbox'
                  }
                ],
                [
                  {
                    name: 'contactData.city',
                    label: 'company.entity.contactData.city',
                    type: 'text'
                  },
                  {
                    name: 'contactData.buildingNumber',
                    label: 'company.entity.contactData.buildingNumber',
                    type: 'text'
                  }
                ]
              ]
            },
            {
              label: 'company.form.invoiceData',
              columns: [
                [
                  {
                    name: 'invoiceData.postCode',
                    label: 'company.entity.invoiceData.postCode',
                    type: 'customInput',
                    relatedValues: ['sameAddress']
                  },
                  {
                    name: 'invoiceData.street',
                    label: 'company.entity.invoiceData.street',
                    type: 'customInput',
                    relatedValues: ['sameAddress']
                  }
                ],
                [
                  {
                    name: 'invoiceData.city',
                    label: 'company.entity.invoiceData.city',
                    type: 'customInput',
                    relatedValues: ['sameAddress']
                  },
                  {
                    name: 'invoiceData.buildingNumber',
                    label: 'company.entity.invoiceData.buildingNumber',
                    type: 'customInput',
                    relatedValues: ['sameAddress']
                  }
                ]
              ]
            }
          ]
        }
      }
    ]
  },
  CompanyEditForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'company',
      slice: 'company/company/edit',
      operation: 'edit',
      fancyMask: true
    },
    children: [
      {
        component: 'CompanyForm'
      }
    ]
  },
  CompanyCreateForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'company',
      slice: 'company/company/create',
      operation: 'create',
      fancyMask: true
    },
    children: [
      {
        component: 'FormContainer',
        children: [
          {
            component: 'Form',
            props: {
              groups: [
                {
                  label: 'company.form.default',
                  columns: [
                    [
                      {
                        name: 'name',
                        label: 'company.entity.name',
                        type: 'text'
                      },
                      {
                        name: 'email',
                        label: 'company.entity.email',
                        type: 'text'
                      },
                      {
                        name: 'representedBy',
                        label: 'company.entity.representedBy',
                        type: 'text'
                      },
                      {
                        name: 'type',
                        label: 'company.entity.type',
                        type: 'endpointCombo',
                        endpoint: 'companyType',
                        slice: 'company/companyType/combo',
                        placeholder: '--',
                        displayField: 'label'
                      }
                    ]
                  ]
                },
                {
                  label: '',
                  columns: [
                    [
                      {
                        name: 'taxID',
                        label: 'company.entity.taxID',
                        type: 'text'
                      }
                    ],
                    [
                      {
                        name: 'krs',
                        label: 'company.entity.krs',
                        type: 'text'
                      },
                      {
                        name: 'regon',
                        label: 'company.entity.regon',
                        type: 'text'
                      }
                    ]
                  ]
                },
                {
                  label: 'company.form.contactPerson',
                  columns: [
                    [
                      {
                        name: 'persons.0.firstName',
                        label: 'person.entity.firstName',
                        type: 'text'
                      },
                      {
                        name: 'persons.0.email',
                        label: 'person.entity.email',
                        type: 'text'
                      },
                      {
                        name: 'persons.0.position',
                        label: 'person.entity.position',
                        type: 'text'
                      }
                    ],
                    [
                      {
                        name: 'persons.0.lastName',
                        label: 'person.entity.lastName',
                        type: 'text'
                      },
                      {
                        name: 'persons.0.phone',
                        label: 'person.entity.phone',
                        type: 'text'
                      }
                    ]
                  ]
                },
                {
                  label: 'company.form.contactData',
                  columns: [
                    [
                      {
                        name: 'contactData.postCode',
                        label: 'company.entity.contactData.postCode',
                        type: 'text'
                      },
                      {
                        name: 'contactData.street',
                        label: 'company.entity.contactData.street',
                        type: 'text'
                      },
                      {
                        name: 'sameAddress',
                        label: 'company.entity.sameAddress',
                        type: 'checkbox'
                      }
                    ],
                    [
                      {
                        name: 'contactData.city',
                        label: 'company.entity.contactData.city',
                        type: 'text'
                      },
                      {
                        name: 'contactData.buildingNumber',
                        label: 'company.entity.contactData.buildingNumber',
                        type: 'text'
                      }
                    ]
                  ]
                },
                {
                  label: 'company.form.invoiceData',
                  columns: [
                    [
                      {
                        name: 'invoiceData.postCode',
                        label: 'company.entity.invoiceData.postCode',
                        type: 'customInput',
                        relatedValues: ['sameAddress']
                      },
                      {
                        name: 'invoiceData.street',
                        label: 'company.entity.invoiceData.street',
                        type: 'customInput',
                        relatedValues: ['sameAddress']
                      }
                    ],
                    [
                      {
                        name: 'invoiceData.city',
                        label: 'company.entity.invoiceData.city',
                        type: 'customInput',
                        relatedValues: ['sameAddress']
                      },
                      {
                        name: 'invoiceData.buildingNumber',
                        label: 'company.entity.invoiceData.buildingNumber',
                        type: 'customInput',
                        relatedValues: ['sameAddress']
                      }
                    ]
                  ]
                }
              ]
            }
          }
        ]
      }
    ]
  },
  CompanyDetailContainer: {
    component: 'EndpointSelectedContainer',
    props: {
      endpoint: 'company',
      slice: 'company/company/list'
    },
    children: [
      {
        component: 'EndpointDetail',
        props: {
          config: {
            groups: [
              {
                label: 'Dane podstawowe',
                columns: [
                  [
                    {
                      key: 'id',
                      label: 'company.entity.id'
                    }
                  ]
                ]
              },
              {
                label: '',
                columns: [
                  [
                    {
                      key: 'name',
                      label: 'company.entity.name'
                    }
                  ],
                  [
                    {
                      key: 'email',
                      label: 'company.entity.email'
                    }
                  ]
                ]
              },
              {
                label: '',
                columns: [
                  [
                    {
                      key: 'taxID',
                      label: 'company.entity.taxID'
                    }
                  ],
                  [
                    {
                      key: 'krs',
                      label: 'company.entity.krs'
                    },
                    {
                      key: 'regon',
                      label: 'company.entity.regon'
                    }
                  ]
                ]
              },
              {
                label: '',
                columns: [
                  [
                    {
                      key: 'typeLabel',
                      label: 'company.entity.type'
                    }
                  ],
                  [
                    {
                      key: 'state.label',
                      label: 'company.entity.state'
                    }
                  ]
                ]
              },
              {
                label: '',
                columns: [
                  [
                    {
                      key: 'createdAt',
                      label: 'company.entity.createdAt',
                      type: 'datetime'
                    }
                  ],
                  [
                    {
                      key: 'updatedAt',
                      label: 'company.entity.updatedAt',
                      type: 'relativeDate'
                    }
                  ]
                ]
              },
              {
                label: 'company.form.contactData',
                columns: [
                  [
                    {
                      key: 'contactData.postCode',
                      label: 'company.entity.contactData.postCode'
                    },
                    {
                      key: 'contactData.street',
                      label: 'company.entity.contactData.street'
                    }
                  ],
                  [
                    {
                      key: 'contactData.city',
                      label: 'company.entity.contactData.city'
                    },
                    {
                      key: 'contactData.street',
                      label: 'company.entity.contactData.street'
                    }
                  ]
                ]
              },
              {
                label: 'company.form.invoiceData',
                columns: [
                  [
                    {
                      key: 'invoiceData.postCode',
                      label: 'company.entity.invoiceData.postCode'
                    },
                    {
                      key: 'invoiceData.street',
                      label: 'company.entity.invoiceData.street'
                    }
                  ],
                  [
                    {
                      key: 'contactData.postCode',
                      label: 'company.entity.contactData.postCode'
                    },
                    {
                      key: 'contactData.street',
                      label: 'company.entity.contactData.street'
                    }
                  ]
                ]
              }
            ]
          }
        }
      }
    ]
  }
};

export default widgets;
