import { createDucks } from '@8powers/core/redux';

const { reducer, saga, actions } = createDucks('company', {
  company: {
    endpoint: 'company',
    slices: ['list', 'detail', 'create', 'edit', 'combo']
  },
  companyState: {
    endpoint: 'companyState',
    slices: ['combo']
  },
  companyType: {
    endpoint: 'companyType',
    slices: ['combo']
  },
  contactData: {
    endpoint: 'location',
    slices: ['combo']
  },
  invoiceData: {
    endpoint: 'location',
    slices: ['combo']
  }
});

export { saga, actions };
export default reducer;
