export default {
  messages: {
    cost: {
      create: 'New cost',
      update: 'Update cost',
      module: {
        name: 'Cost'
      },
      filter: {
        company: 'Company'
      },
      entity: {
        id: 'Id',
        protocol: 'Protocol',
        protocolConditional: 'Protocol',
        createDate: 'CreateDate',
        paymentDate: 'PaymentDate',
        company: 'Company',
        budget: 'Budget',
        items: 'Budget line',
        state: 'State',
        createdBy: 'CreatedBy',
        updatedBy: 'UpdatedBy',
        createdAt: 'CreatedAt',
        updatedAt: 'UpdatedAt',
        total: 'Total'
      },
      grid: [],
      actions: {
        create: 'Create',
        update: 'Update',
        delete: 'Delete'
      }
    }
  }
};
