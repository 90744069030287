export default {
  messages: {
    sidebar: {
      contexts: {
        investment: 'Investment'
      }
    },
    ims: {
      collectionField: {
        addBudgetLine: 'New item',
        addListItem: 'Add item'
      },
      confirm: {
        delete: {
          title: 'Are you sure?',
          message: 'Are you sure you want to delete this item?',
          confirmText: 'Yes',
          cancelText: 'Cancel'
        }
      }
    }
  }
};
