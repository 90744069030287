import Display from '@8powers/ams/components/Display';
import reducer, { saga as sagas, actions } from './ducks';
import * as translations from './translations';
import widgets from './widgets';
import BudgetNameDisplay from './components/BudgetNameDisplay';

Display.register('budgetName', BudgetNameDisplay);

export const name = 'budget';
export const realName = 'budget';
export { reducer, widgets, sagas, actions, translations };
export * from './routes';
