import { createDucks } from '@8powers/core/redux';

const { reducer, saga, actions } = createDucks('person', {
  person: {
    endpoint: 'person',
    slices: ['list', 'detail', 'create', 'edit']
  },
  personState: {
    endpoint: 'personState',
    slices: ['combo']
  }
});

export { saga, actions };
export default reducer;
