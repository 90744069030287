import styled from 'styled-components';
import { Button } from '@8powers/ams/components/UI';

export const Wrapper = styled.div`
  margin-bottom: 10rem;
`;

export const Label = styled.div`
  margin: 0 1rem 1.5rem;
  flex-basis: 100%;
  font-family: ${({ theme }) => theme['form-group-label-font-family']};
  font-size: ${({ theme }) => theme['form-group-label-font-size']};
  font-weight: ${({ theme }) => theme['form-group-label-font-weight']};
  color: ${({ theme }) => theme['form-group-label-color']};
  background: ${({ theme }) => theme['form-group-label-bg']};
`;

export const List = styled.div``;

export const BtnWrap = styled.div``;

export const NewItemButton = styled(Button)`
  border-radius: ${({ theme }) => theme['form-input-border-radius']};
  line-height: 1;
  padding-bottom: 10px;
`;

export const Summary = styled.div`
  text-align: right;
  font-weight: bold;
  max-width: 61rem;
`;

export const SummaryValue = styled.div`
  min-width: 10rem;
  padding-top: 2rem;
  display: inline-block;
  border-top: 1px solid #ddd;
`;
