import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createAction } from 'redux-starter-kit';
import { endpointComboSelector } from '@8powers/ams/redux/selectors';
import { createFetchAction } from '@8powers/core/redux/slice/utils';
import CollectionField from '../components/CollectionField';

class CollectionFieldContainer extends React.Component {
  state = {
    items: this.props.value || [],
    summary: 0
  };

  componentDidMount() {
    const { fetchUnits, fetchBudgetLines } = this.props;
    fetchUnits && fetchUnits();
    fetchBudgetLines && fetchBudgetLines();

    this.setState(prevState => ({
      items: prevState.items.map(item => ({
        ...item,
        id: Date.now() + Math.floor(Math.random() * 100)
      }))
    }));
  }

  getValString(value) {
    if (!value) {
      return '';
    }
    let stringVal = '';
    value.forEach(val => {
      stringVal += `[${  val.budgetLine.id  }.${  val.items.length  }]`;
    });

    return stringVal;
  }

  componentDidUpdate(prevProps) {
    if (
      this.getValString(prevProps.value) !== this.getValString(this.props.value)
    ) {
      /* eslint-disable */
      this.setState({
        items: (this.props.value || []).map(item => ({
          ...item,
          id: Date.now() + Math.floor(Math.random() * 100)
        }))
      });
      /* eslint-enable */
    }
  }

  getData() {
    const { name } = this.props;
    const { items } = this.state;

    return {
      target: {
        name,
        value: [...items]
      }
    };
  }

  handleBudgetLineAdd = () => {
    const { onChange, budgetLines } = this.props;
    this.setState(
      prevState => ({
        items: [
          ...prevState.items,
          {
            id: Date.now() + Math.floor(Math.random() * 100),
            budgetLine: budgetLines[0],
            items: []
          }
        ]
      }),
      () => onChange && onChange(this.getData())
    );
  };

  handleListItemAdd = id => {
    const { onChange } = this.props;
    const { items: budgetLines } = this.state;
    const updatedItems = budgetLines.map(budgetLine => {
      if (budgetLine.id === id) {
        return {
          ...budgetLine,
          items: [
            ...budgetLine.items,
            {
              id: Date.now() + Math.floor(Math.random() * 100),
              name: '',
              count: 0,
              unit: '',
              unitPrice: 0,
              total: 0
            }
          ]
        };
      }

      return budgetLine;
    });

    this.setState(
      {
        items: [...updatedItems]
      },
      () => onChange && onChange(this.getData())
    );
  };

  handleBudgetLineChange = (e, id) => {
    const { onChange, budgetLines } = this.props;
    const { target } = e;
    const { name, value } = target;
    const { items } = this.state;
    const updatedItems = items.map((item, index) => {
      if (index === id) {
        return {
          ...item,
          [name]: budgetLines.find(bl => bl.id === parseInt(value, 10))
        };
      }

      return item;
    });

    this.setState(
      {
        items: [...updatedItems]
      },
      () => onChange && onChange(this.getData())
    );
  };

  handleListItemChange = (e, budgetLineId, listItemId) => {
    const { onChange } = this.props;
    const { target } = e;
    const { name, value } = target;
    const { items: budgetLines } = this.state;

    const updatedBudgetLines = budgetLines.map(
      (budgetLine, budgetLineIndex) => {
        if (budgetLineIndex === budgetLineId) {
          const updatedItems = budgetLine.items.map(listItem => {
            if (listItem.id === listItemId) {
              const count = name === 'count' ? value : listItem.count;
              const unitPrice =
                name === 'unitPrice' ? value : listItem.unitPrice;
              const total =
                parseFloat(`${count}`.replace(',', '.')) *
                parseFloat(`${unitPrice}`.replace(',', '.'));
              return {
                ...listItem,
                [name]: value,
                total
              };
            }
            return listItem;
          });

          return {
            ...budgetLine,
            items: [...updatedItems]
          };
        }

        return budgetLine;
      }
    );

    this.setState(
      {
        items: [...updatedBudgetLines]
      },
      () => onChange && onChange(this.getData())
    );
  };

  handleBudgetLineRemove = id => {
    const { onChange } = this.props;
    const { items } = this.state;

    this.setState(
      {
        items: [...items.filter((item, index) => index !== id)]
      },
      () => onChange && onChange(this.getData())
    );
  };

  handleListItemRemove = (budgetLineId, listItemId) => {
    const { onChange } = this.props;
    const { items: budgetLines } = this.state;

    const updatedItems = budgetLines.map((budgetLine, index) => {
      if (index === budgetLineId) {
        return {
          ...budgetLine,
          items: [...budgetLine.items.filter(item => item.id !== listItemId)]
        };
      }
      return budgetLine;
    });

    this.setState(
      {
        items: [...updatedItems]
      },
      () => onChange && onChange(this.getData())
    );
  };

  render() {
    const { items, summary } = this.state;

    return (
      <CollectionField
        {...this.props}
        items={items}
        onBudgetLineAdd={this.handleBudgetLineAdd}
        onBudgetLineChange={this.handleBudgetLineChange}
        onBudgetLineRemove={this.handleBudgetLineRemove}
        onListItemAdd={this.handleListItemAdd}
        onListItemRemove={this.handleListItemRemove}
        onListItemChange={this.handleListItemChange}
        summary={summary}
      />
    );
  }
}

const mapStateToProps = state => {
  const unitData = endpointComboSelector(
    'budgetLineUnit',
    'budget/budgetLineUnit/combo'
  );
  const budgetLines = endpointComboSelector(
    'budget',
    'budget/budgetLine/combo'
  );
  return {
    budgetLines: [...budgetLines(state)],
    unitComboData: [...unitData(state)]
  };
};

const mapDispatchToProps = dispatch => {
  const slice = 'budget/budget/detail';
  const fetchBudgetLines = createFetchAction({
    endpoint: 'budgetLine',
    slice: 'budget/budgetLine/combo',
    blockUI: false
  });
  const fetchUnits = createFetchAction({
    endpoint: 'budgetLineUnit',
    slice: 'budget/budgetLineUnit/combo',
    blockUI: false
  });

  const actions = ['init', 'clear'].reduce((acc, name) => {
    if (Array.isArray(name)) {
      acc[name[1]] = createAction(`${slice}/${name[0]}`);
    } else {
      acc[name] = createAction(`${slice}/${name}`);
    }
    return acc;
  }, {});

  return {
    ...bindActionCreators(
      { ...actions, fetchBudgetLines, fetchUnits },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollectionFieldContainer);
