const widgets = {
  PersonForm: {
    component: 'FormContainer',
    children: [
      {
        component: 'Form',
        props: {
          groups: [
            {
              label: '',
              columns: [
                [
                  {
                    name: 'company',
                    label: 'person.entity.company',
                    type: 'endpointCombo',
                    endpoint: 'company',
                    slice: 'company/company/combo'
                  }
                ]
              ]
            },
            {
              label: '',
              columns: [
                [
                  {
                    name: 'firstName',
                    label: 'person.entity.firstName',
                    type: 'text'
                  },
                  {
                    name: 'email',
                    label: 'person.entity.email',
                    type: 'text'
                  },
                  {
                    name: 'position',
                    label: 'person.entity.position',
                    type: 'text'
                  }
                ],
                [
                  {
                    name: 'lastName',
                    label: 'person.entity.lastName',
                    type: 'text'
                  },
                  {
                    name: 'phone',
                    label: 'person.entity.phone',
                    type: 'text'
                  }
                ]
              ]
            }
          ]
        }
      }
    ]
  },
  PersonEditForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'person',
      slice: 'person/person/edit',
      operation: 'edit',
      fancyMask: true
    },
    children: [
      {
        component: 'FormContainer',
        children: [
          {
            component: 'Form',
            props: {
              groups: [
                {
                  label: '',
                  columns: [
                    [
                      {
                        name: 'company',
                        label: 'person.entity.company',
                        type: 'endpointCombo',
                        endpoint: 'company',
                        slice: 'company/company/combo'
                      }
                    ]
                  ]
                },
                {
                  label: '',
                  columns: [
                    [
                      {
                        name: 'firstName',
                        label: 'person.entity.firstName',
                        type: 'text'
                      },
                      {
                        name: 'email',
                        label: 'person.entity.email',
                        type: 'text'
                      },
                      {
                        name: 'position',
                        label: 'person.entity.position',
                        type: 'text'
                      }
                    ],
                    [
                      {
                        name: 'lastName',
                        label: 'person.entity.lastName',
                        type: 'text'
                      },
                      {
                        name: 'phone',
                        label: 'person.entity.phone',
                        type: 'text'
                      },
                      {
                        name: 'state',
                        label: 'person.entity.state',
                        type: 'endpointCombo',
                        endpoint: 'personState',
                        slice: 'person/personState/combo',
                        placeholder: '--',
                        displayField: 'label'
                      }
                    ]
                  ]
                }
              ]
            }
          }
        ]
      }
    ]
  },
  PersonCreateForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'person',
      slice: 'person/person/create',
      operation: 'create',
      fancyMask: true
    },
    children: [
      {
        component: 'PersonForm'
      }
    ]
  },
  PersonDetailContainer: {
    component: 'EndpointSelectedContainer',
    props: {
      endpoint: 'person',
      slice: 'person/person/list'
    },
    children: [
      {
        component: 'EndpointDetail',
        props: {
          config: {
            groups: [
              {
                label: 'Dane podstawowe',
                columns: [
                  [
                    {
                      key: 'id',
                      label: 'person.entity.id'
                    },
                    {
                      key: 'company',
                      label: 'person.entity.company',
                      type: 'objectProps',
                      typeProps: {
                        props: ['name']
                      }
                    }
                  ]
                ]
              },
              {
                label: '',
                columns: [
                  [
                    {
                      key: 'firstName',
                      label: 'person.entity.firstName'
                    },
                    {
                      key: 'email',
                      label: 'person.entity.email'
                    },
                    {
                      key: 'position',
                      label: 'person.entity.position'
                    },
                    {
                      key: 'createdAt',
                      label: 'person.entity.createdAt',
                      type: 'datetime'
                    }
                  ],
                  [
                    {
                      key: 'lastName',
                      label: 'person.entity.lastName'
                    },
                    {
                      key: 'phone',
                      label: 'person.entity.phone'
                    },
                    {
                      key: 'state',
                      label: 'person.entity.state',
                      type: 'objectProps',
                      typeProps: {
                        props: ['label']
                      }
                    },
                    {
                      key: 'updatedAt',
                      label: 'person.entity.updatedAt',
                      type: 'relativeDate'
                    }
                  ]
                ]
              }
            ]
          }
        }
      }
    ]
  }
};

export default widgets;
