import React from 'react';
import ListItem from './ListItem';
import { Wrapper, Row, BudgetLineName, List, Num } from './styles';

class CollectionFieldItem extends React.Component {
  render() {
    const { item, index, onListItemChange, preview, readOnly } = this.props;

    return (
      <Wrapper>
        <Row>
          <Num>{index}</Num>
          <BudgetLineName>
            {item.budgetLine.listOrder}. {item.budgetLine.name}
          </BudgetLineName>
        </Row>
        <List>
          {item.items.map((listItem, index) => (
            <ListItem
              key={listItem.id}
              index={index + 1}
              budgetLine={item}
              item={listItem}
              onInputChange={onListItemChange}
              preview={preview}
              readOnly={readOnly}
            />
          ))}
        </List>
      </Wrapper>
    );
  }
}

export default CollectionFieldItem;
