import { createDucks } from '@8powers/core/redux';

const { reducer, saga, actions } = createDucks('contract', {
  contract: {
    endpoint: 'contract',
    slices: ['list', 'detail', 'create', 'edit']
  },
  offer: {
    endpoint: 'offer',
    slices: ['combo']
  },
  company: {
    endpoint: 'company',
    slices: ['combo']
  },
  type: {
    endpoint: 'contractType',
    slices: ['combo']
  },
  state: {
    endpoint: 'contractState',
    slices: ['combo']
  }
});

export { saga, actions };
export default reducer;
