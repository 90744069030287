import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { merge } from 'lodash';

import * as coreModule from '@8powers/core';
import * as amsModule from '@8powers/ams';
import configureApplication from '@8powers/core/configureApplication';
import RouteContainer from '@8powers/ams/containers/RouteContainer';
import Root from '@8powers/ams/components/Root';
import configFromBackend from './configFromBackend';
import history from './history';

import * as imsModule from './modules/ims';
import * as investmentModule from './modules/investment';
import * as teamModule from './modules/team';
import * as companyModule from './modules/company';
import * as personModule from './modules/person';
import * as budgetModule from './modules/budget';
import * as fullBudgetModule from './modules/fullBudget';
import * as profileModule from './modules/profile';
import * as offerModule from './modules/offer';
import * as offerItemModule from './modules/offerItem';
import * as contractModule from './modules/contract';
import * as contractItemModule from './modules/contractItem';
import * as protocolModule from './modules/protocol';
import * as protocolItemModule from './modules/protocolItem';
import * as costModule from './modules/cost';
import * as costItemModule from './modules/costItem';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';

let persitedStore = {};

try {
  persitedStore = JSON.parse(localStorage.getItem('store') || '{}');
} catch (error) {
  console.error('persitedStore', error); // eslint-disable-line
}

const config = {
  exposeStore: process.env.NODE_ENV !== 'production',
  ...configFromBackend,
  initialState: merge(configFromBackend.initialState, persitedStore)
};

configureApplication(
  Root,
  RouteContainer,
  history,
  // module order has matter: [client modules, ams other modules, ams module, core module]
  [
    profileModule,
    budgetModule,
    fullBudgetModule,
    investmentModule,
    teamModule,
    companyModule,
    personModule,
    offerModule,
    offerItemModule,
    contractModule,
    contractItemModule,
    protocolModule,
    protocolItemModule,
    costModule,
    costItemModule,
    amsModule,
    imsModule,
    coreModule
  ],
  config
).then(({ store, routes }) => {
  ReactDOM.render(
    <Provider store={store}>
      <App routes={routes} />
    </Provider>,
    document.getElementById('root')
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
