export default {
  messages: {
    team: {
      create: 'Dodaj członka zespołu',
      update: 'Aktualizuj członka zespołu',
      module: {
        name: 'Zespoły'
      },
      entity: {
        id: 'Id',
        investment: 'Inwestycja',
        profile: 'Profil',
        position: 'Pozycja',
        createdAt: 'Utworzono',
        updatedAt: 'Aktualizowano'
      },
      grid: [],
      actions: {
        create: 'Dodaj',
        delete: 'Usuń'
      }
    }
  }
};
