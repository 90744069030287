export default {
  messages: {
    costItem: {
      create: 'New costItem',
      update: 'Update costItem',
      module: {
        name: 'CostItem'
      },
      entity: {
        id: 'Id',
        cost: 'Cost',
        budgetLine: 'BudgetLine',
        description: 'Description',
        amount: 'Amount',
        contractItem: 'ContractItem',
        createdBy: 'CreatedBy',
        updatedBy: 'UpdatedBy',
        createdAt: 'CreatedAt',
        updatedAt: 'UpdatedAt'
      },
      grid: [],
      actions: {
        create: 'Create',
        update: 'Update',
        delete: 'Delete'
      }
    }
  }
};
