export const routes = [
  {
    name: 'team_list',
    path: '/team',
    componentConfig: [
      {
        component: 'EndpointListContainer',
        props: {
          endpoint: 'team',
          slice: 'team/team/list',
          selectAction: {
            preview: {
              meta: {
                modal: {
                  body: [
                    {
                      component: 'TeamDetailContainer'
                    }
                  ]
                }
              }
            },
            edit: {
              type: 'team/team/edit/start',
              meta: {
                modal: {
                  title: 'team.actions.update',
                  body: [
                    {
                      component: 'TeamEditForm'
                    }
                  ]
                }
              }
            }
          }
        },
        children: [
          {
            component: 'Grid',
            props: {
              title: 'team.module.name',
              icon: 'helmet-alt',
              config: {
                columns: {
                  id: {
                    label: 'team.entity.id'
                  },
                  investment: {
                    label: 'team.entity.investment',
                    display: {
                      type: 'objectProps',
                      props: ['name']
                    }
                  },
                  profile: {
                    label: 'team.entity.profile',
                    display: {
                      type: 'objectProps',
                      props: ['firstName', 'lastName']
                    }
                  },
                  position: {
                    label: 'team.entity.position'
                  },
                  createdAt: {
                    label: 'team.entity.createdAt',
                    display: {
                      type: 'datetime'
                    }
                  },
                  updatedAt: {
                    label: 'team.entity.updatedAt',
                    display: {
                      type: 'datetime'
                    }
                  }
                },
                filtersConfig: [],
                actions: [
                  {
                    label: 'team.actions.create',
                    type: 'create',
                    icon: 'plus',
                    action: {
                      type: 'team/team/edit/start',
                      meta: {
                        modal: {
                          title: 'team.create',
                          body: [
                            {
                              component: 'TeamCreateForm'
                            }
                          ]
                        }
                      }
                    }
                  }
                ],
                recordActions: [
                  {
                    label: '',
                    type: 'edit',
                    icon: 'pen',
                    action: {
                      type: 'team/team/edit/start',
                      meta: {
                        modal: {
                          title: 'team.update',
                          body: [
                            {
                              component: 'TeamEditForm'
                            }
                          ]
                        }
                      }
                    }
                  },
                  {
                    label: '',
                    type: 'delete',
                    icon: 'trash',
                    action: {
                      type: 'team/team/list/delete',
                      meta: {
                        API: {
                          endpoint: 'team',
                          operation: 'delete'
                        },
                        confirm: {
                          props: {
                            title: 'ims.confirm.delete.title',
                            message: 'ims.confirm.delete.message',
                            confirmText: 'ims.confirm.delete.confirmText',
                            cancelText: 'ims.confirm.delete.cancelText'
                          },
                          confirmed: false
                        }
                      }
                    }
                  }
                ]
              }
            }
          }
        ]
      }
    ],
    exact: true,
    label: 'team.module.name',
    icon: 'helmet-alt'
  }
];
