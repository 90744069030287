export default {
  messages: {
    budget: {
      create: 'New budget',
      update: 'Update budget',
      module: {
        name: 'Budget'
      },
      entity: {
        id: 'Id',
        name: 'Name',
        startDate: 'StartDate',
        endDate: 'EndDate',
        investment: 'Investment',
        budgetLines: 'BudgetLines',
        costs: 'Costs',
        createdBy: 'CreatedBy',
        updatedBy: 'UpdatedBy',
        createdAt: 'CreatedAt',
        updatedAt: 'UpdatedAt'
      },
      grid: [],
      rowActions: {
        edit: 'Edit',
        settings: 'Settings',
        delete: 'Delete'
      },
      actions: {
        create: 'Create',
        delete: 'Delete'
      },
      filter: {
        investment: 'Investment'
      },
      line: {
        list: {
          title: {
            preview: 'Budget',
            edit: 'Budget planning',
            schedule: 'Schedule'
          },
          modeSwitch: {
            preview: 'preview',
            edit: 'planning',
            schedule: 'schedule'
          },
          rowActions: {
            newLine: 'New line',
            newSubline: 'New subline',
            duplicate: 'Duplicate',
            delete: 'Delete'
          },
          empty: "You don't have any budget lines."
        },
        modal: {
          contractItem: 'Contract item',
          costItem: 'Cost item'
        }
      }
    }
  }
};
