export default {
  messages: {
    offer: {
      create: 'New offer',
      update: 'Update offer',
      module: {
        name: 'Offer'
      },
      entity: {
        id: 'Id',
        investment: 'Investment',
        company: 'Company',
        sendDate: 'SendDate',
        totalAmount: 'TotalAmount',
        description: 'Description',
        type: 'Type',
        items: 'Items',
        contracts: 'Contracts',
        createdBy: 'CreatedBy',
        updatedBy: 'UpdatedBy',
        createdAt: 'CreatedAt',
        updatedAt: 'UpdatedAt'
      },
      grid: [],
      actions: {
        create: 'Create',
        update: 'Update',
        delete: 'Delete'
      },
      recordActions: {
        addContract: 'Add contract',
        edit: 'Edit',
        delete: 'Delete'
      }
    }
  }
};
