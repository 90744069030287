import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Header, Column } from './styles';

class Donut extends React.Component {
  render() {
    const { title, current, total, children } = this.props;
    const test = canvas => {
      const ctx = canvas.getContext('2d');
      const gradient = ctx.createLinearGradient(0, 0, 435, 215);
      gradient.addColorStop(0, '#f82a73');
      gradient.addColorStop(1, '#fd5a33');

      return {
        datasets: [
          {
            data: [current, total - current],
            backgroundColor: [gradient]
          }
        ]
      };
    };
    const options = {
      cutoutPercentage: 92,
      responsive: true
    };

    return (
      <Column>
        <Header>{title}</Header>
        <Doughnut ref={this.chartRef} data={test} options={options} />
        {children}
      </Column>
    );
  }
}

export default Donut;
