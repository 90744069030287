const widgets = {
  OfferItemForm: {
    component: 'FormContainer',
    children: [
      {
        component: 'Form',
        props: {
          fields: [
            {
              name: 'offer',
              label: 'offerItem.entity.offer',
              type: 'endpointCombo',
              endpoint: 'offer',
              slice: 'offerItem/offer/combo',
              displayField: 'id'
            },
            {
              name: 'budgetLine',
              label: 'offerItem.entity.budgetLine',
              type: 'endpointCombo',
              endpoint: 'budgetLine',
              slice: 'offerItem/budgetLine/combo'
            },
            {
              name: 'description',
              label: 'offerItem.entity.description',
              type: 'text'
            },
            {
              name: 'count',
              label: 'offerItem.entity.count',
              type: 'text'
            },
            {
              name: 'unit',
              label: 'offerItem.entity.unit',
              type: 'endpointCombo',
              endpoint: 'budgetLineUnit',
              slice: 'offerItem/budgetLineUnit/combo',
              displayField: 'label',
              valueField: 'value'
            },
            {
              name: 'unitPrice',
              label: 'offerItem.entity.unitPrice',
              type: 'text'
            },
            {
              name: 'cost',
              label: 'offerItem.entity.cost',
              type: 'text'
            }
          ]
        }
      }
    ]
  },
  OfferItemEditForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'offerItem',
      slice: 'offerItem/offerItem/edit',
      operation: 'edit',
      fancyMask: true
    },
    children: [
      {
        component: 'OfferItemForm'
      }
    ]
  },
  OfferItemCreateForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'offerItem',
      slice: 'offerItem/offerItem/create',
      operation: 'create',
      fancyMask: true
    },
    children: [
      {
        component: 'OfferItemForm'
      }
    ]
  },
  OfferItemDetailContainer: {
    component: 'EndpointSelectedContainer',
    props: {
      endpoint: 'offerItem',
      slice: 'offerItem/offerItem/list'
    },
    children: [
      {
        component: 'EndpointDetail',
        props: {
          config: {
            groups: [
              {
                label: 'Dane podstawowe',
                columns: [
                  [
                    {
                      key: 'id',
                      label: 'offerItem.entity.id'
                    },
                    {
                      key: 'offer',
                      label: 'offerItem.entity.offer',
                      type: 'objectProps',
                      typeProps: {
                        props: ['description']
                      }
                    },
                    {
                      key: 'budgetLine',
                      label: 'offerItem.entity.budgetLine',
                      type: 'objectProps',
                      typeProps: {
                        props: ['name']
                      }
                    },
                    {
                      key: 'description',
                      label: 'offerItem.entity.description'
                    },
                    {
                      key: 'cost',
                      label: 'offerItem.entity.cost'
                    },
                    {
                      key: 'count',
                      label: 'offerItem.entity.count'
                    },
                    {
                      key: 'unit',
                      label: 'offerItem.entity.unit'
                    },
                    {
                      key: 'unitPrice',
                      label: 'offerItem.entity.unitPrice'
                    },
                    {
                      key: 'createdBy',
                      label: 'offerItem.entity.createdBy',
                      type: 'objectProps',
                      typeProps: {
                        props: ['firstName', 'lastName']
                      }
                    },
                    {
                      key: 'updatedBy',
                      label: 'offerItem.entity.updatedBy',
                      type: 'objectProps',
                      typeProps: {
                        props: ['firstName', 'lastName']
                      }
                    },
                    {
                      key: 'createdAt',
                      label: 'offerItem.entity.createdAt',
                      type: 'datetime'
                    },
                    {
                      key: 'updatedAt',
                      label: 'offerItem.entity.updatedAt',
                      type: 'datetime'
                    }
                  ]
                ]
              }
            ]
          }
        }
      }
    ]
  }
};

export default widgets;
