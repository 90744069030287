import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createAction } from 'redux-starter-kit';
import { createFetchAction } from '@8powers/core/redux/slice/utils';
import { endpointListSelector } from '@8powers/ams/redux/selectors';
import DashboardOverview from '@8powers/ams/components/Dashboard/Overview';

class DashboardRevenueContainer extends React.Component {
  componentDidMount() {
    this.props.fetch();
  }

  render() {
    const { title, data } = this.props;
    let summary = [];
    const values = [];
    const labels = [];
    const PLN = Intl.NumberFormat('pl', {
      style: 'currency',
      currency: 'PLN',
      useGrouping: true,
      maximumFractionDigits: 0
    });
    if (data.length) {
      summary = [
        {
          label: 'Bieżący miesiąc',
          value: PLN.format(data[data.length - 1].count)
        },
        {
          label: 'Poprzedni miesiąc',
          value: PLN.format(data[data.length - 2].count)
        }
      ];
      data.forEach(item => {
        values.push(item.count);
        labels.push(item.label);
      });
    }

    return (
      <DashboardOverview
        title={title}
        data={summary}
        labels={labels}
        values={values}
      />
    );
  }
}

const mapStateToProps = (state, { endpoint, slice }) => {
  const selectListData = endpointListSelector(endpoint, slice);
  return {
    ...selectListData(state)
  };
};

const mapDispatchToProps = (dispatch, { endpoint, slice }) => {
  const fetch = createFetchAction({ endpoint, slice });
  const actions = ['init', 'clear'].reduce((acc, name) => {
    if (Array.isArray(name)) {
      acc[name[1]] = createAction(`${slice}/${name[0]}`);
    } else {
      acc[name] = createAction(`${slice}/${name}`);
    }
    return acc;
  }, {});

  return {
    ...bindActionCreators({ ...actions, fetch }, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardRevenueContainer);
