import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createAction } from 'redux-starter-kit';
import { createFetchAction } from '@8powers/core/redux/slice/utils';
import { Select } from '@8powers/ams/components/Form/Controls';
import { endpointComboSelector } from '@8powers/ams/redux/selectors';

class WideEndpointComboContainer extends React.Component {
  static defaultProps = {
    valueField: 'id',
    displayField: 'name',
    displayIndent: '-'
  };

  componentDidMount() {
    const { fetch, fetchProps } = this.props;
    fetch && fetch(fetchProps);
  }

  render() {
    const {
      name,
      placeholder,
      value,
      className,
      options,
      multiple,
      displayField,
      valueField,
      onChange,
      displayIndent,
      renderer,
      tabIndex,
      readOnly,
      config
    } = this.props;

    const selectOptions = options.map(option => ({
      value: option[valueField],
      label:
        (option.nodeDepth ? `${displayIndent.repeat(option.nodeDepth)} ` : '') +
        option[displayField]
    }));

    const selectValue =
      (typeof value === 'object' && value !== null && value[valueField]) ||
      value;
    return renderer ? (
      renderer({
        className,
        value: selectValue,
        options: selectOptions,
        onChange
      })
    ) : (
      <Select
        placeholder={placeholder || undefined}
        className={className}
        name={name}
        value={selectValue}
        options={selectOptions}
        multiple={multiple}
        onChange={onChange}
        tabIndex={tabIndex}
        readOnly={readOnly}
        isClearable={config ? config.isClearable : false}
        styles={{
          control: styles => ({
            ...styles,
            minWidth: '300px',
            border: 0,
            background: 'transparent',
            boxShadow: 'none'
          }),
          menu: styles => ({
            ...styles,
            minWidth: '400px',
            zIndex: 3
          })
        }}
      />
    );
  }
}

const mapStateToProps = (state, { endpoint, slice }) => {
  const selectListData = endpointComboSelector(endpoint, slice);
  return {
    options: selectListData(state)
  };
};

const mapDispatchToProps = (dispatch, { endpoint, slice }) => {
  const fetch = createFetchAction({ endpoint, slice, blockUI: false });
  const actions = ['init', 'clear'].reduce((acc, name) => {
    if (Array.isArray(name)) {
      acc[name[1]] = createAction(`${slice}/${name[0]}`);
    } else {
      acc[name] = createAction(`${slice}/${name}`);
    }
    return acc;
  }, {});

  return {
    ...bindActionCreators({ ...actions, fetch }, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WideEndpointComboContainer);
