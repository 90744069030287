import { createDucks } from '@8powers/core/redux';

const { reducer, saga, actions } = createDucks('cost', {
  cost: {
    endpoint: 'cost',
    slices: ['list', 'detail', 'create', 'edit']
  },
  protocol: {
    endpoint: 'protocol',
    slices: ['combo']
  },
  company: {
    endpoint: 'company',
    slices: ['combo']
  },
  budget: {
    endpoint: 'budget',
    slices: ['combo']
  },
  state: {
    endpoint: 'costState',
    slices: ['combo']
  }
});

export { saga, actions };
export default reducer;
