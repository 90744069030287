export default {
  messages: {
    investment: {
      create: 'New investment',
      update: 'Update investment',
      module: {
        name: 'Investment'
      },
      filter: {
        state: 'State'
      },
      entity: {
        id: 'Id',
        name: 'Name',
        code: 'Code',
        address: {
          label: 'Address',
          city: 'City',
          street: 'Street',
          buildingNumber: 'Building number'
        },
        startDate: 'StartDate',
        documentsPath: 'DocumentsPath',
        teamMembers: 'TeamMembers',
        teamSuggest: 'Find team member',
        targetCompany: 'TargetCompany',
        projectManager: 'ProjectManager',
        budgets: 'Budgets',
        activeBudget: 'ActiveBudget',
        offers: 'Offers',
        state: 'State',
        createdAt: 'CreatedAt',
        updatedAt: 'UpdatedAt',
        project: 'Project',
        plannedEndDate: 'PlannedEndDate',
        logo: 'Logo'
      },
      grid: [],
      actions: {
        create: 'Create',
        delete: 'Delete'
      },
      form: {
        basic: 'Basic data',
        address: 'Address',
        team: 'Team members'
      }
    }
  }
};
