import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Display from '@8powers/ams/components/Display';
import {
  Wrapper,
  Label,
  List,
  BtnWrap,
  NewItemButton,
  Summary,
  SummaryValue
} from './styles';
import Item from './Item';

class CollectionField extends Component {
  render() {
    const {
      label,
      items,
      budgetLines,
      unitComboData,
      onBudgetLineAdd,
      onBudgetLineRemove,
      onBudgetLineChange,
      onListItemAdd,
      onListItemChange,
      onListItemRemove,
      t
    } = this.props;

    let summary = 0;
    items.forEach(budgetLine => {
      budgetLine.items.forEach(item => {
        summary += item.total;
      });
    });

    return (
      <Wrapper>
        <Label>{label}</Label>
        <List>
          {items.map((item, index) => (
            <Item
              index={index + 1}
              key={item.id}
              item={item}
              collectionRow={index}
              budgetLines={budgetLines}
              unitComboData={unitComboData}
              onChange={onBudgetLineChange}
              onRemove={onBudgetLineRemove}
              onListItemAdd={onListItemAdd}
              onListItemChange={onListItemChange}
              onListItemRemove={onListItemRemove}
            />
          ))}
        </List>

        <BtnWrap>
          <NewItemButton type="button" onClick={onBudgetLineAdd}>
            {t('ims.collectionField.addBudgetLine')}
          </NewItemButton>
        </BtnWrap>

        <Summary>
          <SummaryValue>
            <Display value={summary} type="price" /> zł
          </SummaryValue>
        </Summary>
      </Wrapper>
    );
  }
}

export default withTranslation()(CollectionField);
