const widgets = {
  ProtocolEditForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'protocol',
      slice: 'protocol/protocol/edit',
      operation: 'edit',
      fancyMask: true,
      extractFromRecord: [
        'collection',
        'backendCostPercentage',
        'buildCostPercentage',
        'date'
      ]
    },
    children: [
      {
        component: 'FormContainer',
        children: [
          {
            component: 'Form',
            props: {
              fields: [
                {
                  name: 'contract',
                  label: 'protocol.entity.contractConditional',
                  type: 'conditionalField',
                  displayField: 'label',
                  rawControl: true
                },
                {
                  name: 'date',
                  label: 'protocol.entity.date',
                  type: 'date'
                },
                {
                  name: 'endDate',
                  label: 'protocol.entity.endDate',
                  type: 'date'
                },
                {
                  name: 'buildCostPercentage',
                  label: 'protocol.entity.buildCostPercentage',
                  type: 'number'
                },
                {
                  name: 'backendCostPercentage',
                  label: 'protocol.entity.backendCostPercentage',
                  type: 'number'
                },
                {
                  name: 'additionalConditions',
                  label: 'protocol.entity.additionalConditions',
                  type: 'richText',
                  value:
                    '2. Rozliczenie materiałów powierzonych:<br>' +
                    '    a. Podwykonawcy nie zostały powierzone do wbudowania żadne materiały. **<br>' +
                    '    b. Podwykonawcy zostały powierzone do wbudowania materiały zgodnie z protokołem przekazania z dnia .........., który<br>' +
                    'stanowi załącznik nr .......... do niniejszego protokołu. **<br>' +
                    '    c. Podwykonawca przedłożył rozliczenie materiałów powierzonych zgodnie z załącznikiem nr .......... do niniejszego<br>' +
                    'protokołu. Wykonarca przyjmuje przedłozone rozliczenia bez uwag / z uwagami. **<br>' +
                    '3.Termin wykonania robót:<br>' +
                    '    a. zgodnie z terminem umownym **b. niezgodnie z terminem umownym **<br>' +
                    '    c. opóźnienie wynosi .......... dni **<br>' +
                    '4. Kara za opóźnienie w wykonaniu zakresu robót wynosi .......... dni<br>' +
                    '5. Jakość zrealizowanych robót:<br>' +
                    '    a. Na tym etapie robót nie oceniano jakości wykonanych robót<br>' +
                    '    b. Przedniot odbioru został zrealizowany bezusterkowo / z usterkami według zestawienia w pkt 6.<br>' +
                    '6. Wykaz usterek<br>' +
                    '7. Ustalone terminy usunięcia usterek<br>' +
                    '8. Inne<br>' +
                    '9. Wykaz załączników<br>' +
                    '    a. Oświadczenie o niezaleganiu z płatnościami<br>' +
                    '    b. Oświadczenie o niezaleganiu z płatnościami Dalszych Podwykonawców<br>' +
                    '    c. ..........................................................................................................................................................<br>' +
                    '10. Protokół został sporządzony w dwóch jednobrzmiących egzemplarzach po jednej dla każdej ze stron.'
                },
                {
                  name: 'penalty',
                  label: 'protocol.entity.penalty',
                  type: 'number',
                  value: 0
                },
                {
                  name: 'final',
                  label: 'protocol.entity.final',
                  type: 'checkbox'
                },
                {
                  name: 'collection',
                  label: null,
                  type: 'protocolItems',
                  rawControl: true,
                  relatedValues: ['final']
                }
              ]
            }
          }
        ]
      }
    ]
  },
  ProtocolCreateForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'protocol',
      slice: 'protocol/protocol/create',
      operation: 'create',
      fancyMask: true,
      extractFromRecord: [
        'contract',
        'date',
        'penalty',
        'final',
        'collection',
        'backendCostPercentage',
        'buildCostPercentage'
      ]
    },
    children: [
      {
        component: 'FormContainer',
        children: [
          {
            component: 'Form',
            props: {
              fields: [
                {
                  name: 'contract',
                  label: 'protocol.entity.contract',
                  type: 'endpointCombo',
                  endpoint: 'contract',
                  slice: 'protocol/contract/combo',
                  displayField: 'label'
                },
                {
                  name: 'date',
                  label: 'protocol.entity.date',
                  type: 'date'
                },
                {
                  name: 'endDate',
                  label: 'protocol.entity.endDate',
                  type: 'date'
                },
                {
                  name: 'buildCostPercentage',
                  label: 'protocol.entity.buildCostPercentage',
                  type: 'number'
                },
                {
                  name: 'backendCostPercentage',
                  label: 'protocol.entity.backendCostPercentage',
                  type: 'number'
                },
                {
                  name: 'additionalConditions',
                  label: 'protocol.entity.additionalConditions',
                  type: 'richText',
                  value:
                    '<ol start="2"><li>Rozliczenie materiałów powierzonych:<ol type="a">' +
                    '<li>Podwykonawcy nie zostały powierzone do wbudowania żadne materiały. **</li>' +
                    '<li>Podwykonawcy zostały powierzone do wbudowania materiały zgodnie z protokołem przekazania z dnia ................, który ' +
                    'stanowi załącznik nr .......... do niniejszego protokołu. **</li>' +
                    '<li>Podwykonawca przedłożył rozliczenie materiałów powierzonych zgodnie z załącznikiem nr .......... do niniejszego ' +
                    'protokołu. Wykonarca przyjmuje przedłozone rozliczenia bez uwag / z uwagami. **</li></ol></li>' +
                    '<li>Termin wykonania robót:<ol type="a">' +
                    '<li>zgodnie z terminem umownym **b. niezgodnie z terminem umownym **</li>' +
                    '<li>opóźnienie wynosi .......... dni **</li></ol></li>' +
                    '<li>Kara za opóźnienie w wykonaniu zakresu robót wynosi .......... dni</li>' +
                    '<li>Jakość zrealizowanych robót:<ol type="a">' +
                    '<li>Na tym etapie robót nie oceniano jakości wykonanych robót</li>' +
                    '<li>Przedniot odbioru został zrealizowany bezusterkowo / z usterkami według zestawienia w pkt 6.</li></ol></li>' +
                    '<li>Wykaz usterek</li>' +
                    '<li>Ustalone terminy usunięcia usterek</li>' +
                    '<li>Inne</li>' +
                    '<li>Wykaz załączników<ol type="a">' +
                    '<li>Oświadczenie o niezaleganiu z płatnościami</li>' +
                    '<li>Oświadczenie o niezaleganiu z płatnościami Dalszych Podwykonawców</li>' +
                    '<li>..........................................................................................................................................................</li></ol></li></ol>'
                },
                {
                  name: 'penalty',
                  label: 'protocol.entity.penalty',
                  type: 'number'
                },
                {
                  name: 'final',
                  label: 'protocol.entity.final',
                  type: 'checkbox'
                },
                {
                  name: 'collection',
                  label: null,
                  type: 'protocolItems',
                  rawControl: true,
                  relatedValues: ['contract', 'final']
                }
              ]
            }
          }
        ]
      }
    ]
  },
  ProtocolDetail: {
    component: 'EndpointDetail',
    props: {
      config: {
        groups: [
          {
            label: 'Dane podstawowe',
            columns: [
              [
                {
                  key: 'id',
                  label: 'protocol.entity.id'
                }
              ],
              [
                {
                  key: 'final',
                  label: 'protocol.entity.final'
                }
              ]
            ]
          },
          {
            label: '',
            columns: [
              [
                {
                  key: 'contract.number',
                  label: 'protocol.entity.contract'
                }
              ]
            ]
          },
          {
            label: '',
            columns: [
              [
                {
                  key: 'date',
                  label: 'protocol.entity.date',
                  type: 'date'
                },
                {
                  key: 'buildCostPercentage',
                  label: 'protocol.entity.buildCostPercentage'
                },
                {
                  key: 'penalty',
                  label: 'protocol.entity.penalty'
                }
              ],
              [
                {
                  key: 'endDate',
                  label: 'protocol.entity.endDate',
                  type: 'date'
                },
                {
                  key: 'backendCostPercentage',
                  label: 'protocol.entity.backendCostPercentage'
                },
                {
                  key: 'guarantee',
                  label: 'protocol.entity.guarantee'
                }
              ]
            ]
          },
          {
            label: '',
            columns: [
              [
                {
                  key: 'additionalConditions',
                  label: 'protocol.entity.additionalConditions'
                },
                {
                  key: 'collection',
                  label: '',
                  type: 'protocolItems',
                  collection: true,
                  typeProps: {
                    preview: true,
                    relatedValues: []
                  }
                }
              ]
            ]
          },
          {
            label: '',
            columns: [
              [
                {
                  key: 'createdAt',
                  label: 'protocol.entity.createdAt',
                  type: 'relativeDate'
                },
                {
                  key: 'createdBy',
                  label: 'protocol.entity.createdBy',
                  type: 'objectProps',
                  typeProps: {
                    props: ['firstName', 'lastName']
                  }
                }
              ],
              [
                {
                  key: 'updatedAt',
                  label: 'protocol.entity.updatedAt',
                  type: 'relativeDate'
                },
                {
                  key: 'updatedBy',
                  label: 'protocol.entity.updatedBy',
                  type: 'objectProps',
                  typeProps: {
                    props: ['firstName', 'lastName']
                  }
                }
              ]
            ]
          }
        ]
      }
    }
  },
  ProtocolDetailContainer: {
    component: 'EndpointSelectedContainer',
    props: {
      endpoint: 'protocol',
      slice: 'protocol/protocol/list'
    },
    children: [
      {
        component: 'ProtocolDetail'
      }
    ]
  },
  ProtocolDetailEndpointContainer: {
    component: 'EndpointDetailContainer',
    props: {
      endpoint: 'protocol',
      slice: 'protocol/protocol/detail'
    },
    children: [
      {
        component: 'ProtocolDetail'
      }
    ]
  }
};

export default widgets;
