export default {
  messages: {
    contract: {
      create: 'New contract',
      update: 'Update contract',
      module: {
        name: 'Contract'
      },
      filter: {
        company: 'Company'
      },
      entity: {
        id: 'Id',
        offer: 'Offer',
        number: 'Number',
        offerConditional: 'Offer',
        company: 'Company',
        signDate: 'SignDate',
        endDate: 'EndDate',
        description: 'Description',
        guarantee: 'Guarantee',
        daysForPayment: 'DaysForPayment',
        type: 'Type',
        items: 'Items',
        protocols: 'Protocols',
        createdBy: 'CreatedBy',
        updatedBy: 'UpdatedBy',
        state: 'State',
        createdAt: 'CreatedAt',
        updatedAt: 'UpdatedAt',
        accountNumber: 'AccountNumber',
        guaranteeMonths: 'GuaranteeMonths',
        otherDates: 'OtherDates',
        mediaCosts: 'MediaCosts',
        wasteCosts: 'WasteCosts',
        securityRelease: 'SecurityRelease',
        backendCosts: 'BackendCosts',
        additionalConditions: 'AdditionalConditions',
        buildStartDate: 'BuildStartDate'
      },
      grid: [],
      actions: {
        create: 'Create',
        update: 'Update',
        delete: 'Delete'
      },
      recordActions: {
        addProtocol: 'Protocol',
        edit: 'Edit',
        pdf: 'PDF',
        delete: 'Delete'
      }
    }
  }
};
