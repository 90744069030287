export default {
  messages: {
    profile: {
      create: 'New profile',
      update: 'Update profile',
      module: {
        name: 'Users'
      },
      entity: {
        id: 'Id',
        user: {
          label: 'User',
          email: 'Email',
          password: 'Password',
          passwordRepeat: 'Repeat password'
        },
        firstName: 'FirstName',
        lastName: 'LastName',
        phone: 'Phone',
        position: 'Position',
        teamMembers: 'TeamMembers',
        managedInvestments: 'ManagedInvestments',
        state: 'State',
        createdAt: 'CreatedAt',
        updatedAt: 'UpdatedAt',
        stateList: 'StateList',
        stateMap: 'StateMap',
        role: 'Role'
      },
      grid: [],
      actions: {
        create: 'Create',
        update: 'Update',
        delete: 'Delete'
      }
    }
  }
};
