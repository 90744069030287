import Field from '@8powers/ams/components/Form/Field';
import reducer, { saga as sagas, actions } from './ducks';
import * as translations from './translations';
import widgets from './widgets';
import CustomInput from './components/CustomInput';

Field.register('customInput', { input: CustomInput }, true);

export const name = 'company';
export const realName = 'company';
export { reducer, widgets, sagas, actions, translations };
export * from './routes';
