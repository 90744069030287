import Field from '@8powers/ams/components/Form/Field';
import reducer, { saga as sagas, actions } from './ducks';
import * as translations from './translations';
import widgets from './widgets';
import OfferCollectionContainer from './containers/OfferCollectionContainer';

Field.register('offerCollection', { input: OfferCollectionContainer }, true);

export const name = 'contract';
export const realName = 'contract';
export { reducer, widgets, sagas, actions, translations };
export * from './routes';
