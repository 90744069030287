const widgets = {
  Client1DashboardOverview: {
    component: 'DashboardOverview', // how
    props: {
      title: 'Revenue',
      data: [
        {
          label: 'Current week',
          value: '72 145 zł'
        },
        {
          label: 'Previous week',
          value: '55 345 zł'
        }
      ]
    }
  },
  Client1DashboardSummary: {
    component: 'DashboardSummary', // how
    props: {
      title: 'Summary',
      data: [
        {
          label: 'Order value',
          value: '42645'
        },
        {
          label: 'Calls',
          value: '2750'
        }
      ]
    }
  },
  Client1DashboardLastOrders: {
    component: 'DashboardList', // what
    props: {
      title: 'Latest orders',
      moreRoute: 'order_list',
      data: [
        {
          name: 'HyperX Cloud Alpha',
          createdAt: '03.11.2020, 16:30'
        },
        {
          name: 'SteelSeries Arctis 1',
          createdAt: '20.10.2020, 20:12'
        },
        {
          name: 'Creative Sound Blaster Blaze',
          createdAt: '12.10.2020, 18:36'
        },
        {
          name: 'Logitech G PRO X',
          createdAt: '09.10.2020, 22:54'
        }
      ]
    }
  },
  Client1DashboardActivityLog: {
    component: 'DashboardActivityLog', // what
    props: {
      title: 'Activity Log',
      moreRoute: 'client_list',
      data: [
        {
          img: 'https://picsum.photos/id/3/40',
          name: 'Grace Kelly',
          updatedAt: '11.09.2020, 19:00'
        },
        {
          img: 'https://picsum.photos/id/10/40',
          name: 'Gary Cooper',
          updatedAt: '11.08.2020, 23:00'
        },
        {
          img: 'https://picsum.photos/id/45/40',
          name: 'Greta Garbo',
          updatedAt: '11.10.2020, 13:00'
        }
      ]
    }
  },
  Client1DashboardHitRate: {
    component: 'DashboardChart',
    props: {
      title: 'Hit Rate'
    }
  }
};

export default widgets;
