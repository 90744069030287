import { createDucks } from '@8powers/core/redux';

const { reducer, saga, actions } = createDucks('ims', {
  latestCosts: {
    endpoint: 'latestCosts',
    slices: ['list']
  },
  latestContracts: {
    endpoint: 'latestContracts',
    slices: ['list']
  },
  latestProtocols: {
    endpoint: 'latestProtocols',
    slices: ['list']
  },
  budgetPercent: {
    endpoint: 'budgetPercent',
    slices: ['list']
  },
  monthlyCosts: {
    endpoint: 'monthlyCosts',
    slices: ['list']
  }
});

export { saga, actions };
export default reducer;
