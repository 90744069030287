export default {
  messages: {
    investment: {
      create: 'Nowa inwestycja',
      update: 'Edytuj inwestycję',
      module: {
        name: 'Inwestycje'
      },
      entity: {
        id: 'Id',
        name: 'Nazwa',
        code: 'Kod',
        address: {
          label: 'Adres',
          city: 'Miasto',
          postCode: 'Kod pocztowy',
          street: 'Ulica',
          buildingNumber: 'Number budynku'
        },
        startDate: 'Data rozpoczęcia',
        documentsPath: 'Adres Google Drive',
        teamMembers: 'Członkowie zespołu',
        teamSuggest: 'Wyszukaj członka zespołu',
        targetCompany: 'Spółka celowa',
        projectManager: 'Manager projektu',
        budgets: 'Budżety',
        activeBudget: 'Aktywny budżet',
        offers: 'Oferty',
        state: 'Status',
        createdAt: 'Data dodania',
        updatedAt: 'Ostatnia modyfikacja',
        project: 'Nazwa projektu',
        plannedEndDate: 'Planowana data odbioru',
        logo: 'Logo'
      },
      grid: [],
      actions: {
        create: 'Dodaj',
        update: 'Edytuj',
        delete: 'Usuń'
      },
      form: {
        basic: 'Dane podstawowe',
        address: 'Adres',
        team: 'Członkowie zespołu',
        teamPlaceholder: 'Zacznij pisać...'
      }
    }
  }
};
