export const routes = [
  {
    name: 'company_list',
    path: '/company',
    componentConfig: [
      {
        component: 'EndpointListContainer',
        props: {
          endpoint: 'company',
          slice: 'company/company/list',
          selectAction: {
            preview: {
              meta: {
                modal: {
                  body: [
                    {
                      component: 'CompanyDetailContainer'
                    }
                  ]
                }
              }
            },
            edit: {
              type: 'company/company/edit/start',
              meta: {
                modal: {
                  title: 'company.actions.update',
                  body: [
                    {
                      component: 'CompanyEditForm'
                    }
                  ]
                }
              }
            }
          }
        },
        children: [
          {
            component: 'Grid',
            props: {
              title: 'company.module.name',
              icon: 'tasks',
              config: {
                columns: {
                  id: {
                    label: 'company.entity.id',
                    width: '90px'
                  },
                  name: {
                    label: 'company.entity.name',
                    width: '25%'
                  },
                  email: {
                    label: 'company.entity.email',
                    width: '25%'
                  },
                  typeLabel: {
                    label: 'company.entity.type'
                  },
                  stateLabel: {
                    label: 'company.entity.state'
                  },
                  updatedAt: {
                    label: 'company.entity.updatedAt',
                    display: {
                      type: 'relativeDate'
                    }
                  }
                },
                filtersConfig: [
                  {
                    label: 'company.filter.name',
                    property: 'name',
                    type: 'contains',
                    inputConfig: {
                      name: 'name',
                      type: 'text',
                      displayField: 'label'
                    }
                  },
                  {
                    label: 'company.filter.state',
                    property: 'state',
                    type: 'eq',
                    inputConfig: {
                      name: 'state',
                      type: 'endpointCombo',
                      endpoint: 'companyState',
                      slice: 'company/companyState/combo',
                      placeholder: '--',
                      displayField: 'label'
                    }
                  },
                  {
                    label: 'company.filter.type',
                    property: 'type',
                    type: 'eq',
                    inputConfig: {
                      name: 'type',
                      type: 'endpointCombo',
                      endpoint: 'companyType',
                      slice: 'company/companyType/combo',
                      placeholder: '--',
                      displayField: 'label'
                    },
                    defaultValue: 1
                  }
                ],
                actions: [
                  {
                    label: 'company.actions.create',
                    type: 'create',
                    icon: 'plus',
                    action: {
                      type: 'company/company/edit/start',
                      meta: {
                        modal: {
                          title: 'company.create',
                          body: [
                            {
                              component: 'CompanyCreateForm'
                            }
                          ]
                        }
                      }
                    }
                  }
                ],
                recordActions: [
                  {
                    label: '',
                    type: 'edit',
                    icon: 'pen',
                    action: {
                      type: 'company/company/edit/start',
                      meta: {
                        modal: {
                          title: 'company.update',
                          body: [
                            {
                              component: 'CompanyEditForm'
                            }
                          ]
                        }
                      }
                    }
                  },
                  {
                    label: '',
                    type: 'delete',
                    icon: 'trash',
                    action: {
                      type: 'company/company/list/delete',
                      meta: {
                        API: {
                          endpoint: 'company',
                          operation: 'delete'
                        },
                        confirm: {
                          props: {
                            title: 'ims.confirm.delete.title',
                            message: 'ims.confirm.delete.message',
                            confirmText: 'ims.confirm.delete.confirmText',
                            cancelText: 'ims.confirm.delete.cancelText'
                          },
                          confirmed: false
                        }
                      }
                    }
                  }
                ]
              }
            }
          }
        ]
      }
    ],
    exact: true,
    global: true,
    section: true,
    module: 'company',
    label: 'company.module.name',
    icon: 'tasks'
  }
];
