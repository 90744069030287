import BudgetTreeContainer from '../containers/BudgetTreeContainer';
import BudgetTree from '../components/Tree';
import LineDetail from '../components/LineDetail';

const widgets = {
  BudgetForm: {
    component: 'FormContainer',
    children: [
      {
        component: 'Form',
        props: {
          groups: [
            {
              label: '',
              columns: [
                [
                  {
                    name: 'name',
                    label: 'budget.entity.name',
                    type: 'text'
                  },
                  {
                    name: 'investment',
                    label: 'budget.entity.investment',
                    type: 'endpointCombo',
                    endpoint: 'investment',
                    slice: 'budget/investment/combo'
                  }
                ]
              ]
            },
            {
              label: '',
              columns: [
                [
                  {
                    name: 'startDate',
                    label: 'budget.entity.startDate',
                    type: 'date'
                  }
                ],
                [
                  {
                    name: 'endDate',
                    label: 'budget.entity.endDate',
                    type: 'date'
                  }
                ]
              ]
            },
            {
              label: '',
              columns: [
                [
                  {
                    name: 'approved',
                    label: 'budget.entity.approved',
                    type: 'checkbox'
                  }
                ]
              ]
            }
          ]
        }
      }
    ]
  },
  BudgetCloneForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'budget',
      slice: 'budget/budget/clone',
      operation: 'clone',
      fancyMask: true
    },
    children: [
      {
        component: 'FormContainer',
        props: {
          extractFromRecord: ['investment.id', 'name'],
          submitText: 'Kopiuj'
        },
        children: [
          {
            component: 'Form',
            props: {
              groups: [
                {
                  label: '',
                  columns: [
                    [
                      {
                        name: 'investment',
                        label: 'budget.entity.investment',
                        type: 'endpointCombo',
                        endpoint: 'investment',
                        slice: 'budget/investment/combo'
                      },
                      {
                        name: 'name',
                        label: 'budget.entity.name',
                        type: 'text'
                      }
                    ]
                  ]
                }
              ]
            }
          }
        ]
      }
    ]
  },
  BudgetEditForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'budget',
      slice: 'budget/budget/edit',
      operation: 'edit',
      fancyMask: true
    },
    children: [
      {
        component: 'BudgetForm'
      }
    ]
  },
  BudgetCreateForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'budget',
      slice: 'budget/budget/create',
      operation: 'create',
      fancyMask: true
    },
    children: [
      {
        component: 'BudgetForm'
      }
    ]
  },
  BudgetDetailContainer: {
    component: 'EndpointSelectedContainer',
    props: {
      endpoint: 'budget',
      slice: 'budget/budget/list'
    },
    children: [
      {
        component: 'EndpointDetail',
        props: {
          config: {
            groups: [
              {
                label: 'Dane podstawowe',
                columns: [
                  [
                    {
                      key: 'id',
                      label: 'budget.entity.id'
                    },
                    {
                      key: 'name',
                      label: 'budget.entity.name'
                    },
                    {
                      key: 'startDate',
                      label: 'budget.entity.startDate'
                    },
                    {
                      key: 'endDate',
                      label: 'budget.entity.endDate'
                    },
                    {
                      key: 'investment',
                      label: 'budget.entity.investment',
                      type: 'objectProps',
                      typeProps: {
                        props: ['name']
                      }
                    },
                    {
                      key: 'createdBy',
                      label: 'budget.entity.createdBy',
                      type: 'objectProps',
                      typeProps: {
                        props: ['firstName', 'lastName']
                      }
                    },
                    {
                      key: 'updatedBy',
                      label: 'budget.entity.updatedBy',
                      type: 'objectProps',
                      typeProps: {
                        props: ['firstName', 'lastName']
                      }
                    },
                    {
                      key: 'createdAt',
                      label: 'budget.entity.createdAt',
                      type: 'datetime'
                    },
                    {
                      key: 'updatedAt',
                      label: 'budget.entity.updatedAt',
                      type: 'datetime'
                    }
                  ]
                ]
              }
            ]
          }
        }
      }
    ]
  }
};

export default {
  ...widgets,
  BudgetTreeContainer,
  LineDetail,
  BudgetTree
};
