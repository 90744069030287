import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { endpointComboSelector } from '@8powers/ams/redux/selectors';
import { createFetchAction } from '@8powers/core/redux/slice/utils';
import CollectionField from '../components/CollectionField';

class CollectionFieldContainer extends React.Component {
  state = {
    items: [],
    relatedValues: {},
    summary: 0
  };

  static getDerivedStateFromProps(props, state) {
    if (
      state.items.length &&
      JSON.stringify(state.relatedValues) ===
        JSON.stringify(props.relatedValues || {})
    ) {
      return state;
    }

    const id = props.relatedValues
      ? typeof props.relatedValues.contract === 'object'
        ? props.relatedValues.contract.id
        : props.relatedValues.contract
      : null;
    const contract = props.contracts.find(item => item.id === id);
    let items = props.value || state.items || [];

    // Load items from contract only if no value was provided
    if (contract && !items.length) {
      items = contract.collection;
    }

    let summary = 0;
    items = items.map(budgetLine => {
      budgetLine.items = budgetLine.items.map(listItem => {
        summary += parseFloat(listItem.doneCost);
        return {
          ...listItem
        };
      });
      return {
        ...budgetLine
      };
    });

    return {
      items,
      relatedValues: props.relatedValues || {},
      summary
    };
  }

  componentDidMount() {
    const { fetchContracts } = this.props;
    fetchContracts && fetchContracts({ perPage: -1 });
  }

  componentDidUpdate(prevProps, prevState) {
    const { onChange } = this.props;
    if (prevProps.relatedValues.final !== this.props.relatedValues.final) {
      const { items: budgetLines } = prevState;

      let summary = 0;
      const updatedBudgetLines = budgetLines.map(budgetLine => {
        const updatedItems = budgetLine.items.map(listItem => {
          let percent = 100 - listItem.totalPercent;
          const oldPercent = listItem.percent;

          if (!this.props.relatedValues.final) {
            percent =
              typeof listItem.oldPercent === 'undefined'
                ? oldPercent
                : listItem.oldPercent;
          }

          const doneCost =
            parseFloat(listItem.contractTotal, 10) *
            (parseFloat(percent, 10) / 100);
          summary += parseFloat(doneCost);
          return {
            ...listItem,
            percent,
            oldPercent,
            doneCost
          };
        });

        return {
          ...budgetLine,
          items: [...updatedItems]
        };
      });
      /* eslint-disable */
      this.setState(
        {
          items: [...updatedBudgetLines],
          summary
        },
        () => onChange && onChange(this.getData())
      );
      /* eslint-enable */
    }
  }

  getData() {
    const { name } = this.props;
    const { items } = this.state;

    return {
      target: {
        name,
        value: [...items]
      }
    };
  }

  handleListItemChange = (e, budgetLineId, listItemId) => {
    const { onChange } = this.props;
    const { target } = e;
    const { name } = target;
    let { value } = target;
    const { items: budgetLines } = this.state;
    let summary = 0;
    if (value.indexOf(',') !== -1) {
      value = value.replace(',', '.');
    }

    const updatedBudgetLines = budgetLines.map(budgetLine => {
      if (budgetLine.budgetLine.id === budgetLineId) {
        const updatedItems = budgetLine.items.map(listItem => {
          if (listItem.contract_item === listItemId) {
            let doneCost = listItem.doneCost;
            let percent = listItem.percent;
            if (name === 'percent') {
              doneCost =
                Math.round(
                  parseFloat(listItem.contractTotal) *
                    (parseFloat(value) / 100) *
                    100
                ) / 100;
            } else {
              percent =
                Math.round(
                  (parseFloat(value) / parseFloat(listItem.contractTotal)) *
                    100 *
                    10000
                ) / 10000;
            }
            summary += parseFloat(doneCost);
            return {
              ...listItem,
              doneCost,
              percent,
              [name]: value
            };
          }
          summary += parseFloat(listItem.doneCost);

          return listItem;
        });

        return {
          ...budgetLine,
          items: [...updatedItems]
        };
      }
      budgetLine.items.map(listItem => {
        summary += listItem.doneCost;
        return listItem;
      });

      return budgetLine;
    });

    this.setState(
      {
        items: [...updatedBudgetLines],
        summary
      },
      () => onChange && onChange(this.getData())
    );
  };

  render() {
    const { items, summary } = this.state;
    const { preview, relatedValues } = this.props;

    return (
      <CollectionField
        {...this.props}
        summary={summary}
        items={items}
        preview={preview}
        onListItemChange={this.handleListItemChange}
        readOnly={relatedValues.final}
      />
    );
  }
}

const mapStateToProps = state => {
  const contracts = endpointComboSelector('contract', 'contract/contract/list');
  return {
    contracts: [...contracts(state)]
  };
};

const mapDispatchToProps = dispatch => {
  const fetchContracts = createFetchAction({
    endpoint: 'contract',
    slice: 'contract/contract/list',
    blockUI: false
  });

  return {
    ...bindActionCreators({ fetchContracts }, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollectionFieldContainer);
