import React, { Component } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const grid = '8px 0';
const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'transparent',
  padding: grid
});
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'transparent',

  // styles we need to apply on draggables
  ...draggableStyle
});

class NodeWrap extends Component {
  state = {
    isCollapsed: false
  };

  getStateAndHelpers = () => {
    return {
      onCollapseClick: this.handleCollapseClick
    };
  };

  handleCollapseClick = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(prevState => ({
      isCollapsed: !prevState.isCollapsed
    }));
  };

  render() {
    const { isCollapsed } = this.state;
    const { MainComponent, componentProps } = this.props;
    let { children } = this.props;
    if (isCollapsed) {
      children = [];
    }
    if (!children) {
      children = [];
    }
    return (
      <React.Fragment>
        <MainComponent
          {...componentProps}
          onCollapseClick={this.handleCollapseClick}
          isCollapsed={isCollapsed}
        />
        <DragDropContext onDragEnd={componentProps.onDragEnd}>
          <Droppable droppableId={`${componentProps.key}`}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {children.map((item, index) => (
                  <Draggable
                    key={item.key}
                    draggableId={item.key}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {item}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </React.Fragment>
    );
  }
}

export default NodeWrap;
