import React from 'react';
import { FormRow } from '@8powers/ams/components/UI/Form';
import { Wrapper, Label } from '@8powers/ams/components/Form/Field/styles';
import { Value } from './styles';

const ConditionalField = ({ label, value, displayField }) => {
  if (value && value[displayField]) {
    return (
      <FormRow>
        <Wrapper>
          <Label>{label}</Label>
          <Value>{value[displayField]}</Value>
        </Wrapper>
      </FormRow>
    );
  }

  return null;
};

export default ConditionalField;
