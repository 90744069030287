export const routes = [
  {
    name: 'protocol_list',
    path: '/protocol',
    componentConfig: [
      {
        component: 'EndpointListContainer',
        props: {
          endpoint: 'protocol',
          slice: 'protocol/protocol/list',
          selectAction: {
            preview: {
              meta: {
                modal: {
                  body: [
                    {
                      component: 'ProtocolDetailContainer'
                    }
                  ]
                }
              }
            },
            edit: {
              type: 'protocol/protocol/edit/start',
              meta: {
                modal: {
                  title: 'protocol.actions.update',
                  body: [
                    {
                      component: 'ProtocolEditForm'
                    }
                  ]
                }
              }
            }
          }
        },
        children: [
          {
            component: 'Grid',
            props: {
              title: 'protocol.module.name',
              config: {
                columns: {
                  'contract.company': {
                    sortable: true,
                    label: 'protocol.entity.company',
                    display: {
                      type: 'function',
                      renderer: ({ record }) => {
                        if (!record.contract.company) {
                          return '-';
                        }
                        return record.contract.company.name;
                      }
                    }
                  },
                  contract: {
                    sortable: true,
                    label: 'protocol.entity.contract',
                    display: {
                      type: 'objectProps',
                      props: ['number']
                    }
                  },
                  number: {
                    sortable: true,
                    label: 'protocol.entity.number'
                  },
                  date: {
                    sortable: true,
                    label: 'protocol.entity.date',
                    display: {
                      type: 'date'
                    }
                  },
                  total: {
                    sortable: true,
                    label: 'protocol.entity.total',
                    display: {
                      type: 'function',
                      renderer: ({ record }) => {
                        let cost = 0;
                        record.items.map(item => (cost += item.cost));

                        return Math.round(cost);
                      }
                    }
                  },
                  updatedAt: {
                    sortable: true,
                    label: 'protocol.entity.updatedAt',
                    display: {
                      type: 'relativeDate'
                    }
                  }
                },
                filtersConfig: [
                  {
                    label: 'protocol.entity.company',
                    property: 'contract.company',
                    type: 'eq',
                    inputConfig: {
                      type: 'wideEndpointCombo',
                      endpoint: 'company',
                      slice: 'protocol/company/combo',
                      displayField: 'name'
                    }
                  }
                ],
                actions: [],
                recordActions: [
                  {
                    label: 'protocol.recordActions.edit',
                    type: 'edit',
                    icon: 'pen',
                    visibleOnHover: true,
                    hideLabel: true,
                    action: {
                      type: 'protocol/protocol/edit/start',
                      meta: {
                        modal: {
                          title: 'protocol.update',
                          body: [
                            {
                              component: 'ProtocolEditForm'
                            }
                          ]
                        }
                      }
                    }
                  },
                  {
                    label: 'protocol.recordActions.addCost',
                    type: 'addCost',
                    icon: 'plus',
                    hidden: true,
                    passItemProps: ['id'],
                    action: {
                      type: 'protocol/cost/create',
                      sideEffects: {
                        'protocol/cost/create_success': [
                          {
                            type: 'cost/cost/create/start',
                            _convertPayload: 'extractAPI',
                            meta: {
                              modal: {
                                title: 'cost.actions.create',
                                body: [
                                  {
                                    component: 'CostCreateForm'
                                  }
                                ]
                              }
                            }
                          }
                        ]
                      },
                      meta: {
                        API: {
                          endpoint: 'protocol',
                          operation: 'addCost'
                        }
                      }
                    }
                  },
                  {
                    label: 'protocol.recordActions.pdf',
                    type: 'pdf',
                    icon: 'pdf',
                    hidden: true,
                    passItemProps: ['id'],
                    action(data) {
                      let name = `protocol-${data.id}`;
                      // I do not see how to do it "prettier"
                      fetch(`/api/pdf/protocol/${data.id}`, {
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem(
                            'token'
                          )}`
                        }
                      })
                        .then(response => {
                          // Extract file name from header
                          response.headers.forEach(header => {
                            if (header.indexOf('attachment;') > -1) {
                              const parts = header.split('=');
                              name = parts[1].replaceAll('"', '');
                            }
                          });
                          return response.blob();
                        })
                        .then(blob => {
                          if (!(window.URL && window.URL.createObjectURL)) {
                            /* eslint-disable */
                            alert('Update your browser!!!');
                            /* eslint-enable */
                            return;
                          }
                          const blobURL = window.URL.createObjectURL(blob);
                          const tmpLink = document.createElement('a');
                          tmpLink.style.display = 'none';
                          tmpLink.href = blobURL;
                          tmpLink.setAttribute('target', '_blank');
                          tmpLink.setAttribute('download', `${name}`);
                          tmpLink.click();
                        });
                    }
                  },
                  {
                    label: 'protocol.recordActions.delete',
                    type: 'delete',
                    icon: 'trash',
                    visibilityProperty: 'canDelete',
                    visibilityValue: false,
                    action: {
                      type: 'protocol/protocol/list/delete',
                      meta: {
                        API: {
                          endpoint: 'protocol',
                          operation: 'delete'
                        },
                        confirm: {
                          props: {
                            title: 'ims.confirm.delete.title',
                            message: 'ims.confirm.delete.message',
                            confirmText: 'ims.confirm.delete.confirmText',
                            cancelText: 'ims.confirm.delete.cancelText'
                          },
                          confirmed: false
                        }
                      }
                    }
                  }
                ]
              }
            }
          }
        ]
      }
    ],
    exact: true,
    section: true,
    module: 'protocol',
    label: 'protocol.module.name',
    icon: 'page'
  }
];
