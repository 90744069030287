const widgets = {
  TeamForm: {
    component: 'FormContainer',
    children: [
      {
        component: 'Form',
        props: {
          fields: [
            {
              name: 'investment',
              label: 'team.entity.investment',
              type: 'endpointCombo',
              endpoint: 'investment',
              slice: 'investment/investment/combo'
            },
            {
              name: 'profile',
              label: 'team.entity.profile',
              type: 'endpointCombo',
              endpoint: 'profile',
              slice: 'team/profile/combo',
              displayField: 'lastName'
            },
            {
              name: 'position',
              label: 'team.entity.position',
              type: 'text'
            }
          ]
        }
      }
    ]
  },
  TeamEditForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'team',
      slice: 'team/team/edit',
      operation: 'edit',
      fancyMask: true
    },
    children: [
      {
        component: 'TeamForm'
      }
    ]
  },
  TeamCreateForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'team',
      slice: 'team/team/create',
      operation: 'create',
      fancyMask: true
    },
    children: [
      {
        component: 'TeamForm'
      }
    ]
  },
  TeamDetailContainer: {
    component: 'EndpointSelectedContainer',
    props: {
      endpoint: 'team',
      slice: 'team/team/list'
    },
    children: [
      {
        component: 'EndpointDetail',
        props: {
          config: {
            groups: [
              {
                label: 'Dane podstawowe',
                columns: [
                  [
                    {
                      key: 'id',
                      label: 'team.entity.id'
                    },
                    {
                      key: 'investment',
                      label: 'team.entity.investment',
                      type: 'objectProps',
                      typeProps: {
                        props: ['name']
                      }
                    },
                    {
                      key: 'profile',
                      label: 'team.entity.profile',
                      type: 'objectProps',
                      typeProps: {
                        props: ['firstName', 'lastName']
                      }
                    },
                    {
                      key: 'position',
                      label: 'team.entity.position'
                    },
                    {
                      key: 'createdAt',
                      label: 'team.entity.createdAt'
                    },
                    {
                      key: 'updatedAt',
                      label: 'team.entity.updatedAt'
                    }
                  ]
                ]
              }
            ]
          }
        }
      }
    ]
  }
};

export default widgets;
