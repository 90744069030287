const widgets = {
  ContractForm: {
    component: 'FormContainer',
    children: [
      {
        component: 'Form',
        props: {
          groups: [
            {
              label: '',
              columns: [
                [
                  {
                    name: 'offer',
                    label: 'contract.entity.offerConditional',
                    type: 'conditionalField',
                    displayField: 'id',
                    rawControl: true
                  },
                  {
                    name: 'number',
                    label: 'contract.entity.number',
                    type: 'text'
                  },
                  {
                    name: 'company',
                    label: 'contract.entity.company',
                    type: 'endpointCombo',
                    endpoint: 'company',
                    slice: 'contract/company/combo'
                  },
                  {
                    name: 'signDate',
                    label: 'contract.entity.signDate',
                    type: 'date'
                  },
                  {
                    name: 'buildStartDate',
                    label: 'contract.entity.buildStartDate',
                    type: 'date'
                  },
                  {
                    name: 'endDate',
                    label: 'contract.entity.endDate',
                    type: 'date'
                  },
                  {
                    name: 'description',
                    label: 'contract.entity.description',
                    type: 'textarea'
                  }
                ]
              ]
            },
            {
              label: '',
              columns: [
                [
                  {
                    name: 'guarantee',
                    label: 'contract.entity.guarantee',
                    type: 'text'
                  }
                ],
                [
                  {
                    name: 'daysForPayment',
                    label: 'contract.entity.daysForPayment',
                    type: 'text'
                  }
                ]
              ]
            },
            {
              label: '',
              columns: [
                [
                  {
                    name: 'guaranteeMonths',
                    label: 'contract.entity.guaranteeMonths',
                    type: 'number'
                  },
                  {
                    name: 'mediaCosts',
                    label: 'contract.entity.mediaCosts',
                    type: 'number'
                  },
                  {
                    name: 'wasteCosts',
                    label: 'contract.entity.wasteCosts',
                    type: 'number'
                  }
                ],
                [
                  {
                    name: 'securityRelease',
                    label: 'contract.entity.securityRelease',
                    type: 'number'
                  },
                  {
                    name: 'backendCosts',
                    label: 'contract.entity.backendCosts',
                    type: 'number'
                  }
                ]
              ]
            },
            {
              label: '',
              columns: [
                [
                  {
                    name: 'otherDates',
                    label: 'contract.entity.otherDates',
                    type: 'richText'
                  },
                  {
                    name: 'additionalConditions',
                    label: 'contract.entity.additionalConditions',
                    type: 'richText',
                    value:
                      '<ol><li>Termin wykonania robót:<ol>' +
                      '        <li>zgodnie z terminem umownym** </li>' +
                      '        <li>niezgodnie z terminem umownym** </li>' +
                      '        <li>opóźnienie wynosi..................................dni**</li></ol></li>' +
                      '    <li>Rozliczenie materiałów powierzonych:<ol>' +
                      '        <li>Podwykonawcy nie zostały powierzone do wbudowania żadne materiały. ** </li>' +
                      '        <li>Podwykonawcy zostały powierzone do wbudowania materiały zgodnie z protokołem przekazania z dnia ............, który stanowi załącznik nr ................ Do nieniejszego protokołu. ** </li>' +
                      '        <li>Podwykonawca przedłożył rozliczenie materiałów powierzonych zgodnie z załącznikiem nr .............. do nieniejszego protokołu. Wykonawca przyjmuje przedłożone rozliczenia bez uwag / z uwagami. **</li></ol></li>'
                  },
                  {
                    name: 'type',
                    label: 'contract.entity.type',
                    type: 'endpointCombo',
                    endpoint: 'contractType',
                    slice: 'contract/type/combo',
                    displayField: 'label',
                    valueField: 'value'
                  },
                  {
                    name: 'state',
                    label: 'contract.entity.state',
                    type: 'endpointCombo',
                    endpoint: 'contractState',
                    slice: 'contract/state/combo',
                    displayField: 'label'
                  },
                  {
                    label: null,
                    name: 'collection',
                    type: 'collection',
                    rawControl: true
                  }
                ]
              ]
            }
          ]
        }
      }
    ]
  },
  ContractEditForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'contract',
      slice: 'contract/contract/edit',
      operation: 'edit',
      fancyMask: true
    },
    children: [
      {
        component: 'ContractForm'
      }
    ]
  },
  ContractCreateForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'contract',
      slice: 'contract/contract/create',
      operation: 'create',
      fancyMask: true,
      extractFromRecord: [
        'offer',
        'number',
        'company',
        'signDate',
        'description',
        'guarantee',
        'daysForPayment',
        'type',
        'collection'
      ]
    },
    children: [
      {
        component: 'FormContainer',
        children: [
          {
            component: 'Form',
            props: {
              groups: [
                {
                  label: '',
                  columns: [
                    [
                      {
                        name: 'offer',
                        label: 'contract.entity.offerConditional',
                        type: 'conditionalField',
                        displayField: 'label',
                        rawControl: true
                      },
                      {
                        name: 'number',
                        label: 'contract.entity.number',
                        type: 'text'
                      },
                      {
                        name: 'company',
                        label: 'contract.entity.company',
                        type: 'endpointCombo',
                        endpoint: 'company',
                        slice: 'contract/company/combo'
                      },
                      {
                        name: 'signDate',
                        label: 'contract.entity.signDate',
                        type: 'date'
                      },
                      {
                        name: 'buildStartDate',
                        label: 'contract.entity.buildStartDate',
                        type: 'date'
                      },
                      {
                        name: 'endDate',
                        label: 'contract.entity.endDate',
                        type: 'date'
                      },
                      {
                        name: 'description',
                        label: 'contract.entity.description',
                        type: 'text'
                      }
                    ]
                  ]
                },
                {
                  label: '',
                  columns: [
                    [
                      {
                        name: 'guarantee',
                        label: 'contract.entity.guarantee',
                        type: 'text'
                      }
                    ],
                    [
                      {
                        name: 'daysForPayment',
                        label: 'contract.entity.daysForPayment',
                        type: 'text'
                      }
                    ]
                  ]
                },
                {
                  label: '',
                  columns: [
                    [
                      {
                        name: 'guaranteeMonths',
                        label: 'contract.entity.guaranteeMonths',
                        type: 'number'
                      },
                      {
                        name: 'mediaCosts',
                        label: 'contract.entity.mediaCosts',
                        type: 'number'
                      },
                      {
                        name: 'wasteCosts',
                        label: 'contract.entity.wasteCosts',
                        type: 'number'
                      }
                    ],
                    [
                      {
                        name: 'securityRelease',
                        label: 'contract.entity.securityRelease',
                        type: 'number'
                      },
                      {
                        name: 'backendCosts',
                        label: 'contract.entity.backendCosts',
                        type: 'number'
                      }
                    ]
                  ]
                },
                {
                  label: '',
                  columns: [
                    [
                      {
                        name: 'otherDates',
                        label: 'contract.entity.otherDates',
                        type: 'richText'
                      },
                      {
                        name: 'additionalConditions',
                        label: 'contract.entity.additionalConditions',
                        type: 'richText',
                        value:
                          '<ol><li>Termin wykonania robót:<ol type="a">' +
                          '        <li>zgodnie z terminem umownym** </li>' +
                          '        <li>niezgodnie z terminem umownym** </li>' +
                          '        <li>opóźnienie wynosi..................................dni**</li></ol></li>' +
                          '    <li>Rozliczenie materiałów powierzonych:<ol type="a">' +
                          '        <li>Podwykonawcy nie zostały powierzone do wbudowania żadne materiały. ** </li>' +
                          '        <li>Podwykonawcy zostały powierzone do wbudowania materiały zgodnie z protokołem przekazania z dnia ............, który stanowi załącznik nr ................ Do nieniejszego protokołu. ** </li>' +
                          '        <li>Podwykonawca przedłożył rozliczenie materiałów powierzonych zgodnie z załącznikiem nr .............. do nieniejszego protokołu. Wykonawca przyjmuje przedłożone rozliczenia bez uwag / z uwagami. **</li></ol></li>'
                      },
                      {
                        name: 'type',
                        label: 'contract.entity.type',
                        type: 'endpointCombo',
                        endpoint: 'contractType',
                        slice: 'contract/type/combo',
                        displayField: 'label',
                        valueField: 'value'
                      },
                      {
                        label: 'contract.entity.items',
                        name: 'collection',
                        type: 'offerCollection',
                        rawControl: true
                      }
                    ]
                  ]
                }
              ]
            }
          }
        ]
      }
    ]
  },
  ContractDetailContainer: {
    component: 'EndpointSelectedContainer',
    props: {
      endpoint: 'contract',
      slice: 'contract/contract/list'
    },
    children: [
      {
        component: 'EndpointDetail',
        props: {
          config: {
            groups: [
              {
                label: '',
                columns: [
                  [
                    {
                      key: 'id',
                      label: 'contract.entity.id'
                    }
                  ],
                  [
                    {
                      key: 'offer',
                      label: 'contract.entity.offer',
                      type: 'objectProps',
                      typeProps: {
                        props: ['description']
                      }
                    }
                  ]
                ]
              },
              {
                columns: [
                  [
                    {
                      key: 'company',
                      label: 'contract.entity.company',
                      type: 'objectProps',
                      typeProps: {
                        props: ['name']
                      }
                    }
                  ],
                  [
                    {
                      key: 'signDate',
                      label: 'contract.entity.signDate',
                      type: 'date'
                    }
                  ]
                ]
              },
              {
                columns: [
                  [
                    {
                      key: 'buildStartDate',
                      label: 'contract.entity.buildStartDate',
                      type: 'date'
                    }
                  ],
                  [
                    {
                      key: 'endDate',
                      label: 'contract.entity.endDate',
                      type: 'date'
                    }
                  ]
                ]
              },
              {
                columns: [
                  [
                    {
                      key: 'description',
                      label: 'contract.entity.description'
                    }
                  ]
                ]
              },
              {
                columns: [
                  [
                    {
                      key: 'guarantee',
                      label: 'contract.entity.guarantee'
                    }
                  ],
                  [
                    {
                      key: 'daysForPayment',
                      label: 'contract.entity.daysForPayment'
                    }
                  ]
                ]
              },
              {
                columns: [
                  [
                    {
                      key: 'typeLabel',
                      label: 'contract.entity.type'
                    }
                  ],
                  [
                    {
                      key: 'state',
                      label: 'contract.entity.state',
                      type: 'objectProps',
                      typeProps: {
                        props: ['label']
                      }
                    }
                  ]
                ]
              },
              {
                columns: [
                  [
                    {
                      key: 'createdBy',
                      label: 'contract.entity.createdBy',
                      type: 'objectProps',
                      typeProps: {
                        props: ['firstName', 'lastName']
                      }
                    }
                  ],
                  [
                    {
                      key: 'updatedBy',
                      label: 'contract.entity.updatedBy',
                      type: 'objectProps',
                      typeProps: {
                        props: ['firstName', 'lastName']
                      }
                    }
                  ]
                ]
              },
              {
                columns: [
                  [
                    {
                      key: 'protocols',
                      label: 'contract.entity.protocols',
                      type: 'objectProps',
                      typeProps: {
                        props: ['date']
                      },
                      onClick: {
                        type: 'protocol/protocol/detail',
                        meta: {
                          modal: {
                            stack: true,
                            body: [
                              {
                                component: 'ProtocolDetailEndpointContainer'
                              }
                            ]
                          }
                        }
                      }
                    }
                  ]
                ]
              },
              {
                columns: [
                  [
                    {
                      key: 'collection',
                      label: 'cost.entity.items',
                      type: 'itemsList',
                      collection: true
                    }
                  ]
                ]
              }
            ]
          }
        }
      }
    ]
  }
};

export default widgets;
