export default {
  messages: {
    costItem: {
      create: 'Nowa pozycja kosztu',
      update: 'Edytuj pozycję kosztu',
      module: {
        name: 'Pozycje kosztu'
      },
      entity: {
        id: 'Id',
        cost: 'Koszt',
        budgetLine: 'Linia budżetowa',
        description: 'Opis',
        amount: 'Kwota',
        contractItem: 'Pozycja umowy',
        createdBy: 'Dodany przez',
        updatedBy: 'Modyfikowany przez',
        createdAt: 'Data dodania',
        updatedAt: 'Ostatnia modyfikacja'
      },
      grid: [],
      actions: {
        create: 'Dodaj',
        update: 'Edytuj',
        delete: 'Usuń'
      }
    }
  }
};
