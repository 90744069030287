import React from 'react';
import FieldComponent from '@8powers/ams/components/Form/Field/Component';
import { Label } from '@8powers/ams/components/Form/Field/styles';
import { Select } from '@8powers/ams/components/UI/Form';
import { Icon } from '@8powers/ams/components/UI';
import { withTranslation } from 'react-i18next';
import ListItem from './ListItem';
import {
  Wrapper,
  Row,
  SelectWrap,
  List,
  BtnWrap,
  NewListItemButton,
  Actions,
  ActionButton,
  Num
} from './styles';

class CollectionFieldItem extends React.Component {
  createBudgetLinesSelect() {
    const { budgetLines, item, onChange, collectionRow } = this.props;

    const options = budgetLines.map(i => (
      <option
        key={i.id}
        value={i.id}
        selected={item.budgetLine && item.budgetLine.id === i.value}
      >
        {i.listOrder} {i.name}
      </option>
    ));

    return (
      <Select
        name="budgetLine"
        tabIndex={1}
        value={item.budgetLine ? item.budgetLine.id : null}
        onChange={e => onChange(e, collectionRow)}
      >
        {options}
      </Select>
    );
  }

  render() {
    const {
      unitComboData,
      onRemove,
      item,
      index,
      onListItemAdd,
      onListItemChange,
      onListItemRemove,
      collectionRow,
      t
    } = this.props;

    return (
      <Wrapper>
        <Row>
          <Num>{index}</Num>
          <SelectWrap>
            <FieldComponent>
              <Label>Linia budżetowa</Label>
              {this.createBudgetLinesSelect()}
            </FieldComponent>
          </SelectWrap>
          <Actions>
            <ActionButton type="button" onClick={() => onRemove(index - 1)}>
              <Icon name="trash" />
            </ActionButton>
          </Actions>
        </Row>
        <List>
          {item.items.map((listItem, index) => (
            <ListItem
              key={listItem.id}
              index={index + 1}
              budgetLine={collectionRow}
              item={listItem}
              unitComboData={unitComboData}
              onInputChange={onListItemChange}
              onDeleteClick={onListItemRemove}
            />
          ))}
        </List>
        <BtnWrap>
          <NewListItemButton
            type="button"
            color="secondary"
            onClick={() => onListItemAdd(item.id)}
          >
            {t('ims.collectionField.addListItem')}
          </NewListItemButton>
        </BtnWrap>
      </Wrapper>
    );
  }
}

export default withTranslation()(CollectionFieldItem);
