import { createDucks } from '@8powers/core/redux';

const { reducer, saga, actions } = createDucks('offer', {
  offer: {
    endpoint: 'offer',
    slices: ['list', 'detail', 'create', 'edit']
  },
  company: {
    endpoint: 'company',
    slices: ['combo']
  },
  offerItem: {
    endpoint: 'offerItem',
    slices: ['combo']
  },
  type: {
    endpoint: 'offerType',
    slices: ['combo']
  },
  contract: {
    endpoint: 'contract',
    slices: ['create']
  }
});

export { saga, actions };
export default reducer;
