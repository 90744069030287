export const routes = [
  {
    name: 'budget_list',
    path: '/budget',
    componentConfig: [
      {
        component: 'EndpointListContainer',
        props: {
          endpoint: 'budget',
          slice: 'budget/budget/list',
          selectAction: {
            preview: {
              type: 'REDIRECT',
              route: 'budget_edit'
            },
            edit: {
              type: 'budget/budget/edit/start',
              meta: {
                modal: {
                  title: 'budget.actions.update',
                  body: [
                    {
                      component: 'BudgetEditForm'
                    }
                  ]
                }
              }
            }
          }
        },
        children: [
          {
            component: 'Grid',
            props: {
              title: 'budget.module.name',
              icon: 'dollar',
              config: {
                columns: {
                  name: {
                    label: 'budget.entity.name',
                    display: {
                      type: 'budgetName'
                    }
                  },
                  startDate: {
                    label: 'budget.entity.startDate',
                    display: {
                      type: 'date'
                    }
                  },
                  endDate: {
                    label: 'budget.entity.endDate',
                    display: {
                      type: 'date'
                    }
                  },
                  investment: {
                    label: 'budget.entity.investment',
                    display: {
                      type: 'objectProps',
                      props: ['name']
                    }
                  },
                  updatedAt: {
                    label: 'budget.entity.updatedAt',
                    display: {
                      type: 'datetime'
                    }
                  }
                },
                filtersConfig: [
                  {
                    label: 'budget.filter.investment',
                    property: 'investment',
                    type: 'eq',
                    inputConfig: {
                      name: 'investment',
                      type: 'endpointCombo',
                      endpoint: 'investment',
                      slice: 'budget/investment/combo',
                      placeholder: '--',
                      displayField: 'name'
                    }
                  }
                ],
                actions: [
                  {
                    label: 'budget.actions.create',
                    type: 'create',
                    icon: 'plus',
                    action: {
                      type: 'budget/budget/edit/start',
                      meta: {
                        modal: {
                          title: 'budget.create',
                          body: [
                            {
                              component: 'BudgetCreateForm'
                            }
                          ]
                        }
                      }
                    }
                  }
                ],
                recordActions: [
                  {
                    label: 'budget.rowActions.settings',
                    type: 'settings',
                    icon: 'cog',
                    hideLabel: true,
                    visibilityProperty: 'approved',
                    visibilityValue: true,
                    action: {
                      type: 'budget/budget/edit/start',
                      meta: {
                        modal: {
                          title: 'budget.update',
                          body: [
                            {
                              component: 'BudgetEditForm'
                            }
                          ]
                        }
                      }
                    }
                  },
                  {
                    label: 'budget.rowActions.delete',
                    type: 'delete',
                    icon: 'trash',
                    hideLabel: true,
                    visibilityProperty: 'canDelete',
                    visibilityValue: false,
                    action: {
                      type: 'budget/budget/list/delete',
                      meta: {
                        API: {
                          endpoint: 'budget',
                          operation: 'delete'
                        },
                        confirm: {
                          props: {
                            title: 'ims.confirm.delete.title',
                            message: 'ims.confirm.delete.message',
                            confirmText: 'ims.confirm.delete.confirmText',
                            cancelText: 'ims.confirm.delete.cancelText'
                          },
                          confirmed: false
                        }
                      }
                    }
                  }
                ]
              }
            }
          }
        ]
      }
    ],
    exact: true,
    section: true,
    module: 'budget',
    label: 'budget.module.name',
    icon: 'dollar',
    routes: [
      {
        name: 'budget_edit',
        path: '/budget/:id',
        componentConfig: [
          {
            component: 'BudgetTreeContainer',
            props: {
              endpoint: 'budgetLine',
              slice: 'budgetLine/budgetLine/list'
            },

            children: [
              {
                component: 'BudgetTree',
                props: {
                  title: 'budget.list.title.preview',
                  icon: 'dollar',
                  config: {
                    actions: [
                      {
                        label: 'Save',
                        type: 'update'
                      }
                    ],
                    recordActions: []
                  }
                }
              }
            ]
          }
        ]
      }
    ]
  }
];
