const widgets = {
  ContractItemForm: {
    component: 'FormContainer',
    children: [
      {
        component: 'Form',
        props: {
          fields: [
            {
              name: 'contract',
              label: 'contractItem.entity.contract',
              type: 'endpointCombo',
              endpoint: 'contract',
              slice: 'contractItem/contract/combo',
              displayField: 'id'
            },
            {
              name: 'budgetLine',
              label: 'contractItem.entity.budgetLine',
              type: 'endpointCombo',
              endpoint: 'budgetLine',
              slice: 'contractItem/budgetLine/combo',
              displayField: 'name'
            },
            {
              name: 'description',
              label: 'contractItem.entity.description',
              type: 'text'
            },
            {
              name: 'count',
              label: 'contractItem.entity.count',
              type: 'text'
            },
            {
              name: 'unit',
              label: 'contractItem.entity.unit',
              type: 'endpointCombo',
              endpoint: 'budgetLineUnit',
              slice: 'contractItem/budgetLineUnit/combo',
              displayField: 'label',
              valueField: 'value'
            },
            {
              name: 'unitPrice',
              label: 'contractItem.entity.unitPrice',
              type: 'text'
            },
            {
              name: 'cost',
              label: 'contractItem.entity.cost',
              type: 'text'
            }
          ]
        }
      }
    ]
  },
  ContractItemEditForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'contractItem',
      slice: 'contractItem/contractItem/edit',
      operation: 'edit',
      fancyMask: true
    },
    children: [
      {
        component: 'ContractItemForm'
      }
    ]
  },
  ContractItemCreateForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'contractItem',
      slice: 'contractItem/contractItem/create',
      operation: 'create',
      fancyMask: true
    },
    children: [
      {
        component: 'ContractItemForm'
      }
    ]
  },
  ContractItemDetailComponent: {
    component: 'EndpointDetail',
    props: {
      config: {
        groups: [
          {
            label: 'Dane podstawowe',
            columns: [
              [
                {
                  key: 'id',
                  label: 'contractItem.entity.id'
                },
                {
                  key: 'contract',
                  label: 'contractItem.entity.contract',
                  type: 'objectProps',
                  typeProps: {
                    props: ['description']
                  }
                },
                {
                  key: 'budgetLine',
                  label: 'contractItem.entity.budgetLine',
                  type: 'objectProps',
                  typeProps: {
                    props: ['name']
                  }
                },
                {
                  key: 'description',
                  label: 'contractItem.entity.description'
                },
                {
                  key: 'cost',
                  label: 'contractItem.entity.cost'
                },
                {
                  key: 'protocolItems',
                  label: 'contractItem.entity.protocolItems',
                  type: 'objectProps',
                  typeProps: {
                    props: ['description', 'cost']
                  }
                },
                {
                  key: 'costItems',
                  label: 'contractItem.entity.costItems'
                },
                {
                  key: 'createdBy',
                  label: 'contractItem.entity.createdBy',
                  type: 'objectProps',
                  typeProps: {
                    props: ['firstName', 'lastName']
                  }
                },
                {
                  key: 'updatedBy',
                  label: 'contractItem.entity.updatedBy',
                  type: 'objectProps',
                  typeProps: {
                    props: ['firstName', 'lastName']
                  }
                },
                {
                  key: 'createdAt',
                  label: 'contractItem.entity.createdAt',
                  type: 'datetime'
                },
                {
                  key: 'updatedAt',
                  label: 'contractItem.entity.updatedAt',
                  type: 'datetime'
                }
              ]
            ]
          }
        ]
      }
    }
  },
  ContractItemDetailContainer: {
    component: 'EndpointSelectedContainer',
    props: {
      endpoint: 'contractItem',
      slice: 'contractItem/contractItem/list'
    },
    children: [
      {
        component: 'ContractItemDetailComponent'
      }
    ]
  }
};

export default widgets;
