export default {
  messages: {
    contract: {
      create: 'Nowy umowa',
      update: 'Edytuj umowę',
      module: {
        name: 'Umowy'
      },
      filter: {
        company: 'Firma'
      },
      entity: {
        id: 'Id',
        offer: 'Oferta',
        number: 'Numer umowy',
        offerConditional: 'Na podstawie oferty',
        company: 'Firma',
        signDate: 'Data podpisania',
        endDate: 'Data zakończenia',
        description: 'Przedmiot umowy',
        guarantee: 'Zatrzymanie gwarancyjne (%)',
        daysForPayment: 'Liczba dni zapłaty',
        type: 'Typ',
        items: 'Linia budżetowa',
        protocols: 'Protokoły',
        createdBy: 'Dodana przez',
        updatedBy: 'Edytowana przez',
        state: 'Status',
        createdAt: 'Data dodania',
        updatedAt: 'Zmienione',
        accountNumber: 'Numer rachunku',
        guaranteeMonths: 'Liczba miesięcy gwarancji',
        otherDates: 'Terminy pośrednie',
        mediaCosts: 'Obciążenie kosztów budowy (%)',
        wasteCosts: 'Koszty odpadów (zł)',
        securityRelease: 'Zwolnienie zabezpieczenia (%)',
        backendCosts: 'Koszty zaplecza (zł)',
        additionalConditions: 'Dodatkowe warunki',
        buildStartDate: 'Data rozpoczęcia prac'
      },
      grid: [],
      actions: {
        create: 'Dodaj',
        update: 'Edytuj',
        delete: 'Usuń'
      },
      recordActions: {
        addProtocol: 'Protokół',
        edit: 'Edytuj',
        pdf: 'PDF',
        delete: 'Usuń'
      }
    }
  }
};
