import styled from 'styled-components';

export const ListItem = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  font-family: ${({ theme }) => theme['detail-value-font-family']};
  font-size: ${({ theme }) => theme['detail-value-font-size']};
  font-weight: ${({ theme }) => theme['detail-value-font-weight']};
  color: ${({ theme }) => theme['detail-value-color']};
  background: ${({ theme }) => theme['detail-value-bg']};
`;
export const BudgetLine = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
`;
export const Left = styled.div`
  text-align: left;
`;
export const Right = styled.div`
  text-align: right;
`;
export const Positions = styled.div`
  padding-left: 2rem;
`;
