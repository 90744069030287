import DashboardListContainer from './containers/DashboardListContainer';
import DashboardPercentContainer from './containers/DashboardPercentContainer';
import DashboardRevenueContainer from './containers/DashboardRevenueContainer';

export const routes = [
  {
    name: 'home',
    isHome: true,
    path: '/',
    redirect: '/dashboard',
    componentConfig: []
  },
  {
    name: 'dashboard',
    path: '/dashboard',
    exact: true,
    label: 'Dashboard',
    componentConfig: [
      {
        component: 'DashboardLayout',
        children: [
          {
            component: DashboardListContainer,
            props: {
              endpoint: 'latestCosts',
              slice: 'ims/latestCosts/list',
              title: 'Ostatnie koszty',
              moreRoute: 'cost_list',
              moreLabel: 'Więcej'
            }
          },
          {
            component: DashboardRevenueContainer,
            props: {
              endpoint: 'monthlyCosts',
              slice: 'ims/monthlyCosts/list',
              title: 'Koszty miesięcznie'
            }
          },
          {
            component: DashboardListContainer,
            props: {
              endpoint: 'latestContracts',
              slice: 'ims/latestContracts/list',
              title: 'Ostatnie kontrakty',
              moreRoute: 'contract_list',
              moreLabel: 'Więcej'
            }
          },
          {
            component: DashboardListContainer,
            props: {
              endpoint: 'latestProtocols',
              slice: 'ims/latestProtocols/list',
              title: 'Ostatnie protokoły',
              moreRoute: 'protocol_list',
              moreLabel: 'Więcej'
            }
          },
          {
            component: DashboardPercentContainer,
            props: {
              endpoint: 'budgetPercent',
              slice: 'ims/budgetPercent/list',
              title: 'Wykorzystanie budżetu'
            }
          }
        ]
      }
    ]
  }
];
