export default {
  messages: {
    protocolItem: {
      create: 'New protocolItem',
      update: 'Update protocolItem',
      module: {
        name: 'ProtocolItem'
      },
      entity: {
        id: 'Id',
        protocol: 'Protocol',
        contractItem: 'ContractItem',
        donePercent: 'DonePercent',
        cost: 'Cost',
        description: 'Description',
        notes: 'Notes',
        createdBy: 'CreatedBy',
        updatedBy: 'UpdatedBy',
        createdAt: 'CreatedAt',
        updatedAt: 'UpdatedAt'
      },
      grid: [],
      actions: {
        create: 'Create',
        update: 'Update',
        delete: 'Delete'
      }
    }
  }
};
