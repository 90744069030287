import React from 'react';
import Money from '@8powers/ams/components/Display/Money';
import Contracts from './Contracts';
import Costs from './Costs';
import Donut from './Donut';
import { Wrapper, Center } from './styles';

class LineDetail extends React.Component {
  getContractCosts(record) {
    let total = 0;
    total += record.contractCosts;
    record.children.forEach(child => {
      total += this.getContractCosts(child);
    });

    return total;
  }

  getSpendCosts(record) {
    let total = 0;
    total += record.spendCosts;
    record.children.forEach(child => {
      total += this.getSpendCosts(child);
    });

    return total;
  }

  render() {
    const { record, showLineItemDetail } = this.props;
    if (!record) {
      return 'Loading...';
    }

    const contractCosts = this.getContractCosts(record).toFixed(2);
    const spendCosts = this.getSpendCosts(record).toFixed(2);
    return (
      <div>
        <div>
          <div>
            Zaplanowany budżet:{' '}
            <Money value={record.cost} currency="PLN" suffix />
          </div>
        </div>

        <Wrapper>
          <Donut
            title="Zakontraktowane"
            current={contractCosts}
            total={record.cost}
          >
            <Center>
              <Money value={contractCosts} currency="PLN" suffix />
            </Center>
          </Donut>
          <Donut title="Koszty" current={spendCosts} total={contractCosts}>
            <Center>
              <Money value={spendCosts} currency="PLN" suffix />
            </Center>
          </Donut>
        </Wrapper>

        <Contracts
          name={record.name}
          items={record.contractItems}
          onRowClick={showLineItemDetail}
        />
        <Costs
          name={record.name}
          items={record.costItems}
          onRowClick={showLineItemDetail}
        />
      </div>
    );
  }
}

export default LineDetail;
