const widgets = {
  InvestmentForm: {
    component: 'FormContainer',
    children: [
      {
        component: 'Form',
        props: {
          groups: [
            {
              label: 'investment.form.basic',
              columns: [
                [
                  {
                    name: 'name',
                    label: 'investment.entity.name',
                    type: 'text'
                  }
                ],
                [
                  {
                    name: 'code',
                    label: 'investment.entity.code',
                    type: 'text'
                  }
                ]
              ]
            },
            {
              label: null,
              columns: [
                [
                  {
                    name: 'project',
                    label: 'investment.entity.project',
                    type: 'textarea'
                  },
                  {
                    name: 'logo',
                    label: 'investment.entity.logo',
                    type: 'endpointPhoto',
                    endpoint: 'file',
                    slice: 'investment/logo/create',
                    multiple: false
                  },
                  {
                    name: 'plannedEndDate',
                    label: 'investment.entity.plannedEndDate',
                    type: 'date'
                  },
                  {
                    name: 'activeBudget',
                    label: 'investment.entity.activeBudget',
                    type: 'endpointCombo',
                    endpoint: 'budget',
                    slice: 'investment/budget/combo',
                    displayField: 'name'
                  },
                  {
                    name: 'documentsPath',
                    label: 'investment.entity.documentsPath',
                    type: 'text'
                  },
                  {
                    name: 'targetCompany',
                    label: 'investment.entity.targetCompany',
                    type: 'endpointCombo',
                    endpoint: 'targetCompany',
                    slice: 'investment/company/combo'
                  },
                  {
                    name: 'projectManager',
                    label: 'investment.entity.projectManager',
                    type: 'endpointCombo',
                    endpoint: 'profile',
                    slice: 'investment/profile/combo',
                    displayField: 'lastName'
                  },
                  {
                    name: 'state',
                    label: 'investment.entity.state',
                    type: 'endpointCombo',
                    endpoint: 'investmentState',
                    slice: 'investment/investmentState/combo',
                    placeholder: '--',
                    displayField: 'label'
                  }
                ]
              ]
            },
            {
              label: 'investment.form.address',
              columns: [
                [
                  {
                    name: 'address.postCode',
                    label: 'investment.entity.address.postCode',
                    type: 'text'
                  },
                  {
                    name: 'address.street',
                    label: 'investment.entity.address.street',
                    type: 'text'
                  }
                ],
                [
                  {
                    name: 'address.city',
                    label: 'investment.entity.address.city',
                    type: 'text'
                  },
                  {
                    name: 'address.buildingNumber',
                    label: 'investment.entity.address.buildingNumber',
                    type: 'text'
                  }
                ]
              ]
            },
            {
              label: 'investment.form.team',
              columns: [
                [
                  {
                    name: 'team',
                    label: 'investment.entity.teamSuggest',
                    type: 'endpointAutoSuggest',
                    endpoint: 'profile',
                    slice: 'profile/profile/list',
                    placeholder: 'investment.form.teamPlaceholder',
                    multiple: true,
                    rawControl: true
                  }
                ]
              ]
            }
          ]
        }
      }
    ]
  },
  InvestmentEditForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'investment',
      slice: 'investment/investment/edit',
      operation: 'edit',
      fancyMask: true
    },
    children: [
      {
        component: 'InvestmentForm'
      }
    ]
  },
  InvestmentCreateForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'investment',
      slice: 'investment/investment/create',
      operation: 'create',
      fancyMask: true
    },
    children: [
      {
        component: 'InvestmentForm'
      }
    ]
  },
  InvestmentDetailContainer: {
    component: 'EndpointSelectedContainer',
    props: {
      endpoint: 'investment',
      slice: 'investment/investment/list'
    },
    children: [
      {
        component: 'EndpointDetail',
        props: {
          config: {
            groups: [
              {
                label: 'Dane podstawowe',
                columns: [
                  [
                    {
                      key: 'id',
                      label: 'investment.entity.id'
                    },
                    {
                      key: 'name',
                      label: 'investment.entity.name'
                    },
                    {
                      key: 'code',
                      label: 'investment.entity.code'
                    },
                    {
                      key: 'project',
                      label: 'investment.entity.project'
                    },
                    {
                      key: 'logo',
                      label: 'investment.entity.logo',
                      type: 'thumbnail',
                      typeProps: {
                        style: 'large'
                      }
                    },
                    {
                      key: 'plannedEndDate',
                      label: 'investment.entity.plannedEndDate'
                    }
                  ],
                  [
                    {
                      key: 'documentsPath',
                      label: 'investment.entity.documentsPath',
                      type: 'link'
                    },
                    {
                      key: 'stateLabel',
                      label: 'investment.entity.state'
                    },
                    {
                      key: 'createdAt',
                      label: 'investment.entity.createdAt',
                      type: 'datetime'
                    },
                    {
                      key: 'updatedAt',
                      label: 'investment.entity.updatedAt',
                      type: 'datetime'
                    }
                  ]
                ]
              },
              {
                label: 'investment.form.address',
                columns: [
                  [
                    {
                      key: 'address.postCode',
                      label: 'investment.entity.address.postCode'
                    },
                    {
                      key: 'address.street',
                      label: 'investment.entity.address.street'
                    }
                  ],
                  [
                    {
                      key: 'address.city',
                      label: 'investment.entity.address.city'
                    },
                    {
                      key: 'address.buildingNumber',
                      label: 'investment.entity.address.buildingNumber'
                    }
                  ]
                ]
              }
            ]
          }
        }
      }
    ]
  }
};

export default widgets;
