export const routes = [
  {
    name: 'investment_list',
    path: '/investment',
    componentConfig: [
      {
        component: 'EndpointListContainer',
        props: {
          endpoint: 'investment',
          slice: 'investment/investment/list',
          selectAction: {
            preview: {
              meta: {
                modal: {
                  body: [
                    {
                      component: 'InvestmentDetailContainer'
                    }
                  ]
                }
              }
            },
            edit: {
              type: 'investment/investment/edit/start',
              meta: {
                modal: {
                  title: 'investment.actions.update',
                  body: [
                    {
                      component: 'InvestmentEditForm'
                    }
                  ]
                }
              }
            }
          }
        },
        children: [
          {
            component: 'Grid',
            props: {
              title: 'investment.module.name',
              icon: 'apartment',
              config: {
                columns: {
                  id: {
                    label: 'investment.entity.id',
                    width: '90px'
                  },
                  name: {
                    label: 'investment.entity.name'
                  },
                  code: {
                    label: 'investment.entity.code'
                  },
                  address: {
                    label: 'investment.entity.address.label',
                    display: {
                      type: 'objectProps',
                      props: ['city', 'street']
                    }
                  },
                  targetCompany: {
                    label: 'investment.entity.targetCompany',
                    display: {
                      type: 'objectProps',
                      props: ['name']
                    }
                  },
                  projectManager: {
                    label: 'investment.entity.projectManager',
                    display: {
                      type: 'objectProps',
                      props: ['firstName', 'lastName']
                    }
                  },
                  stateLabel: {
                    label: 'investment.entity.state'
                  },
                  updatedAt: {
                    label: 'investment.entity.updatedAt',
                    display: {
                      type: 'datetime'
                    }
                  }
                },
                filtersConfig: [
                  {
                    label: 'investment.filter.state',
                    property: 'state',
                    type: 'eq',
                    inputConfig: {
                      name: 'state',
                      type: 'endpointCombo',
                      endpoint: 'investmentState',
                      slice: 'investment/investmentState/combo',
                      placeholder: '--',
                      displayField: 'label'
                    }
                  }
                ],
                actions: [
                  {
                    label: 'investment.actions.create',
                    type: 'create',
                    icon: 'plus',
                    action: {
                      type: 'investment/investment/edit/start',
                      meta: {
                        modal: {
                          title: 'investment.create',
                          body: [
                            {
                              component: 'InvestmentCreateForm'
                            }
                          ]
                        }
                      }
                    }
                  }
                ],
                recordActions: [
                  {
                    label: '',
                    type: 'edit',
                    icon: 'pen',
                    action: {
                      type: 'investment/investment/edit/start',
                      meta: {
                        modal: {
                          title: 'investment.update',
                          body: [
                            {
                              component: 'InvestmentEditForm'
                            }
                          ]
                        }
                      }
                    }
                  },
                  {
                    label: '',
                    type: 'delete',
                    icon: 'trash',
                    action: {
                      type: 'investment/investment/list/delete',
                      meta: {
                        API: {
                          endpoint: 'investment',
                          operation: 'delete'
                        },
                        confirm: {
                          props: {
                            title: 'ims.confirm.delete.title',
                            message: 'ims.confirm.delete.message',
                            confirmText: 'ims.confirm.delete.confirmText',
                            cancelText: 'ims.confirm.delete.cancelText'
                          },
                          confirmed: false
                        }
                      }
                    }
                  }
                ]
              }
            }
          }
        ]
      }
    ],
    exact: true,
    global: true,
    section: true,
    module: 'investment',
    label: 'investment.module.name',
    icon: 'apartment'
  }
];
