export default {
  messages: {
    offer: {
      create: 'Nowa oferta',
      update: 'Edytuj ofertę',
      module: {
        name: 'Oferty'
      },
      entity: {
        id: 'Id',
        investment: 'Inwestycja',
        company: 'Firma',
        sendDate: 'Data wysłania',
        totalAmount: 'Kwota',
        description: 'Opis',
        type: 'Typ',
        items: 'Pozycje',
        contracts: 'Kontrakty',
        createdBy: 'Dodana przez',
        updatedBy: 'Aktualizowany przez',
        createdAt: 'Data dodania',
        updatedAt: 'Zmienione'
      },
      grid: [],
      actions: {
        create: 'Dodaj',
        update: 'Edytuj',
        delete: 'Usuń'
      },
      recordActions: {
        addContract: 'Umowa',
        edit: 'Edytuj',
        delete: 'Usuń'
      }
    }
  }
};
