export default {
  messages: {
    contractItem: {
      create: 'New contractItem',
      update: 'Update contractItem',
      module: {
        name: 'ContractItem'
      },
      entity: {
        id: 'Id',
        contract: 'Contract',
        budgetLine: 'BudgetLine',
        description: 'Description',
        cost: 'Cost',
        count: 'Count',
        unit: 'Unit',
        unitPrice: 'UnitPrice',
        protocolItems: 'ProtocolItems',
        costItems: 'CostItems',
        createdBy: 'CreatedBy',
        updatedBy: 'UpdatedBy',
        createdAt: 'CreatedAt',
        updatedAt: 'UpdatedAt'
      },
      grid: [],
      actions: {
        create: 'Create',
        update: 'Update',
        delete: 'Delete'
      }
    }
  }
};
