export default {
  messages: {
    cost: {
      create: 'Dodaj koszt',
      update: 'Edytuj koszt',
      module: {
        name: 'Koszty'
      },
      filter: {
        company: 'Firma'
      },
      entity: {
        id: 'Id',
        protocol: 'Protokół',
        protocolConditional: 'Na podstawie protokołu',
        createDate: 'Data powstania',
        paymentDate: 'Data płatności',
        company: 'Firma',
        budget: 'Budżet',
        items: 'Linia budżetowa',
        state: 'Status',
        createdBy: 'Dodany przez',
        updatedBy: 'Modyfikowany przez',
        createdAt: 'Data dodania',
        updatedAt: 'Zmienione',
        total: 'Suma'
      },
      grid: [],
      actions: {
        create: 'Dodaj',
        update: 'Edytuj',
        delete: 'Usuń'
      }
    }
  }
};
