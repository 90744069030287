import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { endpointComboSelector } from '@8powers/ams/redux/selectors';
import { createFetchAction } from '@8powers/core/redux/slice/utils';
import CollectionFieldContainer from '../../ims/containers/CollectionFieldContainer';

class OfferCollectionFieldContainer extends React.Component {
  componentDidMount() {
    const { fetchOffers } = this.props;
    fetchOffers && fetchOffers({ perPage: -1 });
  }

  render() {
    const { offers, relatedValues, value, ...rest } = this.props;
    let items = value;
    const id = relatedValues
      ? typeof relatedValues.offer === 'object'
        ? relatedValues.offer.id
        : relatedValues.offer
      : null;

    const offer = offers.find(item => item.id === id);

    if (offer) {
      items = offer.collection;
    }

    return (
      <CollectionFieldContainer
        {...rest}
        value={items}
        relatedValues={relatedValues || {}}
      />
    );
  }
}

const mapStateToProps = state => {
  const offers = endpointComboSelector('offer', 'offer/offer/list');
  return {
    offers: [...offers(state)]
  };
};

const mapDispatchToProps = dispatch => {
  const fetchOffers = createFetchAction({
    endpoint: 'offer',
    slice: 'offer/offer/list',
    blockUI: false
  });

  return {
    ...bindActionCreators({ fetchOffers }, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OfferCollectionFieldContainer);
