import React from 'react';
import { Icon } from '@8powers/ams/components/UI';
import Display from '@8powers/ams/components/Display';
import RowActions from '@8powers/ams/components/Grid/RowActions';
import { Input, Select } from '@8powers/ams/components/UI/Form';
import {
  Wrapper,
  Row,
  Content,
  Col,
  CollapseHandler,
  Name,
  Prefix,
  InputWrap,
  Multiply,
  Sum
} from './styles';

class ListItem extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    const { record } = this.props;
    if (record.isNew) {
      this.inputRef && this.inputRef.current.focus();
    }
  }

  createUnitSelect() {
    const { unitComboData, record } = this.props;

    const options = unitComboData.map(item => (
      <option
        key={item.id}
        value={item.id}
        selected={record.unit === item.value}
      >
        {item.label}
      </option>
    ));

    return (
      <Select name="unit" tabIndex={1}>
        {options}
      </Select>
    );
  }

  render() {
    const {
      prefix,
      record,
      recordActions,
      children,
      nodeDepth,
      onCollapseClick,
      isCollapsed,
      onInputChange,
      onBudgetLineCreate,
      onBudgetLineDuplicate,
      onBudgetLineDelete
    } = this.props;

    return (
      <Row
        depth={nodeDepth}
        extraMargin={record.children.length === 0}
        key={record.id}
        onMouseLeave={this.onMouseOut}
      >
        <Wrapper>
          <Content>
            <Col>
              {record.children.length > 0 && (
                <CollapseHandler onClick={onCollapseClick}>
                  <Icon name={isCollapsed ? 'chevron-down' : 'chevron-up'} />
                </CollapseHandler>
              )}
              <Name>
                {prefix && <Prefix>{prefix}.</Prefix>}
                <Input
                  value={record.name}
                  name="name"
                  onChange={e => onInputChange(e, record.id)}
                  ref={this.inputRef}
                  tabIndex={1}
                />
              </Name>
            </Col>
            <Col>
              {record.children.length === 0 && (
                <React.Fragment>
                  <InputWrap>
                    <Input
                      value={record.count}
                      name="count"
                      onChange={e => onInputChange(e, record.id)}
                      tabIndex={1}
                    />
                  </InputWrap>
                  <InputWrap>{this.createUnitSelect()}</InputWrap>
                  <Multiply>&times;</Multiply>
                  <InputWrap withCurrency>
                    <Input
                      value={record.unitPrice}
                      name="unitPrice"
                      onChange={e => onInputChange(e, record.id)}
                      tabIndex={1}
                    />
                  </InputWrap>
                  <Multiply>=</Multiply>
                </React.Fragment>
              )}

              <Sum>
                <Display type="price" value={record.total} /> zł
              </Sum>
            </Col>
          </Content>
          <RowActions
            item={record}
            actions={[
              {
                label: 'budget.line.list.rowActions.newLine',
                type: 'create',
                icon: 'plus',
                visibleOnHover: true,
                hideLabel: true,
                action: () => onBudgetLineCreate(record.id)
              },
              {
                label: 'budget.line.list.rowActions.newSubline',
                type: 'create',
                icon: 'pen',
                hideLabel: true,
                action: () => onBudgetLineCreate(record.id, true)
              },
              {
                label: 'budget.line.list.rowActions.duplicate',
                type: 'create',
                icon: 'copy',
                hideLabel: true,
                action: () => onBudgetLineDuplicate(record)
              },
              {
                label: 'budget.line.list.rowActions.delete',
                type: 'delete',
                icon: 'trash',
                hideLabel: true,
                action: () => onBudgetLineDelete(record.id)
              },
              ...recordActions
            ]}
          />
        </Wrapper>
        {children}
      </Row>
    );
  }
}

export default ListItem;
