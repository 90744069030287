export default {
  initialState: {
    ams: {
      endpoints: {
        investment: {
          fetch: {
            method: 'GET',
            url: '/api/investment'
          },
          create: {
            method: 'POST',
            url: '/api/investment'
          },
          edit: {
            method: 'PATCH',
            url: '/api/investment/${id}',
            headers: {
              'Content-Type': 'application/merge-patch+json'
            }
          },
          find: {
            method: 'GET',
            url: '/api/investment/${id}'
          },
          delete: {
            method: 'DELETE',
            url: '/api/investment/${id}'
          }
        },
        team: {
          fetch: {
            method: 'GET',
            url: '/api/team'
          },
          create: {
            method: 'POST',
            url: '/api/team'
          },
          edit: {
            method: 'PATCH',
            url: '/api/team/${id}',
            headers: {
              'Content-Type': 'application/merge-patch+json'
            }
          },
          find: {
            method: 'GET',
            url: '/api/team/${id}'
          },
          delete: {
            method: 'DELETE',
            url: '/api/team/${id}'
          }
        },
        investmentState: {
          fetch: {
            method: 'GET',
            url: '/api/investment/state-list'
          }
        },
        profile: {
          fetch: {
            method: 'GET',
            url: '/api/profile'
          },
          create: {
            method: 'POST',
            url: '/api/profile'
          },
          edit: {
            method: 'PATCH',
            url: '/api/profile/${id}',
            headers: {
              'Content-Type': 'application/merge-patch+json'
            }
          },
          find: {
            method: 'GET',
            url: '/api/profile/${id}'
          },
          delete: {
            method: 'DELETE',
            url: '/api/profile/${id}'
          }
        },
        profileState: {
          fetch: {
            method: 'GET',
            url: '/api/profile/state-list'
          }
        },
        profilePosition: {
          fetch: {
            method: 'GET',
            url: '/api/dictionary/profile_position'
          }
        },
        location: {
          fetch: {
            method: 'GET',
            url: '/api/ams/location'
          }
        },
        company: {
          fetch: {
            method: 'GET',
            url: '/api/company?order[id]=desc'
          },
          create: {
            method: 'POST',
            url: '/api/company'
          },
          edit: {
            method: 'PATCH',
            url: '/api/company/${id}',
            headers: {
              'Content-Type': 'application/merge-patch+json'
            }
          },
          find: {
            method: 'GET',
            url: '/api/company/${id}'
          },
          delete: {
            method: 'DELETE',
            url: '/api/company/${id}'
          }
        },
        companyCombo: {
          fetch: {
            method: 'GET',
            url: '/api/company?order[name]=asc'
          }
        },
        targetCompany: {
          fetch: {
            method: 'GET',
            url: '/api/company?type[eq]=2'
          }
        },
        companyState: {
          fetch: {
            method: 'GET',
            url: '/api/company/state-list'
          }
        },
        companyType: {
          fetch: {
            method: 'GET',
            url: '/api/company/type-list'
          }
        },
        person: {
          fetch: {
            method: 'GET',
            url: '/api/person'
          },
          create: {
            method: 'POST',
            url: '/api/person'
          },
          edit: {
            method: 'PATCH',
            url: '/api/person/${id}',
            headers: {
              'Content-Type': 'application/merge-patch+json'
            }
          },
          find: {
            method: 'GET',
            url: '/api/person/${id}'
          },
          delete: {
            method: 'DELETE',
            url: '/api/person/${id}'
          }
        },
        personState: {
          fetch: {
            method: 'GET',
            url: '/api/person/state-list'
          }
        },
        budget: {
          fetch: {
            method: 'GET',
            url: '/api/budget'
          },
          create: {
            method: 'POST',
            url: '/api/budget'
          },
          edit: {
            method: 'PATCH',
            url: '/api/budget/${id}',
            headers: {
              'Content-Type': 'application/merge-patch+json'
            }
          },
          find: {
            method: 'GET',
            url: '/api/budget/${id}'
          },
          delete: {
            method: 'DELETE',
            url: '/api/budget/${id}'
          },
          clone: {
            method: 'POST',
            url: '/api/budget/${id}/clone'
          }
        },
        budgetLine: {
          fetch: {
            method: 'GET',
            url: '/api/budget-line'
          },
          create: {
            method: 'POST',
            url: '/api/budget-line'
          },
          edit: {
            method: 'PATCH',
            url: '/api/budget-line/${id}',
            headers: {
              'Content-Type': 'application/merge-patch+json'
            }
          },
          find: {
            method: 'GET',
            url: '/api/budget-line/${id}'
          },
          delete: {
            method: 'DELETE',
            url: '/api/budget-line/${id}'
          }
        },
        budgetLineUnit: {
          fetch: {
            method: 'GET',
            url: '/api/dictionary/budget_line_unit'
          }
        },
        budgetLineQuarter: {
          fetch: {
            method: 'GET',
            url: '/api/budget-line'
          }
        },
        offer: {
          fetch: {
            method: 'GET',
            url: '/api/offer'
          },
          create: {
            method: 'POST',
            url: '/api/offer'
          },
          edit: {
            method: 'PATCH',
            url: '/api/offer/${id}',
            headers: {
              'Content-Type': 'application/merge-patch+json'
            }
          },
          find: {
            method: 'GET',
            url: '/api/offer/${id}'
          },
          delete: {
            method: 'DELETE',
            url: '/api/offer/${id}'
          },
          addContract: {
            method: 'POST',
            url: '/api/offer/add-contract/${id}'
          }
        },
        offerType: {
          fetch: {
            method: 'GET',
            url: '/api/dictionary/offer_type'
          }
        },
        offerItem: {
          fetch: {
            method: 'GET',
            url: '/api/offer-item'
          },
          create: {
            method: 'POST',
            url: '/api/offer-item'
          },
          edit: {
            method: 'PATCH',
            url: '/api/offer-item/${id}',
            headers: {
              'Content-Type': 'application/merge-patch+json'
            }
          },
          find: {
            method: 'GET',
            url: '/api/offer-item/${id}'
          },
          delete: {
            method: 'DELETE',
            url: '/api/offer-item/${id}'
          }
        },
        contract: {
          fetch: {
            method: 'GET',
            url: '/api/contract'
          },
          create: {
            method: 'POST',
            url: '/api/contract'
          },
          edit: {
            method: 'PATCH',
            url: '/api/contract/${id}',
            headers: {
              'Content-Type': 'application/merge-patch+json'
            }
          },
          find: {
            method: 'GET',
            url: '/api/contract/${id}'
          },
          delete: {
            method: 'DELETE',
            url: '/api/contract/${id}'
          },
          addProtocol: {
            method: 'POST',
            url: '/api/contract/add-protocol/${id}'
          }
        },
        contractType: {
          fetch: {
            method: 'GET',
            url: '/api/dictionary/contract_type'
          }
        },
        contractState: {
          fetch: {
            method: 'GET',
            url: '/api/contract/state-list'
          }
        },
        contractItem: {
          fetch: {
            method: 'GET',
            url: '/api/contract-item'
          },
          create: {
            method: 'POST',
            url: '/api/contract-item'
          },
          edit: {
            method: 'PATCH',
            url: '/api/contract-item/${id}',
            headers: {
              'Content-Type': 'application/merge-patch+json'
            }
          },
          find: {
            method: 'GET',
            url: '/api/contract-item/${id}'
          },
          delete: {
            method: 'DELETE',
            url: '/api/contract-item/${id}'
          }
        },
        protocol: {
          fetch: {
            method: 'GET',
            url: '/api/protocol'
          },
          create: {
            method: 'POST',
            url: '/api/protocol'
          },
          edit: {
            method: 'PATCH',
            url: '/api/protocol/${id}',
            headers: {
              'Content-Type': 'application/merge-patch+json'
            }
          },
          find: {
            method: 'GET',
            url: '/api/protocol/${id}'
          },
          delete: {
            method: 'DELETE',
            url: '/api/protocol/${id}'
          },
          addCost: {
            method: 'POST',
            url: '/api/protocol/add-cost/${id}'
          }
        },
        protocolItem: {
          fetch: {
            method: 'GET',
            url: '/api/protocol-item'
          },
          create: {
            method: 'POST',
            url: '/api/protocol-item'
          },
          edit: {
            method: 'PATCH',
            url: '/api/protocol-item/${id}',
            headers: {
              'Content-Type': 'application/merge-patch+json'
            }
          },
          find: {
            method: 'GET',
            url: '/api/protocol-item/${id}'
          },
          delete: {
            method: 'DELETE',
            url: '/api/protocol-item/${id}'
          }
        },
        cost: {
          fetch: {
            method: 'GET',
            url: '/api/cost'
          },
          create: {
            method: 'POST',
            url: '/api/cost'
          },
          edit: {
            method: 'PATCH',
            url: '/api/cost/${id}',
            headers: {
              'Content-Type': 'application/merge-patch+json'
            }
          },
          find: {
            method: 'GET',
            url: '/api/cost/${id}'
          },
          delete: {
            method: 'DELETE',
            url: '/api/cost/${id}'
          }
        },
        costState: {
          fetch: {
            method: 'GET',
            url: '/api/cost/state-list'
          }
        },
        costItem: {
          fetch: {
            method: 'GET',
            url: '/api/cost-item'
          },
          create: {
            method: 'POST',
            url: '/api/cost-item'
          },
          edit: {
            method: 'PATCH',
            url: '/api/cost-item/${id}',
            headers: {
              'Content-Type': 'application/merge-patch+json'
            }
          },
          find: {
            method: 'GET',
            url: '/api/cost-item/${id}'
          },
          delete: {
            method: 'DELETE',
            url: '/api/cost-item/${id}'
          }
        },
        pdf: {
          download: {
            method: 'GET',
            url: '/pdf/${type}/${id}'
          }
        },
        latestCosts: {
          fetch: {
            method: 'GET',
            url: '/api/dashboard/latest-costs'
          }
        },
        latestContracts: {
          fetch: {
            method: 'GET',
            url: '/api/dashboard/latest-contracts'
          }
        },
        latestProtocols: {
          fetch: {
            method: 'GET',
            url: '/api/dashboard/latest-protocols'
          }
        },
        budgetPercent: {
          fetch: {
            method: 'GET',
            url: '/api/dashboard/budget-percent'
          }
        },
        monthlyCosts: {
          fetch: {
            method: 'GET',
            url: '/api/dashboard/monthly-costs'
          }
        }
      }
    }
  }
};
