import React from 'react';

import { Completed, Overflow, Container, Empty } from './styles';

class Index extends React.Component {
  render() {
    const { percent } = this.props;

    const CompleteBar = percent <= 100 ? Completed : Overflow;

    return (
      <Container>
        <Empty />
        <CompleteBar style={{ width: `${percent  }%` }} />
      </Container>
    );
  }
}

export default Index;
