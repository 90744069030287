import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createAction } from 'redux-starter-kit';
import {
  endpointRootSelector,
  endpointFormSelector,
  endpointComboSelector
} from '@8powers/ams/redux/selectors';
import {
  createFetchAction,
  createSubmitAction
} from '@8powers/core/redux/slice/utils';
import { selectors as uiSelectors } from '@8powers/ams/ducks/ui';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class BudgetTreeContainer extends React.Component {
  state = {
    mode: 'preview',
    budgetLinesTree: [],
    record: null
  };

  componentDidMount() {
    const { find, fetchUnits, id } = this.props;
    find &&
      find({ id }, record => {
        const recalc = BudgetTreeContainer.recalcBudgetState(
          record.budgetLines
        );
        let tree = [...recalc.items];
        if (tree.length === 0) {
          tree = [
            BudgetTreeContainer.getNewLineTpl(record.startDate, record.endDate)
          ];
        }

        this.setState({
          record,
          isEmpty: !(record.budgetLines.length > 0),
          budgetLinesTree: [...tree],
          total: recalc.total
        });
      });
    fetchUnits && fetchUnits();
  }

  static recalcBudgetState = budgetLinesTree => {
    function recursion(node) {
      return node.map(line => {
        let children = [];
        let total = 0;
        let sumToDistribute = 0;

        if (line.children && line.children.length > 0) {
          children = [...recursion(line.children)];
          children.forEach(child => {
            if (child.children && child.children.length > 0) {
              total += child.total;
              sumToDistribute += child.sumToDistribute;
            } else {
              total +=
                parseFloat(`${child.count}`.replace(',', '.')) *
                parseFloat(`${child.unitPrice}`.replace(',', '.'));
              sumToDistribute += child.sumToDistribute;
            }
          });
        } else {
          total =
            parseFloat(`${line.count}`.replace(',', '.')) *
            parseFloat(`${line.unitPrice}`.replace(',', '.'));

          let scheduleTotal = 0;
          line.schedule.forEach(quarter => {
            scheduleTotal += parseFloat(quarter.cost);
          });

          sumToDistribute = total - scheduleTotal;
        }

        return {
          ...line,
          name: line.name,
          children,
          total,
          sumToDistribute
        };
      });
    }
    const items = recursion(budgetLinesTree);
    let globalTotal = 0;
    items.forEach(item => {
      globalTotal += item.total;
    });

    return {
      items,
      total: globalTotal
    };
  };

  recalcBudgetTreeTotal = () => {
    const { budgetLinesTree } = this.state;
    const recalc = BudgetTreeContainer.recalcBudgetState(budgetLinesTree);
    this.setState({
      budgetLinesTree: [...recalc.items],
      total: recalc.total
    });
  };

  static getScheduleTpl(budgetStartDate, budgetEndDate) {
    const startDate = new Date(budgetStartDate);
    const endDate = new Date(budgetEndDate);
    const startYear = parseInt(startDate.getFullYear(), 10);
    let startQuarter = Math.floor(startDate.getMonth() / 3) + 1;
    const endYear = parseInt(endDate.getFullYear(), 10);
    const endQuarter = Math.floor(endDate.getMonth() / 3) + 1;
    let lastQuarter = 4;

    const schedule = [];

    for (let year = startYear; year <= endYear; year += 1) {
      if (year === endYear) {
        lastQuarter = endQuarter;
      }

      for (let quarter = startQuarter; quarter <= lastQuarter; quarter += 1) {
        schedule.push({
          year,
          cost: 0,
          quarter
        });
      }

      startQuarter = 1;
    }

    return schedule;
  }

  static getNewLineTpl(startDate, endDate, data = {}) {
    return {
      id: Date.now() + Math.floor(Math.random() * 100),
      name: '',
      count: 1,
      cost: 0,
      unit: '',
      unitPrice: 0,
      schedule: BudgetTreeContainer.getScheduleTpl(startDate, endDate),
      children: [],
      total: 0,
      sumToDistribute: 0,
      isNew: true,
      ...data
    };
  }

  handleModeChange = mode => {
    this.setState({
      mode
    });
  };

  handleInputChange = (e, recordId) => {
    const { target } = e;
    const { name, value } = target;

    this.setState(
      {
        budgetLinesTree: [...this.updateBudgetTree(recordId, name, value)],
        isEmpty: false
      },
      this.recalcBudgetTreeTotal
    );
  };

  handleQuarterInputChange = (e, recordId) => {
    const { target } = e;
    const { name, value } = target;
    const quarterIndex = parseInt(name.split('_')[1], 10);

    this.setState(
      {
        budgetLinesTree: [
          ...this.updateSchedule(recordId, quarterIndex, value)
        ],
        isEmpty: false
      },
      this.recalcBudgetTreeTotal
    );
  };

  updateBudgetTree(id, key, value) {
    const { budgetLinesTree } = this.state;

    function recursion(node) {
      return node.map(line => {
        if (line.children && line.children.length > 0) {
          line.children = [...recursion(line.children)];
        }

        if (line.id === id) {
          return {
            ...line,
            [key]: value,
            isNew: false
          };
        }

        return line;
      });
    }

    return recursion(budgetLinesTree);
  }

  reorderTree = result => {
    if (!result.destination) {
      return;
    }
    const { budgetLinesTree } = this.state;
    const dropzone = parseInt(result.destination.droppableId, 10);
    const sourceIndex = result.source.index;
    const destination = result.destination.index;

    function recursion(node) {
      return node.map(line => {
        if (line.id === dropzone) {
          line.children = reorder(line.children, sourceIndex, destination);
        } else if (line.children && line.children.length > 0) {
          line.children = [...recursion(line.children)];
        }
        return line;
      });
    }

    this.setState(
      {
        budgetLinesTree: [...recursion(budgetLinesTree)],
        isEmpty: false
      },
      this.recalcBudgetTreeTotal
    );
  };

  updateSchedule(recordId, quarterIndex, value) {
    const { budgetLinesTree } = this.state;

    function recursion(node) {
      return node.map(line => {
        if (line.children && line.children.length > 0) {
          line.children = [...recursion(line.children)];
        }

        if (line.id === recordId) {
          const updatedSchedule = line.schedule.map((quarter, index) => {
            let cost = quarter.cost;

            if (value.length === 0) {
              value = 0;
            }

            if (index === quarterIndex) {
              cost = parseFloat(value);
            }
            return {
              ...quarter,
              cost
            };
          });

          return {
            ...line,
            schedule: [...updatedSchedule]
          };
        }

        return line;
      });
    }

    return recursion(budgetLinesTree);
  }

  addSiblingNode(budgetLinesTree, siblingId, newBudgetLine) {
    let added = false;

    function recursion(budgetLinesTree, siblingId, newBudgetLine) {
      return budgetLinesTree.map(line => {
        if (line.children && line.children.length > 0) {
          line.children = recursion(line.children, siblingId, newBudgetLine);
          if (line.children.find(item => item.id === siblingId)) {
            line.children.push(newBudgetLine);
            added = true;
          }
        }

        return line;
      });
    }

    const tree = recursion(budgetLinesTree, siblingId, newBudgetLine);

    if (!added) {
      tree.push(newBudgetLine);
    }

    return tree;
  }

  addChildNode(budgetLinesTree, parentId, newBudgetLine) {
    const { record } = this.state;
    const { unitComboData } = this.props;
    return budgetLinesTree.map(line => {
      let lineCopy = { ...line };

      if (line.children && line.children.length > 0) {
        lineCopy.children = this.addChildNode(
          line.children,
          parentId,
          newBudgetLine
        );
      } else if (line.id === parentId) {
        const copy = {
          ...lineCopy
        };

        const newGroup = BudgetTreeContainer.getNewLineTpl(
          record.startDate,
          record.endDate,
          { name: lineCopy.name, count: 0, unit: unitComboData[0].id }
        );

        lineCopy = {
          ...newGroup,
          children: [{ ...copy, name: '', isNew: true }]
        };
      }

      return lineCopy;
    });
  }

  handleBudgetLineCreate = (lineId, asChild = false) => {
    const { record } = this.state;
    const { unitComboData } = this.props;
    const newLine = BudgetTreeContainer.getNewLineTpl(
      record.startDate,
      record.endDate,
      {
        unit: unitComboData[0].id
      }
    );
    const { budgetLinesTree } = this.state;
    const newBudgetLinesTree = asChild
      ? [...this.addChildNode(budgetLinesTree, lineId, newLine)]
      : [...this.addSiblingNode(budgetLinesTree, lineId, newLine)];

    this.setState(
      {
        budgetLinesTree: newBudgetLinesTree,
        isEmpty: false
      },
      this.recalcBudgetTreeTotal
    );
  };

  duplicateNode(budgetLinesTree, node) {
    return budgetLinesTree.map(line => {
      if (line.children && line.children.length > 0) {
        line.children = this.duplicateNode(line.children, node);
      }

      if (line.id === node.parent_id) {
        line.children && line.children.push({ ...node });
      }

      return line;
    });
  }

  handleBudgetLineDuplicate = record => {
    const { budgetLinesTree } = this.state;

    function recursion(node, newParentId) {
      return node.map(line => {
        if (line.children && line.children.length > 0) {
          const newId = Date.now() + Math.floor(Math.random() * 100);
          return {
            ...line,
            id: newId,
            parent_id: newParentId,
            children: [...recursion(line.children, newId)]
          };
        }

        return {
          ...line,
          id: Date.now() + Math.floor(Math.random() * 100),
          parent_id: newParentId
        };
      });
    }

    const newId = Date.now() + Math.floor(Math.random() * 100);

    const node = {
      ...record,
      id: newId,
      children: recursion(record.children, newId)
    };

    let newBudgetLinesTree;
    if (node.parent_id === null) {
      newBudgetLinesTree = [...budgetLinesTree, node];
    } else {
      newBudgetLinesTree = this.duplicateNode(budgetLinesTree, node);
    }

    this.setState(
      {
        budgetLinesTree: newBudgetLinesTree
      },
      this.recalcBudgetTreeTotal
    );
  };

  removeNode(nodeId) {
    const { budgetLinesTree } = this.state;

    function recursion(tree) {
      return tree.map(line => {
        return {
          ...line,
          children: [
            ...recursion(line.children.filter(item => item.id !== nodeId))
          ]
        };
      });
    }

    return recursion(budgetLinesTree.filter(item => item.id !== nodeId));
  }

  handleBudgetLineDelete = lineId => {
    const { record } = this.state;
    const { unitComboData } = this.props;
    let budgetLinesTree = [...this.removeNode(lineId)];
    let isEmpty = false;

    if (budgetLinesTree.length === 0) {
      isEmpty = true;
      // add one for edit purpose
      budgetLinesTree = [
        BudgetTreeContainer.getNewLineTpl(record.startDate, record.endDate, {
          unit: unitComboData[0].id
        })
      ];
    }

    this.setState(
      {
        budgetLinesTree,
        isEmpty
      },
      this.recalcBudgetTreeTotal
    );
  };

  handleSubmit = () => {
    const { budgetLinesTree, record } = this.state;
    const { submit } = this.props;
    const { name, startDate, endDate, investment } = record;

    submit(
      {
        budgetLines: budgetLinesTree,
        name,
        startDate,
        endDate,
        investment
      },
      record.id,
      record
    );
  };

  handleClone = () => {
    const { record } = this.state;
    const { clone } = this.props;
    clone(record.id, record);
  };

  render() {
    const { mode, budgetLinesTree, isEmpty, total } = this.state;
    const { children, showDetail, userData } = this.props;

    const superAdmin = userData.roles.indexOf('SuperAdmin') !== -1;

    return React.Children.map(children, child => {
      if (!child) {
        return null;
      }

      return React.cloneElement(child, {
        ...this.props,
        mode,
        total,
        onModeChange: this.handleModeChange,
        onInputChange: this.handleInputChange,
        onDragEnd: this.reorderTree,
        onQuarterInputChange: this.handleQuarterInputChange,
        onBudgetLineCreate: this.handleBudgetLineCreate,
        onBudgetLineDuplicate: this.handleBudgetLineDuplicate,
        onBudgetLineDelete: this.handleBudgetLineDelete,
        onSubmit: this.handleSubmit,
        clone: this.handleClone,
        budgetLinesTree,
        disableEdit: this.state.record
          ? superAdmin
            ? false
            : this.state.record.approved
          : false,
        isEmpty,
        onRowClick: showDetail
      });
    });
  }
}

const mapStateToProps = state => {
  const userData = endpointRootSelector('core', 'core/session/user');
  const selectData = endpointFormSelector('budget', 'budget/budget/detail');
  const unitData = endpointComboSelector(
    'budgetLineUnit',
    'budget/budgetLineUnit/combo'
  );
  return {
    ...selectData(state),
    unitComboData: [...unitData(state)],
    isPreviewMode: uiSelectors.selectRoot(state).listSelectActionPreview,
    userData: userData(state)
  };
};

const mapDispatchToProps = dispatch => {
  const slice = 'budget/budget/detail';
  // const find = createFindAction({ endpoint: 'budget', slice });
  const find = (params = {}, callback) => {
    return {
      type: `${slice}/find`,
      payload: params,
      meta: {
        slice,
        API: {
          endpoint: 'budget',
          operation: 'find',
          data: params
        },
        callback,
        blockUI: {
          block: true
        }
      }
    };
  };
  const submit = createSubmitAction({
    endpoint: 'budget',
    slice: 'budget/budget/edit',
    operation: 'edit',
    blockUI: true
  });

  const clone = (id, record) => {
    return {
      type: 'budget/budget/clone',
      payload: { ...record },
      meta: {
        modal: {
          // Use data from budget lines since detail container cannot change modal title
          title: `${record.name}`,
          body: [
            {
              component: 'BudgetCloneForm',
              props: {
                record: {
                  ...record,
                  name: `${record.name} - kopia`
                }
              }
            }
          ]
        }
      }
    };
  };

  const fetchUnits = createFetchAction({
    endpoint: 'budgetLineUnit',
    slice: 'budget/budgetLineUnit/combo',
    blockUI: false
  });

  // Create Action to open detail modal
  const showDetail = (_, record, showLineItemDetail) => {
    return {
      type: 'budgetLine/budgetLine/detail',
      payload: { ...record },
      sideEffects: {
        // Since modal blocks any further updates I have to use sideEffect to force new data load into DetailContainer
        'ams/modal/open': {
          type: 'budget/budgetLine/detail/find',
          _convertPayload: 'extractAPI',
          meta: {
            API: {
              method: 'GET',
              endpoint: 'budgetLine',
              slice: 'budget/budgetLine/detail',
              operation: 'find',
              data: { id: record.id }
            }
          }
        }
      },
      meta: {
        modal: {
          // Use data from budget lines since detail container cannot change modal title
          title: `${record.listOrder} ${record.name}`,
          body: [
            {
              component: 'EndpointDetailContainer',
              props: {
                endpoint: 'budgetLine',
                slice: 'budget/budgetLine/detail'
              },
              children: [
                {
                  component: 'LineDetail',
                  props: {
                    showLineItemDetail
                  }
                }
              ]
            }
          ]
        }
      }
    };
  };

  const showLineItemDetail = (_, record, component, title) => {
    const body = {
      contractItem: {
        component: 'ContractItemDetailComponent'
      },
      costItem: {
        component: 'CostItemDetailComponent'
      }
    };

    return {
      type: `${component}/${component}/detail`,
      payload: { ...record },
      sideEffects: {
        // Since modal blocks any further updates I have to use sideEffect to force new data load into DetailContainer
        'ams/modal/open': {
          type: `budget/${component}/detail/find`,
          _convertPayload: 'extractAPI',
          meta: {
            API: {
              method: 'GET',
              endpoint: component,
              slice: `${component}/${component}/detail`,
              operation: 'find',
              data: { id: record.id }
            }
          }
        }
      },
      meta: {
        modal: {
          stack: true,
          // Use data from budget lines since detail container cannot change modal title
          title,
          body: [
            {
              component: 'EndpointDetailContainer',
              props: {
                endpoint: component,
                slice: `${component}/${component}/detail`
              },
              children: [body[component]]
            }
          ]
        }
      }
    };
  };

  const actions = ['init', 'clear'].reduce((acc, name) => {
    if (Array.isArray(name)) {
      acc[name[1]] = createAction(`${slice}/${name[0]}`);
    } else {
      acc[name] = createAction(`${slice}/${name}`);
    }
    return acc;
  }, {});

  return {
    ...bindActionCreators(
      { ...actions, fetchUnits, submit, clone, showDetail, showLineItemDetail },
      dispatch
    ),
    find: (id, callback) => {
      dispatch(find(id, callback));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BudgetTreeContainer);
