import Field from '@8powers/ams/components/Form/Field';
import Display from '@8powers/ams/components/Display';
import reducer, { saga as sagas, actions } from './ducks';
import * as translations from './translations';
import widgets from './widgets';
import CollectionField from './containers/CollectionFieldContainer';
import ConditionalField from './components/ConditionalField';
import ItemsList from './components/ItemsList';
import WideEndpointComboContainer from './components/WideEndpointComboContainer';

Field.register('collection', { input: CollectionField }, true);
Field.register('conditionalField', { input: ConditionalField }, true);
Field.register(
  'wideEndpointCombo',
  { input: WideEndpointComboContainer },
  true
);

Display.register('itemsList', ItemsList);

export const name = 'ims';
export const realName = 'ims';
export { reducer, widgets, sagas, actions, translations };
export * from './routes';
