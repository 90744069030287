import React from 'react';
import RelativeDate from '@8powers/ams/components/Display/RelativeDate';
import Money from '@8powers/ams/components/Display/Money';
import { withTranslation } from 'react-i18next';
import {
  Wrapper,
  SumCol,
  RelativeDateCol,
  Title,
  Desc,
  TextCol,
  StatusCol,
  Headline,
  Status
} from '../styles';

class Costs extends React.Component {
  getItems() {
    const { name, items, onRowClick, t } = this.props;
    const list = [];
    let lastCompanyName;

    items.forEach((costItem, i) => {
      list.push(
        <Wrapper
          key={i}
          onClick={e =>
            onRowClick &&
            onRowClick(e, costItem, 'costItem', t('budget.line.modal.costItem'))
          }
        >
          <TextCol>
            {lastCompanyName !== costItem.cost.company.name && (
              <Title>{costItem.cost.company.name}</Title>
            )}
            <Desc>
              {name}, {costItem.description}
            </Desc>
          </TextCol>
          <RelativeDateCol>
            <RelativeDate value={costItem.cost.paymentDate} />
          </RelativeDateCol>
          <SumCol>
            <Money value={costItem.amount} currency="PLN" suffix />
          </SumCol>
          <StatusCol>
            <Status status={costItem.cost.state.id}></Status>
          </StatusCol>
        </Wrapper>
      );
      lastCompanyName = costItem.cost.company.name;
    });

    return list;
  }

  render() {
    const { items } = this.props;
    if (items.length === 0) return null;

    return (
      <div>
        <Headline>Koszty:</Headline>
        {this.getItems()}
      </div>
    );
  }
}

export default withTranslation()(Costs);
