import styled from 'styled-components';
import {
  DashboardItem,
  DashboardItemHeader
} from '@8powers/ams/components/Dashboard/DashboardItem';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  justify-content: space-between;
`;

export const Center = styled.div`
  text-align: center;
  width: 100%;
`;

export const Column = styled(DashboardItem)`
  display: flex;
  flex-direction: column;
  padding: 0 1rem 1rem 1rem;
`;
export const Header = styled(DashboardItemHeader)`
  display: flex;
  padding: 1rem;
  flex-direction: column;
`;

export const SumCol = styled.div`
  padding: 1rem 0.5rem;
  white-space: nowrap;
  flex: 0 0 12.5rem;
  text-align: right;
`;

export const RelativeDateCol = styled.div`
  padding: 1rem 0.5rem;
  white-space: nowrap;
  text-align: right;
  font-size: 1.4rem;
  color: #999;
  flex: 0 0 14rem;
  margin-left: auto;
`;

export const TextCol = styled.div`
  padding: 1rem 0.5rem;
`;

export const NumberCol = styled.div`
  padding: 1rem 0.5rem;
  flex: 0 0 10rem;
  text-align: right;
`;

export const StatusCol = styled.div`
  padding: 1rem 0.5rem;
  position: relative;
  flex: 0 0 3rem;
  text-align: right;
`;

export const Status = styled.div`
  height: 1.2rem;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0.8rem;
    left: 50%;
    width: 0.6rem;
    height: 0.6rem;
    display: block;
    border-radius: 50%;
    background: ${({ status }) => (status === 1 ? 'orange' : 'green')};
    transform: translateX(-50%);
  }
`;

export const NameCol = styled.div`
  padding: 1rem 0.5rem;
`;

export const Headline = styled.h3`
  margin: 3rem 0 0;
  padding-left: 0.5rem;
  color: #1c236d;
`;

export const Title = styled.div`
  font-weight: bold;
`;
export const Desc = styled.div``;
