export default {
  messages: {
    company: {
      create: 'New company',
      update: 'Update company',
      module: {
        name: 'Company'
      },
      entity: {
        id: 'Id',
        name: 'Name',
        contactData: {
          label: 'ContactData',
          city: 'City',
          postCode: 'Post code',
          street: 'Street',
          buildingNumber: 'Building number'
        },
        invoiceData: {
          label: 'InvoiceData',
          city: 'City',
          postCode: 'Post code',
          street: 'Street',
          buildingNumber: 'Building number'
        },
        sameAddress: 'Same address',
        email: 'Email',
        taxID: 'TaxID',
        krs: 'KRS',
        type: 'Type',
        investments: 'Investments',
        offers: 'Offers',
        contracts: 'Contracts',
        costs: 'Costs',
        state: 'State',
        createdAt: 'CreatedAt',
        updatedAt: 'UpdatedAt',
        regon: 'Regon',
        representedBy: 'RepresentedBy',
        accountNumber: 'AccountNumber'
      },
      grid: [],
      actions: {
        create: 'Create',
        delete: 'Delete'
      },
      filter: {
        state: 'State',
        type: 'Type',
        name: 'Name'
      },
      form: {
        default: 'Basic data',
        contactData: 'Contact data',
        invoiceData: 'Invoice data',
        contactPerson: 'Contact person'
      }
    }
  }
};
