export const routes = [
  {
    name: 'contractItem_list',
    path: '/contractItem',
    componentConfig: [
      {
        component: 'EndpointListContainer',
        props: {
          endpoint: 'contractItem',
          slice: 'contractItem/contractItem/list',
          selectAction: {
            preview: {
              meta: {
                modal: {
                  body: [
                    {
                      component: 'ContractItemDetailContainer'
                    }
                  ]
                }
              }
            },
            edit: {
              type: 'contractItem/contractItem/edit/start',
              meta: {
                modal: {
                  title: 'contractItem.actions.update',
                  body: [
                    {
                      component: 'ContractItemEditForm'
                    }
                  ]
                }
              }
            }
          }
        },
        children: [
          {
            component: 'Grid',
            props: {
              title: 'contractItem.module.name',
              config: {
                columns: {
                  id: {
                    label: 'contractItem.entity.id'
                  },
                  contract: {
                    label: 'contractItem.entity.contract',
                    display: {
                      type: 'function',
                      renderer: ({ value }) => {
                        return `${
                          value.offer ? value.offer.description : ''
                        } - ${value.company ? value.company.name : ''}`;
                      }
                    }
                  },
                  budgetLine: {
                    label: 'contractItem.entity.budgetLine',
                    display: {
                      type: 'objectProps',
                      props: ['name']
                    }
                  },
                  description: {
                    label: 'contractItem.entity.description'
                  },
                  cost: {
                    label: 'contractItem.entity.cost'
                  },
                  updatedAt: {
                    label: 'contractItem.entity.updatedAt',
                    display: {
                      type: 'datetime'
                    }
                  }
                },
                filtersConfig: [],
                actions: [
                  {
                    label: 'contractItem.actions.create',
                    type: 'create',
                    icon: 'plus',
                    action: {
                      type: 'contractItem/contractItem/edit/start',
                      meta: {
                        modal: {
                          title: 'contractItem.create',
                          body: [
                            {
                              component: 'ContractItemCreateForm'
                            }
                          ]
                        }
                      }
                    }
                  }
                ],
                recordActions: [
                  {
                    label: '',
                    type: 'edit',
                    icon: 'pen',
                    action: {
                      type: 'contractItem/contractItem/edit/start',
                      meta: {
                        modal: {
                          title: 'contractItem.update',
                          body: [
                            {
                              component: 'ContractItemEditForm'
                            }
                          ]
                        }
                      }
                    }
                  },
                  {
                    label: '',
                    type: 'delete',
                    icon: 'trash',
                    action: {
                      type: 'contractItem/contractItem/list/delete',
                      meta: {
                        API: {
                          endpoint: 'contractItem',
                          operation: 'delete'
                        },
                        confirm: {
                          props: {
                            title: 'ims.confirm.delete.title',
                            message: 'ims.confirm.delete.message',
                            confirmText: 'ims.confirm.delete.confirmText',
                            cancelText: 'ims.confirm.delete.cancelText'
                          },
                          confirmed: false
                        }
                      }
                    }
                  }
                ]
              }
            }
          }
        ]
      }
    ],
    exact: true,
    section: false,
    module: 'contractItem',
    label: 'contractItem.module.name',
    icon: null
  }
];
