import React from 'react';
import RelativeDate from '@8powers/ams/components/Display/RelativeDate';
import Money from '@8powers/ams/components/Display/Money';
import { withTranslation } from 'react-i18next';
import {
  Wrapper,
  SumCol,
  RelativeDateCol,
  TextCol,
  NumberCol,
  Title,
  Desc,
  Headline
} from '../styles';

class Contracts extends React.Component {
  getItems() {
    const { name, items, onRowClick, t } = this.props;

    const list = [];
    let lastContractNumber;
    items.forEach((contractItem, i) => {
      list.push(
        <Wrapper
          key={i}
          onClick={e =>
            onRowClick &&
            onRowClick(
              e,
              contractItem,
              'contractItem',
              t('budget.line.modal.contractItem')
            )
          }
        >
          <NumberCol>
            {lastContractNumber === contractItem.contract.number
              ? ''
              : contractItem.contract.number}
          </NumberCol>
          <TextCol>
            {lastContractNumber !== contractItem.contract.number && (
              <Title>{contractItem.contract.company.name}</Title>
            )}
            <Desc>
              {name}, {contractItem.description}
            </Desc>
          </TextCol>
          <RelativeDateCol>
            <RelativeDate value={contractItem.contract.signDate} />
          </RelativeDateCol>
          <SumCol>
            <Money value={contractItem.cost} currency="PLN" suffix />
          </SumCol>
        </Wrapper>
      );
      lastContractNumber = contractItem.contract.number;
    });

    return list;
  }

  render() {
    const { items } = this.props;
    if (items.length === 0) return null;

    return (
      <div>
        <Headline>Umowy:</Headline>
        {this.getItems()}
      </div>
    );
  }
}

export default withTranslation()(Contracts);
