import React from 'react';
import { Input } from '@8powers/ams/components/UI/Form';
import Display from '@8powers/ams/components/Display';
import {
  Wrapper,
  WrapperGroup,
  Num,
  InputWrap,
  InputBadge,
  Multiply,
  Sum
} from './styles';

class ListItem extends React.Component {
  render() {
    const {
      index,
      item,
      budgetLine,
      onInputChange,
      preview,
      readOnly
    } = this.props;

    let currentPercent = item.totalPercent + parseFloat(item.percent, 10);
    if (currentPercent % 1 > 0) {
      currentPercent = currentPercent.toFixed(4);
    }
    return (
      <Wrapper>
        <WrapperGroup>
          <Num>{index}.</Num>
          <InputWrap long>{item.name}</InputWrap>
        </WrapperGroup>
        <WrapperGroup>
          <InputWrap
            title={`${item.count} ${item.unitLabel} x ${item.unitPrice} zł`}
            style={{ textAlign: 'right' }}
          >
            <Display type="price" value={item.contractTotal} /> zł
          </InputWrap>
          <Multiply>&times;</Multiply>

          {preview === true ? (
            <InputWrap style={{ minWidth: '5rem', textAlign: 'right' }}>
              {item.percent} %
            </InputWrap>
          ) : (
            <InputWrap withPercent>
              <InputBadge
                overBudget={
                  item.totalPercent + parseFloat(item.percent, 10) > 100
                }
              >
                Aktualnie: {currentPercent}%
              </InputBadge>
              <Input
                style={{ minWidth: '8rem' }}
                value={item.percent}
                name="percent"
                type="text"
                onChange={e =>
                  onInputChange(e, budgetLine.budgetLine.id, item.contract_item)
                }
                readOnly={readOnly}
              />
            </InputWrap>
          )}

          <Multiply>=</Multiply>
          <Sum>
            <InputWrap withCurrency style={{ maxWidth: '11.8rem' }}>
              <Input
                style={{ minWidth: '8rem' }}
                value={item.doneCost}
                name="doneCost"
                type="text"
                onChange={e =>
                  onInputChange(e, budgetLine.budgetLine.id, item.contract_item)
                }
                readOnly={readOnly}
              />
            </InputWrap>
          </Sum>
        </WrapperGroup>
      </Wrapper>
    );
  }
}

export default ListItem;
