import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createAction } from 'redux-starter-kit';
import PropTypes from 'prop-types';
import { createFetchAction } from '@8powers/core/redux/slice/utils';
import { endpointListSelector } from '@8powers/ams/redux/selectors';
import DashboardList from '@8powers/ams/components/Dashboard/List';

class DashboardListContainer extends React.Component {
  static propTypes = {
    endpoint: PropTypes.string.isRequired,
    slice: PropTypes.string.isRequired
  };

  componentDidMount() {
    this.props.fetch();
  }

  render() {
    const { title, moreRoute, moreLabel, data } = this.props;
    const listData = [];
    data.forEach(item => {
      const { id, ...itemData } = item;
      listData.push(itemData);
    });

    const listProps = {
      title,
      moreRoute,
      moreLabel,
      data: listData
    };
    return <DashboardList {...listProps} />;
  }
}

const mapStateToProps = (state, { endpoint, slice }) => {
  const selectListData = endpointListSelector(endpoint, slice);
  return {
    ...selectListData(state)
  };
};

const mapDispatchToProps = (dispatch, { endpoint, slice }) => {
  const fetch = createFetchAction({ endpoint, slice });
  const actions = ['init', 'clear'].reduce((acc, name) => {
    if (Array.isArray(name)) {
      acc[name[1]] = createAction(`${slice}/${name[0]}`);
    } else {
      acc[name] = createAction(`${slice}/${name}`);
    }
    return acc;
  }, {});

  return {
    ...bindActionCreators({ ...actions, fetch }, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardListContainer);
