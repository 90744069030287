import styled from 'styled-components';
import { Button } from '@8powers/ams/components/UI';
import { FormRow } from '@8powers/ams/components/UI/Form';

export const Wrapper = styled.div``;

export const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
`;

export const Num = styled.div`
  min-width: 2rem;
  margin-right: 2rem;
  text-align: right;
`;

export const SelectWrap = styled.div`
  margin-bottom: 2rem;
  flex: 1;

  ${FormRow} {
    margin-bottom: 0;
  }
`;

export const List = styled.div`
  margin-left: 4rem;
  margin-bottom: 2rem;
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 61rem;
`;

export const NewListItemButton = styled(Button)`
  border-radius: ${({ theme }) => theme['form-input-border-radius']};
  line-height: 1;
  padding-bottom: 10px;
`;

export const Actions = styled.div`
  margin-left: 2rem;
`;

export const ActionButton = styled.button`
  border: 0;
  background: transparent;
  user-select: none;
  cursor: pointer;
`;
