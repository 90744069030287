export default {
  messages: {
    protocol: {
      create: 'New protocol',
      update: 'Update protocol',
      module: {
        name: 'Protocol'
      },
      entity: {
        id: 'Id',
        contract: 'Contract',
        contractConditional: 'For contract',
        company: 'Company',
        number: 'Number',
        date: 'Date',
        guarantee: 'Guarantee',
        penalty: 'Penalty',
        final: 'Final',
        items: 'Items',
        total: 'Total',
        costs: 'Costs',
        createdAt: 'CreatedAt',
        updatedAt: 'UpdatedAt',
        createdBy: 'CreatedBy',
        updatedBy: 'UpdatedBy',
        endDate: 'EndDate',
        buildCostPercentage: 'BuildCostPercentage',
        backendCostPercentage: 'BackendCostPercentage',
        additionalConditions: 'AdditionalConditions'
      },
      grid: [],
      actions: {
        create: 'Create',
        update: 'Update',
        delete: 'Delete'
      },
      recordActions: {
        addCost: 'Add cost',
        pdf: 'PDF',
        edit: 'Edit',
        delete: 'Delete'
      }
    }
  }
};
