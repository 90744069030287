const widgets = {
  ProtocolItemForm: {
    component: 'FormContainer',
    children: [
      {
        component: 'Form',
        props: {
          fields: [
            {
              name: 'protocol',
              label: 'protocolItem.entity.protocol',
              type: 'endpointCombo',
              endpoint: 'protocol',
              slice: 'protocolItem/protocol/combo',
              displayField: 'date'
            },
            {
              name: 'contractItem',
              label: 'protocolItem.entity.contractItem',
              type: 'endpointCombo',
              endpoint: 'contractItem',
              slice: 'protocolItem/contractItem/combo',
              displayField: 'description'
            },
            {
              name: 'donePercent',
              label: 'protocolItem.entity.donePercent',
              type: 'text'
            },
            {
              name: 'cost',
              label: 'protocolItem.entity.cost',
              type: 'text'
            },
            {
              name: 'notes',
              label: 'protocolItem.entity.notes',
              type: 'text'
            }
          ]
        }
      }
    ]
  },
  ProtocolItemEditForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'protocolItem',
      slice: 'protocolItem/protocolItem/edit',
      operation: 'edit',
      fancyMask: true
    },
    children: [
      {
        component: 'ProtocolItemForm'
      }
    ]
  },
  ProtocolItemCreateForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'protocolItem',
      slice: 'protocolItem/protocolItem/create',
      operation: 'create',
      fancyMask: true
    },
    children: [
      {
        component: 'ProtocolItemForm'
      }
    ]
  },
  ProtocolItemDetailContainer: {
    component: 'EndpointSelectedContainer',
    props: {
      endpoint: 'protocolItem',
      slice: 'protocolItem/protocolItem/list'
    },
    children: [
      {
        component: 'EndpointDetail',
        props: {
          config: {
            groups: [
              {
                label: 'Dane podstawowe',
                columns: [
                  [
                    {
                      key: 'id',
                      label: 'protocolItem.entity.id'
                    },
                    {
                      key: 'protocol',
                      label: 'protocolItem.entity.protocol',
                      type: 'objectProps',
                      typeProps: {
                        props: ['id']
                      }
                    },
                    {
                      key: 'contractItem',
                      label: 'protocolItem.entity.contractItem',
                      type: 'objectProps',
                      typeProps: {
                        props: ['description']
                      }
                    },
                    {
                      key: 'donePercent',
                      label: 'protocolItem.entity.donePercent'
                    },
                    {
                      key: 'cost',
                      label: 'protocolItem.entity.cost'
                    },
                    {
                      key: 'notes',
                      label: 'protocolItem.entity.notes'
                    },
                    {
                      key: 'createdBy',
                      label: 'protocolItem.entity.createdBy',
                      type: 'objectProps',
                      typeProps: {
                        props: ['firstName', 'lastName']
                      }
                    },
                    {
                      key: 'updatedBy',
                      label: 'protocolItem.entity.updatedBy',
                      type: 'objectProps',
                      typeProps: {
                        props: ['firstName', 'lastName']
                      }
                    },
                    {
                      key: 'createdAt',
                      label: 'protocolItem.entity.createdAt',
                      type: 'datetime'
                    },
                    {
                      key: 'updatedAt',
                      label: 'protocolItem.entity.updatedAt',
                      type: 'datetime'
                    }
                  ]
                ]
              }
            ]
          }
        }
      }
    ]
  }
};

export default widgets;
