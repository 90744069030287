export default {
  messages: {
    protocol: {
      create: 'Dodaj protokół',
      update: 'Edytuj protokół',
      module: {
        name: 'Protokoły'
      },
      entity: {
        id: 'Id',
        contract: 'Umowa',
        contractConditional: 'Dla umowy',
        company: 'Firma',
        number: 'Numer',
        date: 'Data',
        guarantee: 'Zatrzymanie gwarancyjne',
        penalty: 'Zatrzymanie karne',
        final: 'Czy protokół końcowy?',
        items: 'Pozycje',
        costs: 'Koszty',
        total: 'Kosz całkowity',
        createdAt: 'Data dodania',
        updatedAt: 'Zmienione',
        createdBy: 'Dodany przez',
        updatedBy: 'Modyfikowany przez',
        endDate: 'Data zakończenia',
        buildCostPercentage: 'Obciążenie kosztów budowy %',
        backendCostPercentage: 'Koszty zaplecza (zł)',
        additionalConditions: 'Dodatkowe warunki'
      },
      grid: [],
      actions: {
        create: 'Dodaj',
        update: 'Edytuj',
        delete: 'Usuń'
      },
      recordActions: {
        addCost: 'Koszt',
        pdf: 'PDF',
        edit: 'Edytuj',
        delete: 'Usuń'
      },
      delete_error: {
        title: 'Nie można usunąć tego protokołu',
        message: 'Tylko ostatni protokół danej umowy może zostać usunięty',
        confirmText: 'OK',
        cancelText: ''
      }
    }
  }
};
