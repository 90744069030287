const widgets = {
  OfferForm: {
    component: 'FormContainer',
    children: [
      {
        component: 'Form',
        props: {
          fields: [
            {
              name: 'company',
              label: 'offer.entity.company',
              type: 'endpointCombo',
              endpoint: 'company',
              slice: 'offer/company/combo'
            },
            {
              name: 'sendDate',
              label: 'offer.entity.sendDate',
              type: 'date'
            },
            {
              name: 'description',
              label: 'offer.entity.description',
              type: 'textarea',
              height: '6rem'
            },
            {
              name: 'type',
              label: 'offer.entity.type',
              type: 'endpointCombo',
              endpoint: 'offerType',
              slice: 'offer/type/combo',
              displayField: 'label',
              valueField: 'value'
            },
            {
              label: null,
              name: 'collection',
              type: 'collection',
              rawControl: true
            }
          ]
        }
      }
    ]
  },
  OfferEditForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'offer',
      slice: 'offer/offer/edit',
      operation: 'edit',
      fancyMask: true,
      extractFromRecord: ['collection']
    },
    children: [
      {
        component: 'OfferForm'
      }
    ]
  },
  OfferCreateForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'offer',
      slice: 'offer/offer/create',
      operation: 'create',
      fancyMask: true
    },
    children: [
      {
        component: 'OfferForm'
      }
    ]
  },
  OfferDetailContainer: {
    component: 'EndpointSelectedContainer',
    props: {
      endpoint: 'offer',
      slice: 'offer/offer/list'
    },
    children: [
      {
        component: 'EndpointDetail',
        props: {
          config: {
            groups: [
              {
                label: 'Dane podstawowe',
                columns: [
                  [
                    {
                      key: 'id',
                      label: 'offer.entity.id'
                    },
                    {
                      key: 'company',
                      label: 'offer.entity.company',
                      type: 'objectProps',
                      typeProps: {
                        props: ['name']
                      }
                    },
                    {
                      key: 'description',
                      label: 'offer.entity.description'
                    }
                  ],
                  [
                    {
                      key: 'investment',
                      label: 'offer.entity.investment',
                      type: 'objectProps',
                      typeProps: {
                        props: ['name']
                      }
                    },
                    {
                      key: 'sendDate',
                      label: 'offer.entity.sendDate',
                      type: 'datetime'
                    },
                    {
                      key: 'typeLabel',
                      label: 'offer.entity.type'
                    }
                  ]
                ]
              },
              {
                label: '',
                columns: [
                  [
                    {
                      key: 'collection',
                      label: 'offer.entity.items',
                      type: 'itemsList',
                      collection: true
                    }
                  ]
                ]
              },
              {
                label: '',
                columns: [
                  [
                    {
                      key: 'createdAt',
                      label: 'offer.entity.createdAt',
                      type: 'relativeDate'
                    },
                    {
                      key: 'createdBy',
                      label: 'offer.entity.createdBy',
                      type: 'objectProps',
                      typeProps: {
                        props: ['firstName', 'lastName']
                      }
                    }
                  ],
                  [
                    {
                      key: 'updatedAt',
                      label: 'offer.entity.updatedAt',
                      type: 'relativeDate'
                    },
                    {
                      key: 'updatedBy',
                      label: 'offer.entity.updatedBy',
                      type: 'objectProps',
                      typeProps: {
                        props: ['firstName', 'lastName']
                      }
                    }
                  ]
                ]
              }
            ]
          }
        }
      }
    ]
  }
};

export default widgets;
