import React from 'react';
import Application from '@8powers/ams/components/Application';
import { defaultListState } from '@8powers/core/redux/slice/list';
import history from './history';
import './normalize.css';
import './index.css';
import Logo from './logo.svg';

// Force new pagination limit
defaultListState.pagination.onPage = 50;

const App = ({ routes }) => {
  const applicationParams = {
    name: 'Ams',
    enableSearch: true,
    icon: Logo
  };

  return (
    <React.Fragment>
      <Application
        history={history}
        routes={routes}
        params={applicationParams}
      />
    </React.Fragment>
  );
};

export default App;
