import { createDucks } from '@8powers/core/redux';

const { reducer, saga, actions } = createDucks('contractItem', {
  contractItem: {
    endpoint: 'contractItem',
    slices: ['list', 'detail', 'create', 'edit']
  },
  contract: {
    endpoint: 'contract',
    slices: ['combo']
  },
  budgetLine: {
    endpoint: 'budgetLine',
    slices: ['combo']
  },
  budgetLineUnit: {
    endpoint: 'budgetLineUnit',
    slices: ['combo']
  }
});

export { saga, actions };
export default reducer;
