import React from 'react';
import { Icon } from '@8powers/ams/components/UI';
import Display from '@8powers/ams/components/Display';
import { Input } from '@8powers/ams/components/UI/Form';
import {
  Wrapper,
  Row,
  Content,
  Col,
  CollapseHandler,
  Name,
  Prefix,
  InputWrap,
  Field,
  InputLabel,
  Sum,
  Total
} from './styles';

class ListItem extends React.Component {
  render() {
    const {
      prefix,
      record,
      onQuarterInputChange,
      children,
      nodeDepth,
      onCollapseClick,
      isCollapsed
    } = this.props;

    return (
      <Row
        depth={nodeDepth}
        extraMargin={record.children.length === 0}
        key={record.id}
        onMouseLeave={this.onMouseOut}
      >
        <Wrapper>
          <Content>
            <Col>
              {record.children.length > 0 && (
                <CollapseHandler onClick={onCollapseClick}>
                  <Icon name={isCollapsed ? 'chevron-down' : 'chevron-up'} />
                </CollapseHandler>
              )}
              <Name>
                {prefix && <Prefix>{prefix}.</Prefix>}
                {record.name}
              </Name>
            </Col>
            <Col>
              {record.children.length === 0 &&
                record.schedule &&
                record.schedule.map((quarter, index) => (
                  <InputWrap>
                    <Field>
                      <InputLabel>
                        Q{quarter.quarter} {quarter.year}
                      </InputLabel>
                      <Input
                        name={`quarter${record.id}_${index}`}
                        value={quarter.cost}
                        onChange={e => onQuarterInputChange(e, record.id)}
                      />
                    </Field>
                  </InputWrap>
                ))}

              <Sum marginLeft error={record.sumToDistribute < 0}>
                <Display type="price" value={record.sumToDistribute || 0} /> zł
              </Sum>

              <Total>
                <Display type="price" value={record.total} /> zł
              </Total>

              {!record.scheduleTotal && record.count2 && (
                <Sum marginLeft>
                  <Display
                    type="price"
                    value={record.count * record.unitPrice}
                  />{' '}
                  zł
                </Sum>
              )}
            </Col>
          </Content>
        </Wrapper>
        {children}
      </Row>
    );
  }
}

export default ListItem;
