export default {
  messages: {
    company: {
      create: 'Dodaj firmę',
      update: 'Edytuj firmę',
      module: {
        name: 'Firmy'
      },
      entity: {
        id: 'Id',
        name: 'Nazwa',
        contactData: {
          label: 'Dane kontaktowe',
          city: 'Miasto',
          postCode: 'Kod pocztowy',
          street: 'Ulica',
          buildingNumber: 'Numer budynku'
        },
        invoiceData: {
          label: 'Dane do faktury',
          city: 'Miasto',
          postCode: 'Kod pocztowy',
          street: 'Ulica',
          buildingNumber: 'Numer budynku'
        },
        sameAddress: 'Ten sam adres do faktury',
        email: 'Email',
        taxID: 'NIP',
        krs: 'KRS',
        type: 'Typ',
        investments: 'Inwestycje',
        offers: 'Oferty',
        contracts: 'Umowy',
        costs: 'Koszty',
        state: 'Status',
        createdAt: 'Data utworzenia',
        updatedAt: 'Zmienione',
        regon: 'REGON',
        representedBy: 'Reprezentowana przez',
        accountNumber: 'Number konta'
      },
      grid: [],
      actions: {
        create: 'Dodaj',
        update: 'Edytuj',
        delete: 'Usuń'
      },
      filter: {
        state: 'State',
        type: 'Type',
        name: 'Name'
      },
      form: {
        default: 'Dane podstawowe',
        contactData: 'Dane kontaktowe',
        invoiceData: 'Dane do faktury',
        contactPerson: 'Osoba kontaktowa'
      }
    }
  }
};
