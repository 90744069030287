import styled from 'styled-components';
import { Input } from '@8powers/ams/components/UI/Form';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  margin-bottom: 2rem;
  font-weight: 300;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const WrapperGroup = styled.div`
  display: flex;
  align-items: baseline;
`;

export const Num = styled.div`
  min-width: 2rem;
  margin-right: 2rem;
  text-align: right;
`;

export const InputWrap = styled.div`
  max-width: ${({ long }) => (long ? '25rem' : '10rem')};
  width: 100%;
  position: relative;

  & + & {
    margin-left: 1rem;
  }

  ${({ withCurrency }) =>
    withCurrency &&
    `
    position: relative;

    ${Input} {
      text-align: right;
      padding-right: 2.8rem;
    }

    &::after {
      content: 'zł';
      position: absolute;
      right: 1rem;
      bottom: 0.3rem;
      font-weight: 300;
    }
  `}

  ${({ withPercent }) =>
    withPercent &&
    `
    position: relative;

    ${Input} {
      text-align: right;
      padding-right: 2.8rem;
    }

    &::after {
      content: '%';
      position: absolute;
      right: 1rem;
      bottom: 0.3rem;
      font-weight: 300;
    }
  `}
`;

export const Multiply = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  color: #ccc;
`;

export const Sum = styled.div`
  margin-left: ${({ marginLeft }) => (marginLeft ? '2rem' : 0)};
  font-weight: 500;
  white-space: nowrap;
  min-width: 10rem;
  text-align: right;

  ${({ error }) =>
    error &&
    `
    color: red
  `}
`;

export const InputBadge = styled.div`
  position: absolute;
  font-size: 1rem;
  background: #fff;
  transform: translate(0, -75%);
  top: 0;
  left: 1rem;
  right: 1rem;
  text-align: center;
  ${({ overBudget }) =>
    overBudget &&
    `
    color: red
  `}
`;
