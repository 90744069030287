export default {
  messages: {
    budget: {
      create: 'Dodaj budżet',
      update: 'Edytuj budżet',
      module: {
        name: 'Budżet'
      },
      entity: {
        id: 'Id',
        name: 'Nazwa',
        startDate: 'Data rozpoczęcia',
        endDate: 'Data zakończenia',
        investment: 'Inwestycja',
        budgetLines: 'Linie budżetowe',
        costs: 'Koszt',
        createdBy: 'Utworzone przez',
        updatedBy: 'Zaktualizowane przez',
        createdAt: 'Utoworzone',
        updatedAt: 'Zaktualizowane'
      },
      grid: [],
      rowActions: {
        edit: 'Edytuj',
        settings: 'Ustawienia',
        delete: 'Usuń'
      },
      actions: {
        create: 'Dodaj',
        update: 'Edytuj',
        delete: 'Usuń'
      },
      filter: {
        investment: 'Inwestycja'
      },
      line: {
        list: {
          title: {
            preview: 'Budżet',
            edit: 'Planowanie budżetu',
            schedule: 'Harmonogram'
          },
          modeSwitch: {
            preview: 'podgląd',
            edit: 'planowanie',
            schedule: 'harmonogram'
          },
          rowActions: {
            newLine: 'Nowa linia',
            newSubline: 'Nowa podlinia',
            duplicate: 'Duplikuj',
            delete: 'Usuń'
          },
          empty: 'Nie posiadasz żadnych linii budżetowych.'
        },
        modal: {
          contractItem: 'Pozycja umowy',
          costItem: 'Pozycja kosztów'
        }
      }
    }
  }
};
