import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
`;

export const Num = styled.div`
  min-width: 2rem;
  margin-right: 2rem;
  text-align: right;
`;

export const BudgetLineName = styled.div`
  margin-bottom: 2rem;
  flex: 1;
`;

export const List = styled.div`
  margin-left: 4rem;
  margin-bottom: 2rem;
`;
