import { createDucks } from '@8powers/core/redux';

const { reducer, saga, actions } = createDucks('costItem', {
  costItem: {
    endpoint: 'costItem',
    slices: ['list', 'detail', 'create', 'edit']
  },
  cost: {
    endpoint: 'cost',
    slices: ['combo']
  },
  budgetLine: {
    endpoint: 'budgetLine',
    slices: ['combo']
  },
  contractItem: {
    endpoint: 'contractItem',
    slices: ['combo']
  }
});

export { saga, actions };
export default reducer;
