import React from 'react';
import { Icon } from '@8powers/ams/components/UI';
import Display from '@8powers/ams/components/Display';
import Edit from './Edit';
import Bar from './Bar';
import Schedule from './Schedule';
import {
  Wrapper,
  Row,
  Content,
  Col,
  CollapseHandler,
  Name,
  Cell
} from './styles';

class ListItem extends React.Component {
  getContractCosts(record) {
    let total = 0;
    total += record.contractCosts;
    record.children.forEach(child => {
      total += this.getContractCosts(child);
    });

    return total;
  }

  getSpendCosts(record) {
    let total = 0;
    total += record.spendCosts;
    record.children.forEach(child => {
      total += this.getSpendCosts(child);
    });

    return total;
  }

  render() {
    const {
      mode,
      prefix,
      record,
      onRowClick,
      nodeDepth,
      onCollapseClick,
      isCollapsed,
      unitComboData,
      showLineItemDetail
    } = this.props;

    if (mode === 'edit') {
      return <Edit {...this.props} />;
    }
    if (mode === 'schedule') {
      return <Schedule {...this.props} />;
    }

    const unitName =
      unitComboData &&
      record &&
      unitComboData.find(item => item.id === record.unit);
    const contractCosts = this.getContractCosts(record).toFixed(2);
    const spendCosts = this.getSpendCosts(record).toFixed(2);

    return (
      <Row
        depth={nodeDepth}
        extraMargin={record.children.length === 0}
        key={record.id}
        onClick={e => onRowClick(e, record, showLineItemDetail)}
        onMouseLeave={this.onMouseOut}
      >
        <Wrapper>
          <Content>
            <Col>
              {record.children.length > 0 && (
                <CollapseHandler onClick={onCollapseClick}>
                  <Icon name={isCollapsed ? 'chevron-down' : 'chevron-up'} />
                </CollapseHandler>
              )}
              <Name>
                {prefix} {record.name}
              </Name>
            </Col>
            <Col>
              {(contractCosts > 0 || spendCosts > 0) && (
                <React.Fragment>
                  <Cell
                    title={
                      record.total > 0
                        ? `${((contractCosts * 100) / record.total).toFixed(
                            2
                          )} %`
                        : null
                    }
                  >
                    <Display type="price" value={contractCosts || 0} /> zł
                    <Bar percent={(contractCosts * 100) / record.total} />
                  </Cell>
                  <Cell
                    title={
                      record.total > 0
                        ? `${((spendCosts * 100) / record.total).toFixed(2)} %`
                        : null
                    }
                  >
                    <Display type="price" value={spendCosts || 0} /> zł
                    <Bar percent={(spendCosts * 100) / record.total} />
                  </Cell>
                </React.Fragment>
              )}
              {
                <Cell
                  summary
                  title={
                    record.total > 0
                      ? `${record.count} ${unitName ? unitName.label : ''} x ${
                          record.unitPrice
                        } zł`
                      : null
                  }
                >
                  <Display type="price" value={record.total} /> zł
                </Cell>
              }
            </Col>
          </Content>
        </Wrapper>
      </Row>
    );
  }
}

export default ListItem;
