import styled from 'styled-components';
import { Icon, Link } from '@8powers/ams/components/UI';
import {
  Button as DropdownButton,
  MenuItemLabel
} from '@8powers/ams/components/UI/Dropdown/styles';
import {
  Wrapper as ActionWrapper,
  Container as ActionContainer
} from '@8powers/ams/components/Grid/RowActions/styles';

import {
  Wrapper as FieldWrapper,
  Label as FieldLabel
} from '@8powers/ams/components/Form/Field/styles';
import { Input } from '@8powers/ams/components/UI/Form';

export const Actions = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
`;

export const InputLabel = styled(FieldLabel)`
  font-size: 1.2rem;
  white-space: nowrap;
  background-color: #fcfcfc;
  left: auto;
  right: 2.3rem;
`;

export const Field = styled(FieldWrapper)`
  margin-top: 0;
  position: relative;

  ${Input} {
    padding-top: 1rem;
    padding-right: 2.8rem;
    text-align: right;
    font-weight: 400;
  }

  &::after {
    content: 'zł';
    position: absolute;
    right: 1rem;
    bottom: 0.3rem;
    font-weight: 300;
  }
`;

export const Row = styled.div`
  margin-left: ${({ depth, extraMargin }) =>
    `${(extraMargin ? depth + 1 : depth) * 46}px`};
  border-bottom: ${({ theme }) => theme['data-list-row-border']};
  padding-right: 2rem;
  cursor: pointer;

  @media print {
    margin-left: ${({ depth, extraMargin }) =>
      `${(extraMargin ? depth + 1 : depth) * 10}px`};
  }

  ${MenuItemLabel} {
    white-space: nowrap;
  }

  ${ActionWrapper} {
    margin-left: 2rem;
  }

  @media screen and (max-width: 1024px) {
    margin-left: ${({ depth }) => `${depth * 5}px`};
    background: ${({ theme }) => theme['data-list-row-hover-bg']};
    border-radius: ${({ theme }) => theme['data-list-row-hover-border-radius']};
    box-shadow: ${({ theme }) => theme['data-list-row-hover-shadow']};

    ${ActionContainer} {
      flex-basis: 100%;
    }

    ${ActionWrapper} {
      opacity: 1;
    }

    ${DropdownButton} {
      color: ${({ theme }) => theme['dropdown-button-hover-color']};
    }
  }

  &:hover {
    background: ${({ theme }) => theme['data-list-row-hover-bg']};
    border-radius: ${({ theme }) => theme['data-list-row-hover-border-radius']};
    box-shadow: ${({ theme }) => theme['data-list-row-hover-shadow']};

    ${ActionWrapper} {
      opacity: 1;
    }

    ${DropdownButton} {
      color: ${({ theme }) => theme['dropdown-button-hover-color']};
    }

    ${InputLabel} {
      background-color: #fff;
    }
  }
`;

export const Wrapper = styled.div`
  padding: 1.2rem 0;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme['data-list-row-color']};

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: baseline;

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
  }
`;

export const Col = styled.div`
  display: flex;
  align-items: baseline;
  font-family: ${({ theme }) => theme['data-list-item-font-family']};
  font-size: ${({ theme }) => theme['data-list-item-font-size']};
  font-weight: ${({ theme }) => theme['data-list-item-font-weight']};
  color: ${({ theme }) => theme['data-list-item-color']};

  &:nth-child(2) {
    justify-content: flex-end;
  }

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
    flex-basis: 100%;

    &:first-child {
      margin-bottom: 1rem;
    }
  }

  @media print {
    font-size: 14px;
  }
`;

export const CollapseHandler = styled.div`
  padding: 0 1.5rem;
  cursor: pointer;
  user-select: none;
  position: relative;
  color: ${({ theme }) => theme['data-list-item-drag-color']};

  @media print {
    display: none;
  }
`;

export const Name = styled.div`
  padding: 0 1.5rem;
  display: flex;
  align-items: baseline;
`;

export const Prefix = styled.div`
  margin-right: 1rem;
`;

export const Path = styled(Link)`
  &,
  &:hover,
  &:focus,
  &:active {
    padding: 0 1.5rem;
    font-size: ${({ theme }) => theme['data-list-item-path-font-size']};
    color: ${({ theme }) => theme['data-list-item-path-color']};
    text-decoration: none;
  }

  @media screen and (max-width: 1024px) {
    margin-left: 4rem;
    flex-basis: 100%;
  }
`;

export const Info = styled.div`
  font-size: ${({ theme }) => theme['data-list-item-info-font-size']};
  color: ${({ theme }) => theme['data-list-item-info-color']};
  line-height: 1;

  @media screen and (max-width: 1024px) {
    padding-left: 5.5rem;
  }
`;

export const InfoIcon = styled(Icon)`
  margin-right: 1rem;
  position: relative;
  font-size: 2.2rem;
  vertical-align: middle;
`;

export const InputWrap = styled.div`
  max-width: 10rem;
  width: 100%;
  white-space: nowrap;

  & + & {
    margin-left: 2rem;
  }

  ${({ withCurrency }) =>
    withCurrency &&
    `
    position: relative;

    ${Input} {
      text-align: right;
      padding-right: 2.8rem;
    }

    &::after {
      content: 'zł';
      position: absolute;
      right: 1rem;
      bottom: 0.3rem;
      font-weight: 300;
    }
  `}
`;

export const ValueWrap = styled.div`
  max-width: 10rem;
  width: 100%;
  white-space: nowrap;

  & + & {
    margin-left: 2rem;
  }
`;

export const Multiply = styled.div`
  margin-left: 2rem;
  margin-right: 2rem;
  color: #ccc;
`;

export const Sum = styled.div`
  margin-left: ${({ marginLeft }) => (marginLeft ? '2rem' : 0)};
  font-weight: 500;
  white-space: nowrap;
  min-width: 11rem;
  text-align: right;
  ${({ error }) => error && `color: red;`}
`;

export const Total = styled.div`
  font-weight: 700;
  margin-left: 2rem;
  white-space: nowrap;
  min-width: 11rem;
  text-align: right;
`;

export const Cell = styled.div`
  padding: 0 2rem;
  width: 12rem;
  text-align: right;
  position: relative;
  white-space: nowrap;
  box-sizing: content-box;
  font-weight: ${({ summary }) => (summary ? 700 : 400)};

  &::after {
    content: '';
    position: absolute;
    top: -0.5rem;
    bottom: -0.5rem;
    left: 0;
    width: 0.2rem;
    background: ${({ theme }) => theme['data-list-item-drag-border-color']};
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }

  &:first-child {
    &::after {
      display: none;
    }
  }
`;
