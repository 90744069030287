export default {
  messages: {
    person: {
      create: 'New person',
      update: 'Update person',
      module: {
        name: 'Person'
      },
      entity: {
        id: 'Id',
        company: 'Company',
        firstName: 'FirstName',
        lastName: 'LastName',
        email: 'Email',
        phone: 'Phone',
        position: 'Position',
        state: 'State',
        createdAt: 'CreatedAt',
        updatedAt: 'UpdatedAt'
      },
      grid: [],
      actions: {
        create: 'Create',
        update: 'Update',
        delete: 'Delete'
      },
      filter: {
        state: 'State',
        company: 'Company',
        search: 'Search'
      }
    }
  }
};
