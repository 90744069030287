export const routes = [
  {
    name: 'offer_list',
    path: '/offer',
    componentConfig: [
      {
        component: 'EndpointListContainer',
        props: {
          endpoint: 'offer',
          slice: 'offer/offer/list',
          selectAction: {
            preview: {
              meta: {
                modal: {
                  body: [
                    {
                      component: 'OfferDetailContainer'
                    }
                  ]
                }
              }
            },
            edit: {
              type: 'offer/offer/edit/start',
              meta: {
                modal: {
                  title: 'offer.actions.update',
                  body: [
                    {
                      component: 'OfferEditForm'
                    }
                  ]
                }
              }
            }
          }
        },
        children: [
          {
            component: 'Grid',
            props: {
              title: 'offer.module.name',
              config: {
                columns: {
                  company: {
                    label: 'offer.entity.company',
                    display: {
                      type: 'objectProps',
                      props: ['name']
                    }
                  },
                  sendDate: {
                    label: 'offer.entity.sendDate',
                    display: {
                      type: 'relativeDate'
                    }
                  },
                  totalAmount: {
                    label: 'offer.entity.totalAmount'
                  },
                  typeLabel: {
                    label: 'offer.entity.type'
                  },
                  items: {
                    label: 'offer.entity.items',
                    display: {
                      type: 'function',
                      renderer: ({ value }) => {
                        const labels = value.map(x => x.description);
                        return labels.join(', ');
                      }
                    }
                  },
                  updatedAt: {
                    label: 'offer.entity.updatedAt',
                    display: {
                      type: 'relativeDate'
                    }
                  }
                },
                filtersConfig: [],
                actions: [
                  {
                    label: 'offer.actions.create',
                    type: 'create',
                    icon: 'plus',
                    action: {
                      type: 'offer/offer/edit/start',
                      meta: {
                        modal: {
                          title: 'offer.create',
                          body: [
                            {
                              component: 'OfferCreateForm'
                            }
                          ]
                        }
                      }
                    }
                  }
                ],
                recordActions: [
                  {
                    label: 'offer.recordActions.edit',
                    type: 'edit',
                    icon: 'pen',
                    visibleOnHover: true,
                    hideLabel: true,
                    action: {
                      type: 'offer/offer/edit/start',
                      meta: {
                        modal: {
                          title: 'offer.update',
                          body: [
                            {
                              component: 'OfferEditForm'
                            }
                          ]
                        }
                      }
                    }
                  },
                  {
                    label: 'offer.recordActions.addContract',
                    type: 'addContract',
                    icon: 'plus',
                    hidden: true,
                    passItemProps: ['id'],
                    action: {
                      type: 'offer/contract/create',
                      sideEffects: {
                        'offer/contract/create_success': [
                          {
                            type: 'contract/contract/create/start',
                            _convertPayload: 'extractAPI',
                            meta: {
                              modal: {
                                title: 'contract.actions.create',
                                body: [
                                  {
                                    component: 'ContractCreateForm'
                                  }
                                ]
                              }
                            }
                          }
                        ]
                      },
                      meta: {
                        API: {
                          endpoint: 'offer',
                          operation: 'addContract'
                        }
                      }
                    }
                  },
                  {
                    label: 'offer.recordActions.delete',
                    type: 'delete',
                    icon: 'trash',
                    action: {
                      type: 'offer/offer/list/delete',
                      meta: {
                        API: {
                          endpoint: 'offer',
                          operation: 'delete'
                        },
                        confirm: {
                          props: {
                            title: 'ims.confirm.delete.title',
                            message: 'ims.confirm.delete.message',
                            confirmText: 'ims.confirm.delete.confirmText',
                            cancelText: 'ims.confirm.delete.cancelText'
                          },
                          confirmed: false
                        }
                      }
                    }
                  }
                ]
              }
            }
          }
        ]
      }
    ],
    exact: true,
    section: true,
    module: 'offer',
    label: 'offer.module.name',
    icon: 'layers'
  }
];
