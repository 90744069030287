export default {
  messages: {
    person: {
      create: 'Nowa osoba',
      update: 'Edytuj osobę',
      module: {
        name: 'Kontakty'
      },
      entity: {
        id: 'Id',
        company: 'Firma',
        firstName: 'Imię',
        lastName: 'Nazwisko',
        email: 'Email',
        phone: 'Telefon',
        position: 'Pozycja',
        state: 'Status',
        createdAt: 'Data dodania',
        updatedAt: 'Zmienione'
      },
      grid: [],
      actions: {
        create: 'Dodaj',
        update: 'Edytuj',
        delete: 'Usuń'
      },
      filter: {
        state: 'Status',
        company: 'Firma',
        search: 'Szukaj'
      }
    }
  }
};
