const widgets = {
  ProfileFrom: {
    component: 'FormContainer',
    children: [
      {
        component: 'Form',
        props: {
          groups: [
            {
              label: '',
              columns: [
                [
                  {
                    name: 'firstName',
                    label: 'profile.entity.firstName',
                    type: 'text'
                  }
                ],
                [
                  {
                    name: 'lastName',
                    label: 'profile.entity.lastName',
                    type: 'text'
                  }
                ]
              ]
            },
            {
              label: '',
              columns: [
                [
                  {
                    name: 'user.username',
                    label: 'profile.entity.user.username',
                    type: 'text'
                  }
                ]
              ]
            },
            {
              label: '',
              columns: [
                [
                  {
                    name: 'user.plainPassword.plainPassword',
                    label: 'profile.entity.user.password',
                    type: 'password'
                  }
                ],
                [
                  {
                    name: 'user.plainPassword.plainPassword_repeat',
                    label: 'profile.entity.user.passwordRepeat',
                    type: 'password'
                  }
                ]
              ]
            },
            {
              label: '',
              columns: [
                [
                  {
                    name: 'user.email',
                    label: 'profile.entity.user.email',
                    type: 'text'
                  }
                ],
                [
                  {
                    name: 'phone',
                    label: 'profile.entity.phone',
                    type: 'text'
                  }
                ]
              ]
            },
            {
              label: '',
              columns: [
                [
                  {
                    name: 'position',
                    label: 'profile.entity.position',
                    type: 'endpointCombo',
                    endpoint: 'profilePosition',
                    slice: 'profile/position/combo',
                    displayField: 'label',
                    valueField: 'value'
                  }
                ],
                [
                  {
                    name: 'state',
                    label: 'profile.entity.state',
                    type: 'endpointCombo',
                    endpoint: 'profileState',
                    slice: 'profile/state/combo',
                    placeholder: '--',
                    displayField: 'label'
                  }
                ]
              ]
            },
            {
              label: '',
              columns: [
                [
                  {
                    name: 'roles',
                    label: 'profile.entity.role',
                    type: 'endpointAutoSuggest',
                    endpoint: 'role',
                    slice: 'profile/role/combo',
                    displayField: 'name',
                    multiple: true,
                    rawControl: true
                  }
                ]
              ]
            }
          ]
        }
      }
    ]
  },
  ProfileEditForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'profile',
      slice: 'profile/profile/edit',
      operation: 'edit',
      fancyMask: true,
      extractFromRecord: ['roles']
    },
    children: [
      {
        component: 'ProfileFrom'
      }
    ]
  },
  ProfileCreateForm: {
    component: 'EndpointFormContainer',
    props: {
      endpoint: 'profile',
      slice: 'profile/profile/create',
      operation: 'create',
      fancyMask: true
    },
    children: [
      {
        component: 'ProfileFrom'
      }
    ]
  },
  ProfileDetailContainer: {
    component: 'EndpointSelectedContainer',
    props: {
      endpoint: 'profile',
      slice: 'profile/profile/list'
    },
    children: [
      {
        component: 'EndpointDetail',
        props: {
          config: {
            groups: [
              {
                label: 'Dane podstawowe',
                columns: [
                  [
                    {
                      key: 'id',
                      label: 'profile.entity.id'
                    }
                  ]
                ]
              },
              {
                label: '',
                columns: [
                  [
                    {
                      key: 'firstName',
                      label: 'profile.entity.firstName'
                    }
                  ],
                  [
                    {
                      key: 'lastName',
                      label: 'profile.entity.lastName'
                    }
                  ]
                ]
              },
              {
                label: '',
                columns: [
                  [
                    {
                      key: 'user',
                      label: 'profile.entity.user.email',
                      type: 'objectProps',
                      typeProps: {
                        props: ['email']
                      }
                    }
                  ],
                  [
                    {
                      key: 'phone',
                      label: 'profile.entity.phone'
                    }
                  ]
                ]
              },
              {
                label: '',
                columns: [
                  [
                    {
                      key: 'position',
                      label: 'profile.entity.position'
                    }
                  ],
                  [
                    {
                      key: 'state',
                      label: 'profile.entity.state',
                      type: 'objectProps',
                      typeProps: {
                        props: ['label']
                      }
                    }
                  ]
                ]
              },
              {
                label: '',
                columns: [
                  [
                    {
                      key: 'createdAt',
                      label: 'profile.entity.createdAt',
                      type: 'datetime'
                    }
                  ],
                  [
                    {
                      key: 'updatedAt',
                      label: 'profile.entity.updatedAt',
                      type: 'relativeDate'
                    }
                  ]
                ]
              }
            ]
          }
        }
      }
    ]
  }
};

export default widgets;
