import { createDucks } from '@8powers/core/redux';

const { reducer, saga, actions } = createDucks('offerItem', {
  offerItem: {
    endpoint: 'offerItem',
    slices: ['list', 'detail', 'create', 'edit']
  },
  offer: {
    endpoint: 'offer',
    slices: ['combo']
  },
  budgetLine: {
    endpoint: 'budgetLine',
    slices: ['combo']
  },
  budgetLineUnit: {
    endpoint: 'budgetLineUnit',
    slices: ['combo']
  }
});

export { saga, actions };
export default reducer;
