import Field from '@8powers/ams/components/Form/Field';
import Display from '@8powers/ams/components/Display';
import reducer, { saga as sagas, actions } from './ducks';
import * as translations from './translations';
import widgets from './widgets';
import CollectionField from './containers/CollectionFieldContainer';

// we can change mode for percent input only globally
// Field.register('protocolItems', { input: props => <CollectionField {...props} preview={true} /> }, true);

Field.register('protocolItems', { input: CollectionField }, true);
Display.register('protocolItems', CollectionField);

const errorModal = ({ dispatch }) => next => action => {
  // Side effect triggers twice due to the confirmation
  if (action.type === 'protocol/protocol/list/delete_error') {
    dispatch({
      type: `${action.type}_alert`,
      meta: {
        confirm: {
          props: {
            title: 'protocol.delete_error.title',
            message: 'protocol.delete_error.message',
            confirmText: 'protocol.delete_error.confirmText',
            cancelText: 'protocol.delete_error.cancelText'
          },
          confirmed: false
        }
      }
    });
  }

  return next(action);
};

const middlewares = [errorModal];

export const name = 'protocol';
export const realName = 'protocol';
export { reducer, widgets, sagas, actions, middlewares, translations };
export * from './routes';
