export default {
  messages: {
    profile: {
      create: 'Nowy użytkownik',
      update: 'Edytuj użytkownika',
      module: {
        name: 'Użytkownicy'
      },
      entity: {
        id: 'Id',
        user: {
          label: 'Użytkownik',
          email: 'Email',
          username: 'Login',
          password: 'Hasło',
          passwordRepeat: 'Powtórz hasło'
        },
        firstName: 'Imię',
        lastName: 'Nazwisko',
        phone: 'Telefon',
        position: 'Pozycja',
        teamMembers: 'Członkowie zespołu',
        managedInvestments: 'Zarządzane inwestycje',
        state: 'Status',
        createdAt: 'Data utworzenia',
        updatedAt: 'Zmienione',
        role: 'Uprawnienia'
      },
      grid: [],
      actions: {
        create: 'Dodaj',
        update: 'Edytuj',
        delete: 'Usuń'
      }
    }
  }
};
