import styled from 'styled-components';

export const Container = styled.div`
  height: 5px;
  width: 100%;
  position: relative;
`;

export const Empty = styled.div`
  height: 5px;
  width: 100%;
  background-color: #e2e2e2;
  position: absolute;
  right: 0;
`;

export const Completed = styled(Empty)`
  background-color: #03b0f0;
`;

export const Overflow = styled(Empty)`
  width: 100% !important;
  background-color: red;
`;
