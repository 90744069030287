export const routes = [
  {
    name: 'cost_list',
    path: '/cost',
    componentConfig: [
      {
        component: 'EndpointListContainer',
        props: {
          endpoint: 'cost',
          slice: 'cost/cost/list',
          selectAction: {
            preview: {
              meta: {
                modal: {
                  body: [
                    {
                      component: 'CostDetailContainer'
                    }
                  ]
                }
              }
            },
            edit: {
              type: 'cost/cost/edit/start',
              meta: {
                modal: {
                  title: 'cost.actions.update',
                  body: [
                    {
                      component: 'CostEditForm'
                    }
                  ]
                }
              }
            }
          }
        },
        children: [
          {
            component: 'Grid',
            props: {
              title: 'cost.module.name',
              config: {
                columns: {
                  company: {
                    sortable: true,
                    label: 'cost.entity.company',
                    display: {
                      type: 'objectProps',
                      props: ['name']
                    }
                  },
                  items: {
                    label: 'cost.entity.items',
                    display: {
                      type: 'function',
                      renderer: ({ value }) => {
                        let labels = value.map(
                          item =>
                            `${item.budgetLine.listOrder || ''} ${
                              item.budgetLine.name
                            }`
                        );

                        if (labels.length > 3) {
                          labels = labels.slice(0, 3);
                          return `${labels.join(', ')} ...`;
                        }

                        return labels.join(', ');
                      }
                    }
                  },
                  total: {
                    label: 'cost.entity.total',
                    display: {
                      type: 'money',
                      suffix: true,
                      currency: 'PLN'
                    }
                  },
                  createdAt: {
                    sortable: true,
                    label: 'cost.entity.createDate',
                    display: {
                      type: 'relativeDate'
                    }
                  }
                },
                filtersConfig: [
                  {
                    label: 'cost.filter.company',
                    property: 'company',
                    type: 'eq',
                    inputConfig: {
                      name: 'company',
                      type: 'wideEndpointCombo',
                      endpoint: 'companyCombo',
                      slice: 'cost/company/combo',
                      displayField: 'name'
                    }
                  }
                ],
                actions: [
                  {
                    label: 'cost.actions.create',
                    type: 'create',
                    icon: 'plus',
                    action: {
                      type: 'cost/cost/edit/start',
                      meta: {
                        modal: {
                          title: 'cost.create',
                          body: [
                            {
                              component: 'CostCreateForm'
                            }
                          ]
                        }
                      }
                    }
                  }
                ],
                recordActions: [
                  {
                    label: '',
                    type: 'edit',
                    icon: 'pen',
                    action: {
                      type: 'cost/cost/edit/start',
                      meta: {
                        modal: {
                          title: 'cost.update',
                          body: [
                            {
                              component: 'CostEditForm'
                            }
                          ]
                        }
                      }
                    }
                  },
                  {
                    label: '',
                    type: 'delete',
                    icon: 'trash',
                    visibilityProperty: 'canDelete',
                    visibilityValue: false,
                    action: {
                      type: 'cost/cost/list/delete',
                      meta: {
                        API: {
                          endpoint: 'cost',
                          operation: 'delete'
                        },
                        confirm: {
                          props: {
                            title: 'ims.confirm.delete.title',
                            message: 'ims.confirm.delete.message',
                            confirmText: 'ims.confirm.delete.confirmText',
                            cancelText: 'ims.confirm.delete.cancelText'
                          },
                          confirmed: false
                        }
                      }
                    }
                  }
                ]
              }
            }
          }
        ]
      }
    ],
    exact: true,
    section: true,
    module: 'cost',
    label: 'cost.module.name',
    icon: 'money-bag'
  }
];
