export default {
  messages: {
    contractItem: {
      create: 'Dodaj pozycję umowy',
      update: 'Edytuj pozycję umowy',
      module: {
        name: 'Pozycje umowy'
      },
      entity: {
        id: 'Id',
        contract: 'Umowa',
        budgetLine: 'Linia budżetowa',
        description: 'Opis',
        cost: 'Koszt',
        count: 'Ilość',
        unit: 'Jednostka',
        unitPrice: 'Cena jednostkowa',
        protocolItems: 'Pozycje protokołu',
        costItems: 'Pozycje kosztów',
        createdBy: 'Dodana przez',
        updatedBy: 'Modyfikowana przez',
        createdAt: 'Data dodania',
        updatedAt: 'Ostatnia modyfikacja'
      },
      grid: [],
      actions: {
        create: 'Dodaj',
        update: 'Edytuj',
        delete: 'Usuń'
      }
    }
  }
};
