import styled from 'styled-components';
import { Cell } from './Item/styles';

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex: 1 1 100%;
  flex-direction: column;
  position: relative;

  @media print {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: 11.3rem;
    left: 0;
    right: 0;
    border-top: ${({ theme }) => theme['grid-header-border-bottom']};

    @media print {
      display: none;
    }

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 7rem;
    left: 0;
    right: 0;
    height: 4rem;
    background: linear-gradient(
      0deg,
      ${({ theme }) => theme['data-list-bg']},
      transparent
    );
  }
`;

export const Content = styled.div`
  margin: 0 0 0;
  padding: 2rem 1rem 4rem;
  background: ${({ theme }) => theme['data-list-bg']};
  flex: 1;
  overflow: auto;

  @media screen and (max-width: 1439px) {
    margin-top: 0;
    padding: 0 2.5rem 2rem 2.5rem;
  }

  @media print {
    overflow: visible;
  }
`;

export const EmptyInfo = styled.div`
  padding: 3rem 0;
  color: #bebebe;
  font-size: 2.4rem;
  text-align: center;
  font-weight: 300;
`;

export const ModeSwitcher = styled.div`
  margin: 2rem 3rem 2rem 0;
  display: flex;
  justify-content: flex-end;

  @media print {
    display: none;
  }
`;

export const ModeSwitcherItem = styled.div`
  margin-left: 1.5rem;
  padding: 0 0 0 1.5rem;
  border-left: 0.1rem solid #f5f5f5;
  user-select: none;
  cursor: pointer;
  font-weight: ${({ active, theme }) =>
    active ? 500 : theme['base-font-weight']};

  &:first-child {
    border-left: 0;
  }
`;

export const CellHeader = styled.div`
  padding: 1.2rem 2rem 1.2rem 0;
  font-size: 1.2rem;
  display: flex;
  justify-content: flex-end;
  border-bottom: ${({ theme }) => theme['data-list-row-border']};

  ${Cell} {
    text-align: center;
  }
`;
