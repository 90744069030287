export default {
  messages: {
    sidebar: {
      contexts: {
        investment: 'Investycja'
      }
    },
    ims: {
      collectionField: {
        addBudgetLine: 'Nowy element',
        addListItem: 'Nowa pozycja'
      },
      confirm: {
        delete: {
          title: 'Czy na pewno?',
          message: 'Czy na pewno chesz trwale usunąć wybraną pozycję?',
          confirmText: 'Tak',
          cancelText: 'Nie'
        }
      }
    },
    ams: {
      relative: {
        labels: {
          moments: 'chwilę',
          ago: 'temu',
          months1: 'mies.',
          months2: 'mies.',
          months: 'mies.',
          weeks1: 'tyg.',
          weeks2: 'tyg.',
          weeks: 'tyg.',
          days1: 'dzień',
          days2: 'dni',
          days: 'dni',
          hours1: 'godz.',
          hours2: 'godz.',
          hours: 'godz.',
          minutes1: 'min.',
          minutes2: 'min.',
          minutes: 'min.'
        }
      }
    }
  }
};
