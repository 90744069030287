import { createDucks } from '@8powers/core/redux';
import { createDetailsSlice } from '@8powers/core/redux/slice/details';

const detailSlice = createDetailsSlice('budget/budget/detail', {
  reducers: {
    find_success: (state, { payload: data, meta }) => {
      if (meta.callback) {
        meta.callback(data.data);
      }

      return {
        ...state,
        record: data.data,
        isLoading: false,
        loaded: true,
        error: null
      };
    }
  }
});

const { reducer, saga, actions } = createDucks('budget', {
  budget: {
    endpoint: 'budget',
    slices: ['list', detailSlice, 'create', 'edit']
  },
  investment: {
    endpoint: 'investment',
    slices: ['combo']
  },
  budgetLine: {
    endpoint: 'budgetLine',
    slices: ['detail', 'combo']
  },
  budgetState: {
    endpoint: 'budgetState',
    slices: ['combo']
  },
  budgetLineUnit: {
    endpoint: 'budgetLineUnit',
    slices: ['combo']
  },
  contractItem: {
    endpoint: 'contractItem',
    slices: ['list', 'detail']
  },
  costItem: {
    endpoint: 'costItem',
    slices: ['list', 'detail']
  }
});

export { saga, actions };
export default reducer;
